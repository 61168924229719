import { Formik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Creators as ReportActions } from "../../../store/ducks/report";
// Components
import {
  Checkbox,
  Col,
  DatePicker,
  Radio,
  Row,
  Select,
  Spin,
  notification,
} from "antd";
import CustomDivider from "../../utils/customDivider";
import {
  Container,
  ExtensionButton,
  ExtensionContainer,
  Title,
} from "./styles";
// Services
import { findAllFinancialClassesByGroupIdAndFarmId } from "../../../services/financialClassService";
import { processReportDisbursement } from "../../../services/reportService";
import fileReader from "../../../utils/fileReader";
import ExportExcelIcon from "../../utils/icons/export/excel";

const validationSchema = Yup.object().shape({
  showZeroValues: Yup.boolean().required(),
  periodType: Yup.string().required(),
  periodStartDate: Yup.date().when(["periodType"], {
    is: (periodType) => periodType === "customDateRange",
    then: Yup.date().required(),
    otherwise: Yup.date().notRequired().nullable(),
  }),
  periodEndDate: Yup.date().when(["periodType"], {
    is: (periodType) => periodType === "customDateRange",
    then: Yup.date().required(),
    otherwise: Yup.date().notRequired().nullable(),
  }),
});

const LIST_COST_TYPE = [
  { costType: "AllTypes", amountAnimals: 0 },
  { costType: "Growth", amountAnimals: 0 },
  { costType: "RecreateFattenPasture", amountAnimals: 0 },
  { costType: "RecreateFattenSemiConfinement", amountAnimals: 0 },
  { costType: "RecreateFattenConfinement", amountAnimals: 0 },
  { costType: "Agriculture", amountAnimals: 0 },
  { costType: "Others", amountAnimals: 0 },
];

const LIST_PERIODS = [
  "lastSevenDays",
  "lastThirdyDays",
  "currentMonth",
  "lastMonth",
  "currentYear",
  "lastYear",
  "currentHarvest",
  "lastHarvest",
  "customDateRange",
];

const LIST_INTERVALS = ["daily", "weakly", "monthly", "yearly"];

const ExportReportDisbursementModal = () => {
  // Variables
  const formikRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [filteredListFinancialNature, setFilteredListFinancialNature] =
    useState([]);
  const [isLoadingFinancialNature, setIsLoadingFinancialNature] =
    useState(false);
  const [listFinancialClass, setListFinancialClass] = useState([]);
  const [isLoadingFinancialClass, setIsLoadingFinancialClass] = useState(false);
  // Redux Variables
  const {
    translation,
    groupSelected: { id: groupId, farms },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { isModalDisbursementReportVisible: modalVisible } = useSelector(
    (state) => state.report
  );

  const dispatch = useDispatch();

  // Methods

  const getFinancialClassAndNature = useCallback(async () => {
    setIsLoadingFinancialNature(true);
    setIsLoadingFinancialClass(true);
    try {
      const {
        data: { results: classes },
      } = await findAllFinancialClassesByGroupIdAndFarmId({
        groupId,
        farmId,
      });
      const filterClasses = classes.filter(
        (item) => item.shouldDisbursement === true
      );
      const natures = [
        ...new Map(
          filterClasses
            .map((c) => c.financialNature)
            .filter((c) => c.type === "expense")
            .map((item) => [item["id"], item])
        ).values(),
      ];
      setFilteredListFinancialNature(natures);
      setListFinancialClass(classes);
    } catch (error) {
    } finally {
      setIsLoadingFinancialNature(false);
      setIsLoadingFinancialClass(false);
    }
  }, [farmId, groupId]);

  function handleClose() {
    formikRef.current.resetForm({
      transactionType: "both",
      periodStartDate: null,
      periodEndDate: null,
      periodType: "currentMonth",
      intervalType: "daily",
      farmIds: null,
      financialNatureId: null,
      financialClassId: null,
      costActivityType: "AllTypes",
      showZeroValues: true,
      showAnalyticalData: false,
    });
    dispatch(ReportActions.hideModal());
  }

  async function handleSubmit(values, actions) {
    if (
      values.periodStartDate &&
      values.periodEndDate &&
      moment(values.periodEndDate).diff(
        moment(values.periodStartDate),
        "months"
      ) > 12
    ) {
      formikRef.current.setFieldError(
        "periodStartDate",
        "Diff must be more than 12"
      );
      formikRef.current.setFieldError(
        "periodEndDate",
        "Diff must be more than 12"
      );

      notification.error({
        message:
          translation.disbursementReportModalRequest.messages
            .errorPeriodMoreThanTwelveMonths,
      });

      return;
    }
    const body = {
      ...values,
      farmIds: values.farmIds ?? [],
      periodStartDate: !values.periodStartDate
        ? null
        : moment(values.periodStartDate).format("YYYY-MM-DD"),
      periodEndDate: !values.periodEndDate
        ? null
        : moment(values.periodEndDate).format("YYYY-MM-DD"),
    };
    setTimeout(() => {
      setIsLoading(false);
      formikRef.current.resetForm({
        transactionType: "both",
        periodStartDate: null,
        periodEndDate: null,
        periodType: "currentMonth",
        intervalType: "daily",
        farmIds: null,
        financialNatureId: null,
        financialClassId: null,
        costActivityType: "AllTypes",
        showZeroValues: true,
        showAnalyticalData: false,
      });
      dispatch(ReportActions.hideModal());
    }, 100);
    try {
      const { data } = await processReportDisbursement({
        groupId,
        farmId,
        body,
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.href = downloadUrl;
      const dayFormat = moment().format("YYYYMMDD");
      const hourFormat = moment().format("HHmm");
      link.setAttribute(
        "download",
        `Relatorio_Desembolso_${dayFormat}_${hourFormat}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

      notification.success({
        message: translation.successGenerateReport,
      });
    } catch (error) {
      if (error?.response) {
        try {
          const { data } = error.response;
          // Read file
          const file = await fileReader(data);

          if (typeof file === "string") {
            // Parse content and retrieve 'message'
            const result = JSON.parse(file);
            if (result.code === 5035) {
              notification.error({
                message:
                  translation.disbursementReportModalRequest.messages
                    .errorNoDataFound,
              });
            } else {
              notification.error({
                message: translation.errorGenerateReport,
              });
            }
          }
        } catch (readError) {
          notification.error({
            message: translation.errorGenerateReport,
          });
        }
      } else {
        notification.error({
          message: translation.errorGenerateReport,
        });
      }
    }
  }

  useEffect(() => {
    if (modalVisible === true) {
      getFinancialClassAndNature();
    }

    return () => {};
  }, [getFinancialClassAndNature, modalVisible]);

  return (
    <Container
      width={750}
      visible={modalVisible}
      centered
      closable={true}
      footer={null}
      onCancel={handleClose}
    >
      <Spin spinning={isLoading}>
        <Row type="flex">
          <Col span={24}>
            <Title>{translation.disbursementReportModalRequest.title}</Title>
          </Col>
        </Row>
        <Formik
          ref={formikRef}
          initialValues={{
            transactionType: "both",
            periodStartDate: null,
            periodEndDate: null,
            periodType: "currentMonth",
            intervalType: "daily",
            farmIds: null,
            financialNatureId: null,
            financialClassId: null,
            costActivityType: "AllTypes",
            showZeroValues: true,
            showAnalyticalData: false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={({
            values,
            errors,
            setFieldValue,
            submitCount,
            handleSubmit,
            setFieldError,
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log(JSON.stringify(errors))}
              {Object.keys(errors).length > 0 && submitCount > 0 && (
                <Row type="flex" justify="center">
                  <label className="error">{translation.error.formError}</label>
                </Row>
              )}
              {/* showZeroValues && showAnalyticalData fields */}
              <Row type="flex" gutter={16}>
                <Col span={12}>
                  <Checkbox
                    checked={values.showZeroValues}
                    onChange={(e) =>
                      setFieldValue("showZeroValues", e.target.checked)
                    }
                  >
                    {
                      translation.disbursementReportModalRequest.form
                        .showZeroValues
                    }
                  </Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox
                    checked={values.showAnalyticalData}
                    onChange={(e) =>
                      setFieldValue("showAnalyticalData", e.target.checked)
                    }
                  >
                    {
                      translation.disbursementReportModalRequest.form
                        .showAnalyticalData
                    }
                  </Checkbox>
                </Col>
              </Row>
              {/* farmIds field */}
              <Row type="flex" gutter={8}>
                <Col span={24}>
                  <Row>
                    <label>
                      {translation.disbursementReportModalRequest.form.farmIds}
                    </label>
                  </Row>
                  <Row type="flex">
                    <Select
                      mode="multiple"
                      name="farms"
                      value={
                        values.farmIds != null ? values.farmIds : undefined
                      }
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(values) => {
                        setFieldValue("farmIds", values);
                      }}
                    >
                      {farms.length > 0 &&
                        farms.map((f) => (
                          <Select.Option key={f.id} value={f.id}>
                            {f.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              {/* periodType (periodStartDate, periodEndDate) && intervalType fields */}
              <Row type="flex" gutter={8}>
                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <Row>
                    <label className="">
                      {
                        translation.disbursementReportModalRequest.form
                          .periodType
                      }
                    </label>
                  </Row>
                  <Row>
                    <Select
                      style={{ width: "100%" }}
                      name="periodType"
                      value={values.periodType || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(periodType) => {
                        setFieldValue("periodType", periodType);
                        if (periodType !== "customDateRange") {
                          setFieldValue("periodStartDate", null);
                          setFieldValue("periodEndDate", null);
                        }
                      }}
                    >
                      {LIST_PERIODS?.map((period) => (
                        <Select.Option key={period} value={period}>
                          {
                            translation.disbursementReportModalRequest.periods[
                              period
                            ]
                          }
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                {values.periodType === "customDateRange" && (
                  <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <Row type="flex" gutter={8}>
                      <Col span={12}>
                        <Row>
                          <label
                            className={
                              errors.periodStartDate && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.disbursementReportModalRequest.form
                                .periodStartDate
                            }
                            *
                          </label>
                        </Row>
                        <Row>
                          <DatePicker
                            name="periodStartDate"
                            value={values.periodStartDate || null}
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            format={"DD/MM/YYYY"}
                            onChange={(date) => {
                              setFieldValue("periodStartDate", date);
                              if (
                                values.periodEndDate &&
                                date.isAfter(values.periodEndDate)
                              )
                                setFieldValue("periodEndDate", null);
                            }}
                          />
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <label
                            className={
                              errors.periodEndDate && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.disbursementReportModalRequest.form
                                .periodEndDate
                            }
                            *
                          </label>
                        </Row>
                        <Row>
                          <DatePicker
                            name="periodEndDate"
                            value={values.periodEndDate || null}
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            format={"DD/MM/YYYY"}
                            disabledDate={(current) =>
                              current.isAfter(moment())
                            }
                            onChange={(date) =>
                              setFieldValue("periodEndDate", date)
                            }
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <Row>
                    <label className="">
                      {
                        translation.disbursementReportModalRequest.form
                          .intervalType
                      }
                    </label>
                  </Row>
                  <Row>
                    <Select
                      style={{ width: "100%" }}
                      name="intervalType"
                      value={values.intervalType || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(intervalType) => {
                        setFieldValue("intervalType", intervalType);
                      }}
                    >
                      {LIST_INTERVALS?.map((interval) => (
                        <Select.Option key={interval} value={interval}>
                          {
                            translation.disbursementReportModalRequest
                              .intervals[interval]
                          }
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              {/* Financial Nature && Financial Class && costActivityType fields */}
              <Row type="flex" gutter={8}>
                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <Row>
                    <label>
                      {
                        translation.disbursementReportModalRequest.form
                          .financialNatureId
                      }
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="financialNatureId"
                      value={
                        isLoadingFinancialNature
                          ? undefined
                          : values.financialNatureId || undefined
                      }
                      placeholder={translation.defaultSelectPlaceholder}
                      loading={isLoadingFinancialNature}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      onDeselect={() => {
                        setFieldValue("financialNatureId", null);
                        setFieldValue("financialClassId", null);
                      }}
                      onChange={(value) => {
                        setFieldValue("financialNatureId", value);
                        setFieldValue("financialClassId", null);
                      }}
                    >
                      {filteredListFinancialNature.map((l) => (
                        <Select.Option key={l.id} value={l.id}>
                          {l.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <Row>
                    <label>
                      {
                        translation.disbursementReportModalRequest.form
                          .financialClassId
                      }
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="financialClassId"
                      value={
                        isLoadingFinancialClass
                          ? undefined
                          : values.financialClassId || undefined
                      }
                      placeholder={translation.defaultSelectPlaceholder}
                      loading={isLoadingFinancialClass}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      onDeselect={() => {
                        setFieldValue("financialClassId", null);
                      }}
                      onChange={(value) => {
                        setFieldValue("financialClassId", value);
                      }}
                    >
                      {values.financialNatureId != null &&
                        listFinancialClass
                          .filter(
                            (l) =>
                              l.financialNature.id === values.financialNatureId
                          )
                          .map((l) => (
                            <Select.Option key={l.id} value={l.id}>
                              {l.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                  <Row>
                    <label className="filterLabel">
                      {
                        translation.disbursementReportModalRequest.form
                          .costActivityType
                      }
                    </label>
                  </Row>
                  <Row>
                    <Select
                      style={{ width: "100%" }}
                      name="costActivityType"
                      value={values.costActivityType || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      allowClear
                      onChange={(value) => {
                        setFieldValue("costActivityType", value);
                      }}
                    >
                      {LIST_COST_TYPE.map((l) => (
                        <Select.Option key={l.costType} value={l.costType}>
                          {`${translation.costType[l.costType]}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <CustomDivider dashed title="EXTENSÃO" />
              <Row type="flex">
                <Col span={24}>
                  <ExtensionContainer>
                    <ExtensionButton type="submit">
                      <ExportExcelIcon />
                    </ExtensionButton>
                  </ExtensionContainer>
                </Col>
              </Row>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default ExportReportDisbursementModal;
