import { call, put, select } from "redux-saga/effects";
import { Creators as FarmActions } from "../ducks/farm";
import { Creators as UserActions } from "../ducks/user";
/** Services */
import {
  getFarmIndex,
  saveFarm,
  getFarmShow,
  inactivateFarmByGroupIdAndId,
  reactivateFarmByGroupIdAndId,
} from "../../services/farmService";
import { notification } from "antd";

export const getAppState = (state) => state.app;

export function* indexFarm(action) {
  try {
    const {
      data: { results: allFarms },
    } = yield call(getFarmIndex, action.payload);
    yield put(FarmActions.indexFarmSuccess(allFarms));
  } catch (error) {
    yield put(FarmActions.indexFarmError(error));
  }
}

export function* getFarm(action) {
  try {
    const {
      data: { results: farm },
    } = yield call(getFarmShow, action.payload);
    yield put(FarmActions.showFarmSuccess(farm));
    yield put(FarmActions.updateOwnersData([...farm?.owners]));
  } catch (error) {
    yield put(FarmActions.showFarmError(error));
  }
}

export function* storeOrUpdateFarm(action) {
  const {
    translation,
    user: { id: userId },
  } = yield select(getAppState);
  let result = null;
  try {
    const {
      data: { results: newFarm },
    } = yield call(saveFarm, action.payload);
    result = newFarm;
    const { saveOptions } = action.payload;
    yield put(FarmActions.saveFarmSuccess(newFarm, saveOptions));
    notification.success({
      message: translation.farm.messages.successSubmit,
    });

    yield put(UserActions.indexUserGroupsAndFarmsDescriptions(userId));
  } catch (error) {
    const {
      response: {
        data: { code, message },
      },
    } = error;

    if (code === 5004 || code === 5005) {
      notification.error({ message });
    } else if (
      message != null &&
      message === "Document already exist in the system."
    ) {
      notification.error({
        message: translation.farm.messages.errorFarmDocumentAlreadyExists,
      });
    } else if (code === 5038) {
      notification.error({
        message: translation.farm.messages.errorFarmOwnerDocumentAlreadyExists,
      });
    } else {
      notification.error({
        message: translation.farm.messages.errorSubmit,
      });
    }
    yield put(FarmActions.saveFarmError(error));
  }

  if (result != null && Object.entries(result).length > 0) {
    const newPayload = {
      ...action.payload,
      page: 1,
      size: 10,
      sorter: {},
    };
    yield put(
      FarmActions.indexFarm(
        newPayload.groupId,
        newPayload.page,
        newPayload.sorter,
        "",
        10
      )
    );
  }
}

export function* deleteFarm(action) {
  try {
    yield call(inactivateFarmByGroupIdAndId, action.payload);
    yield put(FarmActions.deleteFarmSuccess(true));
    const {
      user: { id: userId },
    } = yield select(getAppState);
    yield put(UserActions.indexUserGroupsAndFarmsDescriptions(userId));
    const newPayload = {
      ...action.payload,
      page: 1,
      size: 10,
      sorter: {},
    };
    try {
      const {
        data: { results: allFarms },
      } = yield call(getFarmIndex, newPayload);
      yield put(FarmActions.indexFarmSuccess(allFarms));
    } catch (error) {
      yield put(FarmActions.indexFarmError(error));
    }
    notification.success({ message: "Fazenda excluída com sucesso" });
  } catch (error) {
    notification.error({ message: "Fazenda não foi excluída" });
    yield put(FarmActions.deleteFarmError(error));
  }
}

export function* activateFarm(action) {
  try {
    yield call(reactivateFarmByGroupIdAndId, action.payload);
    yield put(FarmActions.activateFarmSuccess(true));
    const {
      user: { id: userId },
    } = yield select(getAppState);
    yield put(UserActions.indexUserGroupsAndFarmsDescriptions(userId));
    const newPayload = {
      ...action.payload,
      page: 1,
      size: 10,
      sorter: {},
    };
    try {
      const {
        data: { results: allFarms },
      } = yield call(getFarmIndex, newPayload);
      yield put(FarmActions.indexFarmSuccess(allFarms));
    } catch (error) {
      yield put(FarmActions.indexFarmError(error));
    }
    notification.success({ message: "Fazenda ativada com sucesso" });
  } catch (error) {
    notification.error({ message: "Fazenda não foi ativada" });
    yield put(FarmActions.deleteFarmError(error));
  }
}
