export default {
  diet: {
    title: "Dietas",
    buttonNewDiet: "Nova dieta",
    table: {
      title: "Lista de Dietas",
      columns: {
        status: "Status",
        description: "Dieta",
        phosphorus: "Fósforo (mg)",
        nitrogen: "Nitrogênio (mg)",
        tdn: "NDT (g)",
        cp: "Proteína Bruta (g)",
        calcium: "Cálcio (mg)",
        cost: "Custo (R$/Kg)",
      },
    },
    drawerTitle: "Cadastro de Dieta",
    drawerTitleEdit: "Editar Dieta",
    drawerSubTitle: "Complete os campos abaixo para criar uma nova dieta",
    drawerSubTitleEdit: "Complete os campos abaixo para editar a dieta",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    form: {
      name: "Identificação da dieta",
      namePlaceHolder: "Nome da dieta",
      pasture: "Forrageira",
      pasturePlaceHolder: "Qual é a forrageira da dieta?",
      pastureNoSelection: "Nenhum",
      supplements: "Suplementos/Concent.",
      supplementsPlaceHolder:
        "Qual são os Suplemento/Concent. inseridos na dieta?",
      cost: "Custo da Dieta (R$/Kg)",
      costPlaceHolder: "Qual o custo da dieta em R$/Kg?",
      phosphorus: "Fósforo (mg)",
      phosphorusPlaceHolder: "Qual a quantidade de Fósforo?",
      nitrogen: "Nitrogênio (mg)",
      nitrogenPlaceHolder: "Qual a quantidade de Nitrogênio?",
      calcium: "Cálcio (mg)",
      calciumPlaceHolder: "Qual a quantidade de Cálcio?",
      tdn: "NDT (g)",
      tdnPlaceHolder: "Qual a quantidade de NDT?",
      cp: "Proteína Bruta (g)",
      cpPlaceHolder: "Qual a quantidade de Proteína Bruta?",
      status: "Status da dieta",
      error: {
        requiredPastureSupplement: {
          title: "Erro",
          message:
            "A Forrageira ou pelo menos um Suplemento/Concent. deve ser selecionado",
        },
      },
    },
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar dieta e adicionar nova",
    },
  },
  pasture: {
    title: "Forrageiras",
    buttonNewPasture: "Nova Forrageira",
    table: {
      title: "Lista de Forrageiras",
      columns: {
        status: "Status",
        description: "Forrageira",
        phosphorus: "Fósforo (mg)",
        nitrogen: "Nitrogênio (mg)",
        tdn: "NDT (g)",
        cp: "PB (g)",
        calcium: "Cálcio (mg)",
        cost: "Custo (animal/ha)",
        entryHeight: "Alt. Entrada",
        exitHeight: "Alt. Saída",
        growthSpeed: "Veloc. Cresc.",
        yield: "Rendimento",
      },
    },
    drawerTitle: "Cadastro de Forrageira",
    drawerTitleEdit: "Editar Forrageira",
    drawerSubTitle: "Complete os campos abaixo para criar uma nova forrageira",
    drawerSubTitleEdit: "Complete os campos abaixo para editar a forrageira",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    form: {
      groupFarmVisibility: "Quem irá utilizar a forrageira?",
      name: "Identificação",
      namePlaceHolder: "Nome da forrageira",
      pastureType: "Nome científico",
      fertilized: "Adubado",
      notFertilized: "Não adubado",
      cost: "Custo",
      costPlaceHolder: "Medida: R$",
      cp: "PB: Proteína Bruta",
      cpPlaceHolder: "Medida: g",
      fdn: "FDN: Fibra em detergente neutro",
      fdnPlaceHolder: "Medida: %",
      tdn: "NDT: Nutrientes Digest. Totais",
      tdnPlaceHolder: "Medida: g",
      phosphorus: "Fósforo",
      phosphorusPlaceHolder: "Medida: g/kg",
      nitrogen: "Nitrogênio",
      nitrogenPlaceHolder: "Medida: mg/kg",
      calcium: "Cálcio",
      calciumPlaceHolder: "Medida: g/kg",
      zinc: "Zinco",
      zincPlaceHolder: "Medida: mg/kg",
      sodium: "Sódio",
      sodiumPlaceHolder: "Medida: g/kg",
      formation: "Formação",
      formationPlaceHolder: "Medida: dias",
      rest: "Descanso",
      restPlaceHolder: "Medida: dias",
      entryHeight: "Altura ideal de Entrada",
      entryHeightPlaceHolder: "Medida: cm",
      exitHeight: "Altura ideal de Saída",
      exitHeightPlaceHolder: "Medida: cm",
      growthRate: "Taxa de Crescimento",
      growthRatePlaceHolder: "Medida: kg/ha/dia",
      leafDensityProduction: "Produção MS Foliar",
      leafDensityProductionPlaceHolder: "Medida: t/ha/corte",
      growthSpeed: "Taxa de Crescimento",
      growthSpeedPlaceHolder: "Medida: kg MS/㎡/dia",
      yield: "Rendimento",
      yieldPlaceHolder: "Medida: kg MS/㎡",
      status: "Status da forrageira",
      nutritionalData: "DADOS NUTRICIONAIS",
      capacity: "CAPACIDADE",
      maximumConsumption: "Consumo máximo",
      minimumConsumption: "Consumo mínimo",
      radiogroup: {
        farm: "Somente esta fazenda",
        group: "Fazendas do grupo",
      },
      fda: "FDA",
      lignin: "Lignina",
      ee: "Extrato Etéreo",
      maximumHeight: "Altura Máxima",
      maximumHeightPlaceHolder: "Altura Máxima",
    },
    modal: {
      confirmation: {
        title: "Troca do Tipo da Forrageira",
        message:
          "Ao confirmar a troca do tipo da forrageira os valores de altura ideal de entrada, altura ideal de saída e estações climáticas serão alterados. Deseja continuar?",
        confirmButton: "Confirmar",
        cancelButton: "Cancelar",
      },
    },
    errorConsumptionMaximumLessThanMinimum:
      "O consumo máximo deve ser maior que o consumo mínimo",
    errorEntryHeightLessThanExitEntryHeight:
      "A altura ideal de entrada deve ser maior que a altura ideal de saída",
    errorNecessaryOneSeason:
      "É necessário ter pelo menos uma estação informado",
    errorFertilizedSeasonInfoIncomplete:
      "Informe ou mantenha pendente os dados da forrageira adubado em todos os períodos climáticos",
    errorUnfertilizedSeasonInfoIncomplete:
      "Informe ou mantenha pendente os dados da forrageira não adubado em todos os períodos climáticos",
    errorPastureInfoForAllSeasonsIncomplete:
      "Informe ou mantenha pendente os dados da forrageira adubado e não adubado em todos os períodos climáticos",
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar forrageira e adicionar nova",
    },
  },
  breed: {
    title: "Raças",
    buttonNewBreed: "Nova raça",
    table: {
      title: "Lista de Raças",
      columns: {
        status: "Status",
        geneticGroup: "Grupo Genético",
        description: "Descrição",
        name: "Raça",
        asymptoticWeight: "Peso Assintótico(kg)",
        estimatedMaximumAge: "Idade adulta",
        correctionFactor: "Fator de Correção",
        maturationRate: "Taxa de Maturação",
      },
      menu: {
        parameters: "Parâmetros",
      },
    },
    drawerTitle: "Cadastro de Raça",
    drawerTitleEdit: "Editar Raça",
    drawerSubTitle: "Complete os campos abaixo para criar uma nova raça",
    drawerSubTitleEdit: "Complete os campos abaixo para editar a raça",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    form: {
      name: "Identificação da Raça",
      namePlaceHolder: "Nome da raça",
      description: "Descrição",
      descriptionPlaceHolder: "Descrição da raça",
      geneticGroup: "Grupo Genético",
      geneticGroupPlaceHolder: "Selecione o Grupo Genético",
      status: "Status do raça",
    },
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar raça e adicionar novo",
      save_and_add_parameters: "Criar raça e configurar paramêtros",
    },
    drawerParametersTitle: "Parâmetros da Raça",
    formParameters: {
      asymptoticWeight: "Peso assintótico",
      asymptoticWeightPlaceHolder: "Defina o peso",
      theoricalCurve: "Curva teórica",
      estimatedMaximumAge: "Idade Adulta",
      estimatedMaximumAgePlaceHolder: "Idade Adulta",
      correctionFactor: "Fator de Correção",
      correctionFactorPlaceHolder:
        "Fator de Correção para cálculo da curva da raça",
      maturationRate: "Taxa de Maturação",
      maturationRatePlaceHolder:
        "Taxa de maturação para cálculo da curva da raça",
      status: "Status dos parâmetros da raça",
      extimatedCurveTitle: "CURVA ESTIMADA DE CRESCIMENTO",
      genetic: "GENÉTICA",
      diet: "DIETA",
      gestationDivider: "Tempo de Gestação",
      minGestationTime: "Mínimo",
      medGestationTime: "Médio",
      maxGestationTime: "Máximo",
    },
  },
  handlingOperation: {
    title: "Operações",
    buttonNewPasture: "Nova operação",
    table: {
      title: "Lista de Operações",
      columns: {
        status: "Status",
        operation: "Operação",
        action: "Ação",
      },
    },
    drawerTitle: "Cadastro de Operação",
    drawerTitleEdit: "Editar Operação",
    drawerSubTitle: "Complete os campos abaixo para criar um nova operação",
    drawerSubTitleEdit: "Complete os campos abaixo para editar a operação",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    form: {
      name: "Operação",
      namePlaceHolder: "Nome da operação",
      action: "Ação",
      actionPlaceHolder: "Selecione a ação do procedimento",
      recurrence: "Recorrência",
      recurrenceAmount: "Qtd.",
      quarantine: "Quarêntena",
      quarantineAmount: "Digite a quantidade de dias",
      breed: "Raça",
      breedPlaceHolder: "Selecione as raças",
      gender: "Sexo",
      genderPlaceHolder: "Selecione",
      age: "Idade",
      weight: "Peso",
      dontExecuteOperation: "Não executar operação em animais que estão em:",
      inQuarantine: "Quarentena",
      inPregnancy: "Prenhez",
      operationCost: "Custo da operação",
      noCost: "Sem custo",
      haveCost: "Com custo(defina o custo no manejo)",
      status: "Status do operação",
    },
    parameters: "PARÂMETROS",
    limitations: "LIMITAÇÕES",
    errors: {
      recurrenceMinAndMaxDayCanBeNull: {
        title: "Erro",
        message:
          "O número mínimo e máximo de dias da recorrência não pode estar em branco.",
      },
      recurrenceQtdCanBeNull: {
        title: "Erro",
        message:
          "A quantidade de vezes da recorrência não pode estar em branco.",
      },
    },
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar operação e adicionar nova",
    },
  },
  handling: {
    title: "Manejos",
    tabs: {
      pending: "Pendentes",
      finalized: "Finalizados",
      dietStrategies: "Estratégias de dieta",
      vacinations: "Veterinários",
      handlingReproduction: "Reprodução",
      animalWeightHistory: "Pesagens",
      picketSupplementSupply: "Fornecimento",
      troughFeedingAssessment: "Leitura de Cocho",
    },
    vacinations: {
      buttonNew: "Nova Aplicação",
      form: {
        titleEdit: "Editar Aplicação",
        titleCreateNew: "Nova Aplicação",
        dividerTitle: "APLICAÇÃO ANTERIOR",
        animalId: "Animal",
        lastDailyWeight: "Última pesagem",
        handlingNumber: "Animal",
        veterinarySupplementId: "Produto",
        veterinarySupplementApplicationType: "Forma de Aplicação",
        veterinarySupplementApplicationValue: "Dose",
        applicationQuantity: "Qtde Aplicada",
        applicationUnit: "Unidade",
        applicationDay: "Data Aplicação",
      },
      table: {
        title: "Veterinários",
        columns: {
          animalName: "Animal",
          veterinarySupplementName: "Nome",
          type: "Tipo",
          applicationDate: "Data",
          applicationQuantity: "Quantidade",
          applicationUnit: "Unidade",
        },
      },
    },
  },
  payments: {
    title: "Pagamentos",
  },
  parameters: {
    title: "Parâmetros",
    tabs: {
      general: "Gerais",
      breed: "Raças",
      diet: "Dietas",
      supplement: "Suplementos/Concent.",
      pasture: "Forrageira",
      alert: "Alertas",
      benchmark: "Benchmarks",
      marketAnalysis: "Curva de Preços",
      cost: "Custos",
      activity: "Atividades",
      boitelPrice: "Boitel - Diárias",
      integration: "Integrações",
      financial: "Financeiro/Custos",
      reproduction: "Reprodução",
      reproductionParameter: "Parâmetros",
      semen: "Sêmens",
      inseminators: "Inseminadores",
      supplie: "Insumos",
      animal: "Animais",
      deathReasons: "Causa Mortis",
      unit: "Unidades",
      veterinary: "Veterinários",
      commercialization: "Comercialização",
      quota: "Cotas",
      multipleBull: "Touro Múltiplo",
      reproductiveProtocol: "Protocolos",
      handlingParameters: "Manejos",
      farmDayScaleTreatmentParameter: "Escala de Tratos",
      farmReadFeederTreatmentParameter: "Leitura de Cocho",
      ageRange: "Faixa Etária",
    },
  },
  boitelPrice: {
    title: "Boitel - Diárias",
    buttonNew: "Novo",
    table: {
      title: "Boitel - Diárias",
      columns: {
        name: "Nome",
        farmName: "Nome da Fazenda",
        validity: "Vigência",
        status: "Status",
      },
    },
  },
  integrationPartners: {
    table: {
      title: "Integrações",
      columns: {
        status: "Situação",
        system: "Sistema",
        authType: "Tipo Autenticação",
      },
    },
    authType: {
      Token: "Token",
      UserAndPassword: "Usuário e Senha",
    },
  },
  imports: {
    title: "Importações",
    tabs: {
      animal: "Animais",
      weight: "Pesagens",
      supplement: "Suplementos",
      financialTransactions: "Mov. Financeiras",
    },
    status: {
      queue: "Aguardando",
      processing: "Processando",
      processed: "Processado",
      deleting: "Deletando",
      error: "Erro",
    },
    animal: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Animais",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberAlerted: "Alertas",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Animais",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            handlingNumber: "Identificação do Animal",
            message: "Motivo",
            alert: "Alertas",
          },
        },
      },
    },
    weight: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Pesagens",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Pesagens",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            weight: "Peso",
            handlingNumber: "Identificação do Animal",
            message: "Motivo",
          },
        },
      },
    },
    supplement: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Suplementos",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Suplementos",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            name: "Identificação do Suplemento",
            message: "Motivo",
          },
        },
      },
    },
    financialTransactions: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Mov. Financeiras",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Mov. Financeiras",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            name: "Identificação da Mov. Financeira",
            message: "Motivo",
          },
        },
      },
    },
  },
  benchmark: {
    title: "Benchmarks",
    buttonNewBenchmark: "Novo benchmark",
    table: {
      title: "Lista de Benchmarks",
      columns: {
        status: "Status",
        description: "Benchmark",
      },
    },
    drawerTitle: "Cadastro de Benchmark",
    drawerTitleEdit: "Editar Benchmark",
    drawerSubTitle:
      "Complete os campos abaixo para cadastrar um novo benchmark",
    drawerSubTitleEdit: "Complete os campos abaixo para editar o benchmark",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    form: {
      name: "Nome",
      namePlaceHolder: "Identificação",
      status: "Status do benchmark",
      groupFarmVisibility: "Quem irá visualizar o benchmark?",
      radiogroup: {
        farm: "Esta fazenda",
        group: "Fazendas do grupo",
      },
      harvestDivider: "SAFRAS",
    },
    errorAtLeastOneKPIValue:
      "Informar pelo menos um valor para qualquer Indicadores (KPIs)",
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar benchmark e adicionar novo",
    },
  },
  marketAnalysis: {
    title: "Curva de Preços",
    buttonNewMarketAnalysis: "Nova Curva de Preços",
    table: {
      title: "Lista de Curva de Preços",
      columns: {
        status: "Status",
        type: "Tipo",
        description: "Nome",
        baseline: "Padrão",
      },
    },
    type: {
      bovexo: "BovExo",
      progressive: "Progressivo",
      linear: "Linear",
      free: "Livre",
    },
  },
  supplement: {
    title: "Suplementos/Concent.",
    buttonNewSupplement: "Novo Suplemento/Concent.",
    table: {
      title: "Lista de Suplementos/Concent.",
      columns: {
        status: "Status",
        description: "Suplemento/Concent.",
        industrialName: "Nome Industrial",
        supplierName: "Fornecedor",
        createdAt: "Data de Criação",
        userName: "Responsável",
        version: "Versão",
        cp: "PB: Proteína Bruta",
        tdn: "NDT: Nutrientes Digest. Totais",
        npn: "NNP - Equivalente Proteico (Máx)",
        dryMatterPercentage: "MS: Matéria Seca",
        phosphorus: "Fósforo",
        nitrogen: "Nitrogênio",
        calcium: "Cálcio",
        zinc: "Zinco",
        sodium: "Sódio",
        additiveEffect: "Efeito Aditivo",
        fda: "FDA",
        lignin: "Lignina",
        ee: "Extrato Etéreo",
      },
    },
    drawerTitle: "Cadastro de Suplemento/Concent.",
    drawerTitleEdit: "Editar Suplemento/Concent.",
    drawerSubTitle:
      "Complete os campos abaixo para cadastrar um novo Suplemento/Concent.",
    drawerSubTitleEdit:
      "Complete os campos abaixo para editar o Suplemento/Concent.",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    form: {
      supplementType: "Ração/mistura ou Suplemento ?",
      supplementTypeHelp:
        "Ração/Mistura: Dieta pronta, produzida na própria fazenda. Suplementos: Produto adquirido e fornecido de forma pura.",
      supplementClassification: "Qual a classificação do Suplemento/Ração ?",
      supplementClassificationHelp: {
        title_0: "Suplemento Mineral",
        description_0:
          " quando possuir na sua composição, macro e/ou micro elemento mineral, podendo apresentar, no produto final, um valor menor que quarenta e dois porcento de equivalente protéico.",
        title_1: "Suplemento Mineral Protéico",
        description_1:
          " quando possuir na sua composição, macro e/ou micro elemento mineral, pelo menos 20% de proteína bruta (PB) e fornecer, no mínimo, 30 gramas de proteína bruta (PB) por 100 Kg de PV.",
        title_2: "Suplemento Mineral Protéico Energético",
        description_2:
          " quando possuir na sua composição, macro e/ou micro elemento mineral, pelo menos 20% de proteína bruta, fornecer, no mínimo, 30 gramas de proteína bruta e 100 gramas de nutrientes digestíveis totais (NDT) por 100 Kg de PV.",
        source: "*Conforme Mapa - IN12 de 30 de novembro de 2004.",
      },
      name: "Nome genérico",
      namePlaceHolder: "Identificação",
      importCode: "Código de importação",
      industrialName: "Nome Industrial",
      industrialNamePlaceHolder: "Nome industrial",
      supplierName: "Fornecedor",
      supplierNamePlaceHolder: "Nome do fornecedor",
      status: "Status do Suplemento/Concent.",
      nutritionalDataDivider: "DADOS NUTRICIONAIS",
      capacityDivider: "QUANTIDADES POR MODO DE OFERTA",
      sustenanceCapacity: "QUANTIDADES POR MODO DE OFERTA",
      information: 'Todas as informações são referentes a "Animal/dia"',
      sustenanceCapacityDrawerTitle:
        "Quantidades por Modo de Oferta do Suplemento/Concent.",
      minimumAmount: "Qtd Mín",
      maximumAmount: "Qtd Máx",
      minimum: "Mínimo",
      maximum: "Máximo",
      ratio: "Proporção",
      cost: "Custo diário",
      costPlaceHolder: "Medida: R$",
      cp: "PB: Proteína Bruta",
      cpPlaceHolder: "Medida: g/kg",
      tdn: "NDT: Nutrientes Digest. Totais",
      tdnPlaceHolder: "Medida: g/kg",
      npn: "NNP - Equivalente Proteico (Máx)",
      npnPlaceHolder: "Medida: g/kg",
      dryMatterPercentage: "MS: Matéria Seca",
      phosphorus: "Fósforo",
      phosphorusPlaceHolder: "Medida: g/kg",
      nitrogen: "Nitrogênio",
      nitrogenPlaceHolder: "Medida: mg/kg",
      calcium: "Cálcio",
      calciumPlaceHolder: "Medida: g/kg",
      zinc: "Zinco",
      zincPlaceHolder: "Medida: mg/kg",
      sodium: "Sódio",
      sodiumPlaceHolder: "Medida: g/kg",
      voluntaryConsumption: "Consumo Voluntário",
      minimumVoluntaryConsumptionPlaceHolder: "Informe",
      maximumVoluntaryConsumptionPlaceHolder: "Informe",
      pure: "Oferta Puro",
      mix: "Oferta Mistura",
      recommendedTrough: "Cocho Recomendado",
      recommendedTroughPlaceHolder: "Informe",
      groupFarmVisibility: "Quem irá utilizar o Suplemento/Concent.?",
      radiogroup: {
        farm: "Esta fazenda",
        group: "Fazendas do grupo",
      },
      additiveEffect: "Efeito Aditivo",
      additiveEffectPlaceHolder: "Medida: g/dia",
      additiveEffectHelp:
        "Alguns suplementos minerais possuem aditivos em sua formulação que potencializam o ganho de peso dos animais, podendo o fornecedor indicar um ganho incremental (g/dia), somando ao ganho previsto calculado (Pasto + Suplemento) ou indicar por um aumento % do total de Proteína Bruta (PB) a ser considerado no calculo.",
      fda: "FDA",
      fdaPlaceHolder: "Medida: g/kg",
      lignin: "Lignina",
      ligninPlaceHolder: "Medida: g/kg",
      ee: "Extrato Etéreo",
      eePlaceHolder: "Medida: %/MS",
    },
    errorVoluntaryConsumptionMaximumLessThanMinimum:
      "O consumo recomendado pelo fornecedor máximo deve ser maior ou igual ao consumo mínimo",
    errorPureMaximumLessThanMinimum:
      "A Oferta Puro máxima deve ser maior ou igual a Oferta Pura mínima",
    errorPureMaximumTroughLessThanMinimumTrough:
      "O valor máximo de Cocho recomendado para Oferta Puro deve ser maior ou igual ao valor mínimo",
    errorMixMaximumLessThanMinimum:
      "A Oferta Mistura máxima deve ser maior ou igual a Oferta Mistura mínima",
    errorMixMaximumTroughLessThanMinimumTrough:
      "O valor máximo de Cocho recomendado para Oferta Mistura deve ser maior ou igual ao valor mínimo",
    errorNecessarySustenanceCapacity:
      "É necessário informar os dados de quantidades por modo de oferta.",
    errorWrongNpnInput:
      "Atenção! São classificados como Suplementos Minerais, somente produtos com até 42,0 % de NNP - Equivalente Proteico. Por favor, verifique a Classificação deste Suplemento.",
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar Suplemento/Concent. e adicionar novo",
    },
    details: {
      tabs: {
        supplementData: "Suplemento",
        supplementHistory: "Histórico",
      },
      supplementType: "Tipo",
      supplementClassification: "Classificação",
      name: "Nome genérico",
      industrialName: "Nome Industrial",
      supplierName: "Fornecedor",
      ratio: "Proporção",
      cost: "Custo",
      nutritionalDataDivider: "DADOS NUTRICIONAIS",
      tdn: "NDT: Nutrientes Digest. Totais",
      cp: "PB: Proteína Bruta",
      dryMatterPercentage: "MS: Matéria Seca",
      calcium: "Cálcio",
      phosphorus: "Fósforo",
      nitrogen: "Nitrogênio",
      zinc: "Zinco",
      sodium: "Sódio",
      voluntaryConsumption: "Consumo Voluntário",
      pure: "Oferta Puro",
      mix: "Oferta Mistura",
      recommendedTrough: "Cocho Recomendado",
      minimum: "Mínimo",
      maximum: "Máximo",
      information: 'Todas as informações são referentes a "Animal/dia"',
      capacityDivider: "QUANTIDADES POR MODO DE OFERTA",
      sustenanceCapacity: "QUANTIDADES POR MODO DE OFERTA",
      sustenanceCapacityDrawerTitle:
        "Quantidades por Modo de Oferta do Suplemento/Concent.",
      npn: "NNP - Equivalente Proteico (Máx)",
      additiveEffect: "Efeito Aditivo",
      fda: "FDA",
      lignin: "Lignina",
      ee: "Extrato Etéreo",
    },
  },
  lot: {
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    tabs: {
      production: "Produção",
      movementHistory: "Histórico Mov.",
      receipt: "Recebimento",
    },
    production: {
      title: "Lotes de produção",
      buttonNewLot: "Novo lote de produção",
      details: {
        breadCumbsProductionLot: "Lotes em produção",
        breadCumbsLotDetails: "Detalhes do lote ",
        createdAt: "Criação:",
        options: "Opções",
        labelSystem: "ATIVIDADE",
        labelDietStrategy: "ESTRATÉGIA DE DIETA",
        baseline: "Baseline",
        optionalStrategies: "Estratégias opcionais",
        optional: "Opcionais",
        labelAnimals: "ANIMAIS",
        labelAsymptoticWeight: "PESO ASSINTÓTICO",
        labelMarketValuation: "AVALIAÇÃO DE MERCADO",
        labelProductionCost: "Custo de Produção",
        makeValuation: "Fazer avaliação",
        labelWeights: "PESAGENS",
        labelAverageWeight: "PESO MÉDIO:",
        labelAverageWeightGain: "GMD:",
        labelPicket: "PIQUETE / BAIA:",
        labelProductionArea: "ÁREA UTIL:",
        labelTotalArea: "ÁREA TOTAL:",
        labelSupportCapacity: "C. DE SUPORTE:",
        titleAnimals: "Animais",
        titleWeigth: "Peso",
        viewBy: "Visualizar por:",
        tooltipEstimatedCurve:
          'Curva teórica obtida de RAÇA, clique em "Estimar para lote" para definir os valores do Lote',
        rankBy: "Ranquear por:",
      },
      table: {
        columns: {
          lotNumber: "Nº Lote",
          lotName: "Lote",
          salesScenario: "Cenário de Venda",
          dietStrategyName: "Estratégia de Dieta",
          profitCenter: "Atividade",
          createdAtAndEndAt: "Criação / Encerramento",
          status: "Status",
          amount: "Qtd.",
          gdp: "GDP",
          gmd: "GMD",
          diet: "Dieta",
          averageWeight: "Peso médio",
          target: "Alvo",
          picketName: "Piquete/Baia",
        },
      },
      drawer: {
        title: "Criar lote de produção",
        titleEdit: "Editar lote de produção",
        subtitle: "Complete os campos abaixo para criar um lote",
        subtitleEdit: "Complete os campos abaixo para editar um lote",
        form: {
          status: "Status",
          name: "Nome do lote*",
          namePlaceHolder: "Nome do lote",
          identification: "Código de Importação",
          identificationPlaceHolder: "Código de Importação",
          profitCenter: "Atividade*",
          profitCenterPlaceHolder: "Selecione o Atividade",
          asymptoticWeight: "Peso Assintótico",
          carcassHarnessing: "Aproveitamento de Carcaça",
          informPlaceHolder: "Informe",
          picket: "Piquete / Baia",
          picketMessage:
            "Selecione o piquete / baia se deseja já alocar esse lote",
          picketPlaceHolder: "Selecione o piquete / baia",
          dietStrategyBaseLine: "Estratégia de dieta",
          dietStrategyBaseLineMessage:
            "Selecione a estratégia que será utilizada para alimentação dos animais adicionados a este lote",
          dietStrategies: "Estratégia de Dieta - Opcionais",
          dietStrategiesPlaceHolder:
            "Selecione um ou mais estratégias de dieta",
          dietStrategiesTooltip:
            "As estratégias de dieta opcionais selecionadas serão utilizadas futuramente nas simulações de Cenários de compra e venda",
          checkboxSaveAndAddNewAnimals: "Salvar e adicionar animais",
          picketId: "Piquete",
          picketLotEntry: "Data de entrada do Lote",
          breedId: "Raça",
          referenceAcquisitionDate: "Data de aquisição",
          referenceBirthdayDate: "Nascimento animal",
          dismembermentDate: "Data de desmembramento",
          referenceAcquisitionWeight: "Peso médio por animal",
        },
      },
      saveOptions: {
        only_save: "Salvar somente",
        save_and_create_new: "Salvar e adicionar novo Lote",
      },
      addAnimalsPage: {
        listLotProduction: "Lista: Lotes de produção",
        title: "Adicionar animal ao lote",
        descriptionMessage:
          "Selecione os animais na coluna cinza e mova-os para a coluna laranja clicando no botão",
        selectPlaceHolder:
          "Selecione a raça de animais que devem compor o lote",
        listAnimals: "Lista de animais",
        productionLot: "Lote de produção",
        modal: {
          message: "Clique em confirmar para adicionar os animais ao lote",
          lot: "Lote",
          profitCenter: "Atividade",
          amount: "Quantidade",
        },
        error: {
          noAnimalsTitle: "Erro",
          noAnimalsMessage: "É necessário selecionar um ou mais animais",
        },
      },
      dismemberLotPage: {
        listLotProduction: "Lista: Lotes de produção",
        title: "Desmembrar Lote",
        descriptionMessage:
          "Selecione os animais na tabela e clique no botão 'Desmembrar' para realocar os animais",
        profitCenter: "Atividade",
        productionSubSystem: "Subsistema de produção",
        animalsTotal: "Total de Animais",
        animalsSelected: "Animais selecionados",
        dismemberButton: "Desmembrar",
        destinationLots: "Lotes de destino",
        drawer: {
          title: "Desmembrar Lote",
          description:
            "Selecione abaixo se o desmembramento será em um novo lote ou um lote existente",
          newButton: "Novo",
          existingButton: "Existente",
          alertMessage: "A atividade do lote informado difere do lote atual",
          searchInputPlaceHolder: "Pesquise pela identificação de lote",
        },
      },
    },
    receipt: {
      title: "Lotes de recebimento",
      buttonNewLot: "Novo",
      collapse: {
        animals: "Animais",
        receiptIn: "Recebidos em",
      },
      table: {
        columns: {
          breed: "Raça",
          gender: "Sexo",
          birthday: "Idade/meses",
          weight: "Peso estimado",
          amount: "Qtd. animais",
          confirmedAmount: "Qtd. animais recebidos",
          purchaseCost: "Custo de aquisição",
          numbers: "Numeração",
        },
        boitelColumns: {
          identification: "Identificação",
          amount: "Qtd. animais",
          breed: "Raça",
          entryDate: "Data de entrada",
          weightRange: "Faixa de peso",
          confirmedAmount: "Qtd. animais recebidos",
          identifiedAmount: "Qtd. animais identificados",
          status: "Situação",
        },
        status: {
          confirmed: "Confirmado",
          pending: "Pendente",
        },
      },
      finalize: {
        title: "Criação de lotes de recebimento",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        buttonFinalize: "FINALIZAR RECEBIMENTO",
        buttonFinalizeCancel: "CANCELAR RECEBIMENTO",
        supplier: "Fornecedor",
        dateReceiving: "Data de recebimento",
        animalsReceived: "Animais recebidos",
        grid: {
          title: "Lote de recebimento",
          animalsInserted: "animais inseridos",
          buttonAddAnimals: "Adicionar animais",
          columns: {
            breed: "Raça",
            gender: "Sexo",
            birthday: "Idade/meses",
            weight: "Peso estimado",
            amount: "Qtd.",
            purchaseCost: "Custo de aquisição",
            numbers: "Numeração",
          },
          deleteBox: {
            description: "Ao confirmar o lote será excluído",
            buttonConfirm: "Confirmar",
            buttonCancel: "Cancelar",
          },
        },
        newLotAnimalsReceipt: {
          title: "Novos animais",
          description: "Preencha os dados do recebimento de animais",
          saveOptions: {
            only_save: "Salvar somente",
            save_and_create_new: "Salvar e criar novo recebimento",
            save_and_handle: "Salvar e fazer manejo",
          },
        },
        newLotItem: {
          title: "Agrupamento de animais",
          description:
            "Agrupe os animais em recebimento de acordo com suas características completando as informações abaixo",
          tabGeneralTitle: "Geral",
          tabNumbersTitle: "Numeração",
          breed: "Raça",
          gender: "Sexo",
          birthday: "Nascimento",
          birthdayPlaceHolder: "Selecionar",
          estimatedWeight: "Peso Estimado",
          estimatedWeightPlaceHolder: "Digite o peso - Kg",
          amount: "Quantidade",
          amoutPlaceHolder: "Digite a quantidade",
          purchaseCost: "Custo de aquisição",
          purchaseCostPlaceHolder: "Digite o custo de aquisição",
          rangeDescription:
            "Adicione o número dos animais que possuem as características",
          range: "Intervalos",
          numbersInserted: "Números inseridos",
          animalFarmFunction: "Finalidade",
          animalReproductionCategory: "Categoria",
          saveOptions: {
            only_save: "Salvar somente",
            create_new: "Salvar e criar nova entrada de animais",
          },
        },
        errors: {
          noAnimalsAdd: {
            title: "Nenhum animal adicionado",
            description:
              "É necessário adicionar a quantidade informada de animais",
          },
          amountAnimalsInLotItenBiggerThanAmountAnimalsReceveid: {
            title: "Quantidade de animais maior",
            description:
              "A quantidade de animais inserida é maior do que a informada no lote de recebimento",
          },
          amountAnimalsInLotItenLessThanAmountAnimalsReceveid: {
            title: "Quantidade de animais menor",
            description:
              "A quantidade de animais inserida é menor do que a informada no lote de recebimento",
          },
          amountAnimalsInLotItenBiggerThanAmountReported: {
            title: "Quantidade de animais inválida",
            description:
              "A quantidade de animais inserida é maior do que a informada na quantidade do lote",
          },
        },
      },
      supplier: {
        title: "Entrada de Animais - Compra",
        buttonNext: "Próximo",
        buttonBack: "Voltar",
        titleBox: "Cadastro de Recebimento",
        messageError: "Preencha todos os campos obrigatórios *",
        supplierTitle: "Fornecedor",
        supplierComboBoxPlaceHolder: "Selecionar",
        lotName: "Nome do Lote",
        lotNameInputPlaceHolder: "Nome do Lote de recebimento",
        dateTitle: "Data de entrada",
        datePlaceHolder: "Selecionar",
        shouldCreateLotEntry: "Lançar e criar animais?",
        buttonToday: "HOJE",
        qtyAnimalsTitle: "Quantidade de animais",
        qtyAnimalsInputPlaceHolder: "Quantidade de animais",
        receiptNameTitle: "Número de Recebimento",
        supplierInformationsTitle: "Dados do Fornecedor",
        documentTitle: "CNPJ",
        responsibleTitle: "Responsável",
        emailTitle: "E-mail",
        contactTitle: "Contato",
        addressTitle: "Endereço",
      },
    },
  },
  picket: {
    status: {
      break: "Descanso",
      pasturage: "Pastejo",
      renovation: "Reforma",
      maintenance: "Manutenção",
      leased: "Arrendado",
      deleted: "Excluído",
      active: "Ativo",
      inactive: "Inativo",
    },
    dashboards: {},
    propertyType: {
      own: "Próprio",
      foreign: "Arrendado",
    },
    tabs: {
      picket: "Piquete/Baia",
      retreat: "Retiro/Linha",
    },
    title: "Lista de piquetes / baias",
    buttonNewPicket: "Novo piquete / baia",
    table: {
      columns: {
        picketName: "Piquete / Baia",
        totalAndProductionArea: "Área total/útil",
        propertyType: "Tipo",
        profitCenterName: "Atividade",
        lotName: "Lote",
        status: "Status",
        // inAndOutLot: "Entrada/Saída de lote",
        inAndOutLot: "Entrada de lote",
        supportCapacity: "Capac. suporte",
        averageWeight: "Peso médio",
        gmd: "GMD",
        amountAnimals: "Qtd.",
      },
    },
    pastureTable: {
      title: "Estados dos Pastos",
      titleTooltip:
        "O Gráfico Estado dos Pastos, apresenta diferentes visões para que possa acompanhar e planejar as movimentações de animais entre piquetes, de forma a otimizar o uso das pastagens." +
        " - Estoque MS: Apresenta a disponibilidade (%) de MS da forrageira utilizada, conforme a estação climática e consumo dos animais presentes no Piquete. Diariamente é executado a rotina de recalculo, para atualizar a estimativa de disponibilidade dos próximos 30 dias." +
        " - Taxa de Oferta: Apresenta a relação de Oferta e Consumo de Matéria Seca (MS) da forrageira disponível no piquete, exibindo a quantidade de dias em que o Lote de animais poderá ficar no piquete, de acordo com o estoque de MS disponível e consumo previsto dos animais.",
      columns: {
        picketName: "Piquete",
        status: "Status",
        days: "Dias acumulados",
        lotation: "Taxa de lotação",
      },
      tableType: {
        dryStockOption: "Estoque MS",
        OfferRateOption: "Taxa de Oferta",
      },
    },
    drawerTitle: "Cadastro de Piquete / Baia",
    drawerTitleEdit: "Editar Piquete",
    drawerTitleEditIntensiveSubSystem: "Editar Baia",
    drawerSubTitle:
      "Complete os campos abaixo para criar um novo piquete / baia",
    drawerSubTitleEdit:
      "Complete os campos abaixo para editar o piquete / baia",
    drawerAllocateLotTitle: "Alocação de lote no piquete / baia",
    drawerAllocateLotSubTitle: "Alocação de lote no piquete / baia",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    searchInputPlaceHolder:
      "Pesquise pela identificação do lote e pressione ENTER.",
    alertLotWithProfitCenterDifferent:
      "O piquete / baia receberá a atividade do lote selecionado!",
    detail: {
      name: "Identificação",
      productionSubSystem: "Subsistema",
      supplierId: "Fornecedor",
      supplierDivider: "FORNECEDOR",
      sustenanceCapacityDivider: "CAPACIDADE DE SUPORTE",
      pastureDivider: "FORRAGEIRA",
      infrastructureDivider: "INFRAESTRUTURA",
      pasture: "Forrageira",
      pastureDailyCost: "Custo diário",
      pastureAnimalCapacity: "Ocupação por animal",
      pastureArea: "Área disponível",
      pastureLeafDensity: "Densidade foliar",
      pastureFertilized: "Adubado",
      pastureCurrentHeight: "Altura atual do capim",
      pastureCurrentHeightDate: "Data da altura atual",
      pastureGrowthSpeed: "Velocidade Crescimento",
      troughSpacePerAnimal: "Espaço de cocho por animal",
      totalTroughSpace: "Espaço total de cocho",
      drinkingFountain: "Espaço total do bebedouro",
      lotId: "Lote",
      leaseCost: "Custo do arrendamento",
      profitCenterId: "Atividade",
      profitCenterType: "Tipo",
      pastureId: "Tipo de forrageira",
      moreInformationButton: "MAIS INFORMAÇÃO",
    },
    form: {
      name: "Identificação do piquete / baia",
      namePlaceHolder: "Identificação do piquete / baia",
      importCode: "Código de importação",
      propertyType: "O piquete / baia é interno ou terceirizado?",
      productionSubSystem: "Subsistema de produção",
      productionSubSystemPlaceHolder: "Selecione o Sistema",
      supplierId: "Fornecedor",
      supplierIdPlaceHolder: "Selecione o fornecedor",
      sustenanceCapacity: "CAPACIDADE DE SUPORTE",
      alocateLotTitle: "ALOCAR LOTE DE PRODUÇÃO",
      infrastructureDivider: "INFRAESTRUTURA",
      lotId: "Lote",
      lotIdPlaceHolder: "Selecione um lote",
      lotStartDate: "Entrada do lote atual",
      oldLotEndDate: "Saída do lote antigo",
      leaseMonthlyCost: "Custo do arrendamento",
      profitCenterId: "Atividade utilizada",
      profitCenterIdPlaceHolder: "Selecione a atividade",
      status: "Status do picket",
      sustenanceCapacityDrawerTitle: "Capacidade de Suporte do Piquete",
      sustenanceCapacityDrawerTitleIntensiveSubSystem:
        "Capacidade de Suporte da Baia",
      drinkingFountain: "Espaço total do bebedouro",
      troughSpacePerAnimal: "Espaço de cocho por animal",
      totalTroughSpace: "Espaço total de cocho",
      pastureDivider: "FORRAGEIRA",
      pastureId: "Forrageira",
      pastureIdPlaceHolder: "Selecione o tipo de forrageira",
      pastureAnimalCapacity: "Ocupação por animal",
      pastureArea: "Área disponível",
      pastureLeafDensity: "Densidade foliar",
      pastureFertilized: "Adubado",
      pastureDailyCost: "Custo diário ",
      pastureDailyCostTooltip:
        "Ao informar algum valor neste campo, o mesmo será utilizado na Estratégia de Dieta, independente da Estação Climática. Se nenhum valor for informado, será utilizado o Custo cadastrado em Pasto referente à cada Estação Climática.",
      pastureCurrentHeight: "Altura atual do capim",
      pastureCurrentHeightDate: "Data da altura atual",
      pastureGrowthSpeed: "Velocidade Crescimento",
      observation: "Observações",
      error: {
        requiredPastureId: {
          title: "Dados inválidos",
          message:
            "Selecione o tipo de forrageira quando subsistema de produção for Intensivo ou Semi-Intensivo",
        },
        requiredSupplierId: {
          title: "Dados inválidos",
          message:
            "Selecione o fornecedor quando o tipo de picket for Terceiro",
        },
        supplementConsumptionMeasureTypeInvalid: {
          title: "Dados inválidos",
          message: "Tipo de medida inválida para Tipo de Consumo selecionado",
        },
        productionSubSystemDifferentFromPicketProductionSubSystem: {
          title: "Dados inválidos",
          message:
            "Não pode alocar este lote, pois o sub-sistema de produção é diferente do piquete / baia",
        },
        sustenanceCapacityNotInformed: {
          title: "Dados inválidos",
          message: "Os dados da Capacidade de Suporte devem ser informados",
        },
      },
    },
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar piquete / baia e adicionar novo",
      save_and_add_lot: "Salvar e adicionar lote ao piquete / baia",
    },
    drawerMaintenance: {
      title: "Colocar piquete / baia em manutenção",
      subtitle:
        "Indique no campo abaixo o tempo que o piquete / baia ficará em manutenção",
      labelAmountDays: "Quantidade de dias",
      finishIn: "Finalizado em:",
      infoMessage: "Você será notificado quando o período for finalizado.",
    },
    drawerRenovation: {
      title: "Colocar piquete / baia em reforma",
      subtitle:
        "Indique no campo abaixo o tempo que o piquete / baia ficará em reforma",
      labelAmountDays: "Quantidade de dias",
      finishIn: "Finalizado em:",
      infoMessage: "Você será notificado quando o período for finalizado.",
    },
    drawerBreakTitle:
      "Você tem certeza que quer colocar este picket em Descanso ?",
    drawerInactivate: "Você tem certeza que quer inativar este piquete ?",
    drawerReactivate: "Você tem certeza que quer reativar este piquete ?",
    drawerDelete: "Você tem certeza de que quer excluir este piquete ?",
  },
  retreat: {
    title: "Lista de retiros",
    buttonNew: "Novo Retiro",
    table: {
      columns: {
        status: "Status",
        retreatName: "Retiro",
        area: "Área",
      },
      expandedColumns: {
        picketName: "Piquete/Baia",
        area: "Área",
        activity: "Atividade",
        status: "Status",
        lot: "Lote",
      },
    },
  },
  profitCenter: {
    title: "Atividades",
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar atividade e adicionar novo",
    },
    table: {
      title: "Lista de atividades",
      columns: {
        name: "Name",
        status: "Status",
        type: "Tipo",
        productionSubSystem: "Subsistema de produção",
      },
    },
    drawerTitle: "Cadastro de Atividade",
    drawerTitleEdit: "Editar Atividade",
    drawerSubTitle: "Complete os campos abaixo para criar uma nova atividade",
    drawerSubTitleEdit: "Complete os campos abaixo para editar a atividade",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    modalCheckbox: "Salvar e adicionar lote ao piquete / baia",
    form: {
      name: "Identificação da atividade",
      namePlaceHolder: "Nome da atividade",
      productionSubSystem: "Subsistema de produção",
      productionSubSystemPlaceHolder: "Qual é o subsistema de produção?",
      type: "Tipo",
      typePlaceHolder: "Qual é o tipo de atividade?",
      status: "Status da atividade",
    },
  },
  farm: {
    title: "Fazendas",
    table: {
      title: "Lista de fazendas",
      columns: {
        name: "Nome",
        status: "Status",
      },
    },
    drawerTitle: "Cadastro de Fazenda",
    drawerTitleEdit: "Editar Fazenda",
    drawerSubTitle: "Complete os campos abaixo para criar uma nova fazenda",
    drawerSubTitleEdit: "Complete os campos abaixo para editar a fazenda",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    form: {
      logo: "Informe o logo da sua fazenda",
      confinement: "Confinamento?",
      boitel: "É Boitel ?",
      boitelVisibleToAllSystem:
        "Assinale a caixa, caso você deseje aparecer na lista de Boitel - Diárias para todos usuários BovExo.",
      checkVisibleForAllSystem:
        "Ao assinalar esta caixa, você concorda em adicionar esta fazenda a lista de fornecedores e clientes deste grupo, e permite que esta fazenda seja pesquisada por todos os usuários Bovexo.",
      farmSisbov: "Fazenda ERAS (SISBOV)?",
      farmSisbovInfoTooltip:
        "Ao marcar que a fazenda é ERAS (Estabelecimentos Rurais Aprovados SISBOV), serão habilitados, campos e controles referente a rastreabilidade.",
      name: "Nome fantasia da fazenda",
      namePlaceHolder: "Nome fantasia da fazenda",
      group: "Grupo",
      groupPlaceHolder: "Selecione o grupo",
      companyName: "Razão social",
      companyNamePlaceHolder: "Razão social da fazenda",
      document: "CNPJ",
      documentPlaceHolder: "CNPJ",
      zipCode: "CEP",
      zipCodePlaceHolder: "CEP",
      address: "Endereço",
      addressPlaceHolder: "Estrada, Rodovia, rua....",
      city: "Cidade",
      cityPlaceHolder: "Cidade",
      state: "UF",
      statePlaceHolder: "UF",
      status: "Status da fazenda",
      country: "País",
      countryPlaceHolder: "País",
      totalArea: "Área total",
      rentAreaOutSourced: "Área arrendada para terceiros",
      livestockArea: "Área de Pasto",
      nonProductiveArea: "Área de reserva não produtiva",
      cultivationArea: "Área de cultivo",
      improvementsArea: "Área de melhoria",
      complementAddress: "Complemento",
      complementAddressPlaceHolder: "Complemento",
      farmOwner: "Proprietários",
      weatherStation: "Estação Metereológica",
      weatherStationPlaceHolder: "Estação Metereológica",
    },
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar fazenda e adicionar nova",
    },
    messages: {
      successSubmit: "Fazenda criada/atualizada com sucesso.",
      errorSubmit: "Fazenda não foi criada/atualizada. Contate o suporte.",
      errorFarmDocumentAlreadyExists:
        "Documento da Fazenda já existe em nossa base de dados.",
      errorFarmOwnerDocumentAlreadyExists:
        "Documento do Proprietário já existe em nossa base de dados.",
    },
  },
  farmOwner: {
    title: "Proprietários da Fazenda",
    buttonNew: "Novo",
    table: {
      columns: {
        code: "Cód.",
        name: "Nome",
        document: "CPF/CNPJ",
        participation: "% Part.",
        mainOwner: "Principal ?",
      },
    },
    form: {
      code: "Còdigo",
      name: "Nome",
      document: "CPF/CNPJ",
      participation: "Participação",
      mainOwner: "Principal ?",
    },
  },
  supplier: {
    title: "Fornecedores",
    types: {
      supplement: "Suplemento/Concent.",
      technology: "Tecnologia",
      cattleFarmer: "Pecuarista",
      others: "Outros",
      veterinarySupplement: "Insumos Veterinários",
    },
    table: {
      title: "Lista de fornecedores",
      columns: {
        name: "Nome",
        type: "Tipo",
        status: "Status",
        phoneNumber: "Contato",
        email: "Email",
        city_uf: "Cidade/UF",
      },
    },
    drawerTitle: "Cadastro de Fornecedor",
    drawerTitleEdit: "Editar Fornecedor",
    drawerSubTitle: "Complete os campos abaixo para criar uma nova fornecedor",
    drawerSubTitleEdit: "Complete os campos abaixo para editar o fornecedor",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    form: {
      name: "Fornecedor",
      namePlaceHolder: "Nome do fornecedor",
      type: "Tipo",
      typePlaceHolder: "Tipo do fornecedor",
      phoneNumber: "Contato",
      phoneNumberPlaceHolder: "Telefone para contato",
      document: "CPF/CNPJ",
      documentPlaceHolder: "CPF ou CNPJ",
      email: "Email",
      emailPlaceHolder: "E-mail",
      zipCode: "CEP",
      zipCodePlaceHolder: "CEP",
      address: "Endereço",
      addressPlaceHolder: "Estrada, Rodovia, rua....",
      city: "Cidade",
      cityPlaceHolder: "Cidade",
      state: "UF",
      statePlaceHolder: "UF",
      country: "País",
      countryPlaceHolder: "País",
      status: "Status do fornecedor",
      groupFarmVisibility: "Quem irá utilizar o fornecedor?",
      radiogroup: {
        farm: "Esta fazenda",
        group: "Fazendas do grupo",
      },
    },
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar fornecedor e adicionar novo",
    },
  },
  animal: {
    buttonNew: "Novo animal",
    buttonGenerateReport: "Gerar Listagem de Animais",
    buttonGenerateReportInfoTooltip:
      "Selecione um ou vários animal(is) na tabela abaixo para gerar o relatório.",
    buttonGenerateReportERA: "Gerar Relatório de Movimentações",
    listingReportTitle: "Listagem de Animais",
    dashboard: {
      activeAnimals: "ANIMAIS ATIVOS",
      activeLots: "LOTES ATIVOS",
      animalsByGender: {
        title: "Quantidade por sexo",
        female: "Fêmea",
        male: "Macho",
      },
    },
    columns: {
      title: "Lista de animais",
      buttonCompare: "COMPARAR",
      handlingNumber: "Identificação",
      picket: "Piquete / Baia",
      lot: "Lote",
      sisbov: "Sisbov",
      tagId: "Id. Eletrônico",
      breed: "Raça",
      gender: "Sexo",
      birthdayMonths: "Idade",
      weightRanking: "Ranking",
      currentWeight: "Último Peso",
      gdp: "GMD",
      lastDailyWeightDate: "Últ. Pesagem",
      target: "Alvo",
      status: "Status",
      supplier: "Fornecedor",
      reproductionCategory: "Categoria Rep.",
      reproductionStatus: "Situação Rep.",
      reproductionState: "Estado Rep.",
    },
    details: {
      animalProductionPageLink: "Animais em produção",
      animalPageDescription: "Detalhes do Animal",
      emptyMessage: "Dados do animal não encontrados",
      bovexoId: "Id Bovexo",
      sisbov: "SISBOV",
      age: "Nascimento",
      source: "Fornecedor",
      farmOwner: "Proprietário",
      castrated: "Castrado",
      tagId: "Id. Eletrônica",
      sex: "Sexo",
      male: "Macho",
      female: "Fêmea",
      acquisition: "Aquisição",
      handlingNumber: "Identificação",
      weight: "Peso",
      current: "Atual",
      location: "Localização",
      picket: "Piquete / Baia",
      lot: "Lote",
      breed: "Raça",
      weightGain: "Ganho de peso",
      accumulated: "Acumulado",
      lastWeight: "Último peso",
      forSlaughter: "para Abate",
      expected: "Esperado",
      asymptoticWeight: "Peso Assintótico",
      economicReturn: "Retorno econômico",
      currentValue: "Valor atual",
      accumulatedCost: "Custo acumulado",
      animalFarmFunction: "Finalidade",
      animalReproductionCategory: "Categoria",
      margin: "Margem",
      appropriateCosts: "Custos Apropriados",
      femaleSituation: "Situação",
      femaleSituationTime: "Tempo de situação",
      coverageDate: "Data Cobertura",
      coverageType: "Tipo Cobertura",
      coverageSemenOrMaleIdentification: "Touro",
      weightCoverage: "Peso na Cobertura",
      tab: {
        production: "Produção",
        reproduction: "Reprodução",
        genetic: "Genética",
        geneology: "Genealogia",
        evaluation: "Avaliação",
        zoonosis: "Zoonose",
        weighing: "Pesagens",
        dailyWeights: "Pesos diários",
        weightGains: "Ganhos de peso (GMD)",
      },
      history: {
        title: "Histórico de pesos",
        tooltipHistoryWeight:
          "Nesta opção, será apresentadas as pesagens registradas para o animal e o respectivo ganho de peso.\n" +
          "\n" +
          " 1. Pesagens: São exibidos todos os pesos registrados para animal, mesmo que ocorra mais de uma pesagem no mesmo dia, como por exemplo, pesagens de origem de balanças de pesagens dinâmicas.\n" +
          " 2. Pesos Diários: São os pesos que efetivamente serão considerados para o animal, seja para cálculo de GDP ou GMD. Se utilizada balanças de pesagens dinâmicas, que podem registrar mais de um peso no mesmo dia para o mesmo animal, será considerada a regra definida em configuração de Pesos, disponível em [Parâmetros/Gerais].\n" +
          " 3. Ganhos de peso (GMD): refere-se ao GMD, ou seja, o ganho diário entre a pesagem da data e sua anterior.",

        grid: {
          emptyMessage: "Não há histórico de peso registrado",
          in: "Entrada",
          out: "Saída",
          manual: "Manual",
          date: "Data",
          hour: "Hora",
          inOut: "E/S",
          weight: "Peso(kg)",
          eventTitle: "Tipo",
          originEvent: {
            birthday: "Nasc",
            purchase: "Compra",
            weaning: "Desm",
            sale: "Venda",
            transfer: "Transferência",
            weighing: "",
          },
        },
      },
      totalWeight: {
        title: "PESO TOTAL",
        tooltip:
          "Este gráfico compara o último peso do animal com a media dos animais semelhantes no mesmo grupo genético, raça e do mesmo lote",
        graph: {
          currentWeight: "Peso Atual",
          lot: "Lote",
          genetic: "Genética",
          breed: "Raça",
          weight: "Peso",
          type: "Tipo",
          estimated: "Estimado ED",
        },
      },
      averageDailyGain: {
        title: "GANHO MÉDIO DIÁRIO",
        tooltip:
          "Este gráfico compara o último ganho diário de peso do animal com a media dos animais semelhantes no mesmo grupo genético, raça e do mesmo lote",
        graph: {
          currentGain: "Ganho Atual",
          lot: "Lote",
          genetic: "Genética",
          breed: "Raça",
          weight: "Peso",
          type: "Tipo",
          estimated: "Estimado ED",
        },
      },
      dailyWeightGain: {
        title: "EVOLUÇÃO DO PESO E GDP",
        graph: {
          weight: "Peso",
          time: "Dias",
        },
      },
    },
    drawerTitleEdit: "Editar animal",
    drawerSubTitleEdit: "Preencha os dados abaixo para editar um animal",
    drawerSubTitleEditDeathAnimal:
      "Animal em óbito, todos os campos foram desativados",
    drawerAnimalDeathTitle: "Óbito animal",
    animalDeathPopConfirmTitle:
      "Ao confirmar, o animal é considerado em estado de óbito permanente",
    edit: {
      form: {
        nickname: "Nome",
        nicknamePlaceHolder: "Nome",
        sisbov: "SISBOV",
        sisbovPlaceHolder: "SISBOV",
        tagId: "Id. Eletrônica",
        tagIdPlaceHolder: "Id. Eletrônica",
        sex: "Sexo",
        male: "Macho",
        female: "Fêmea",
        acquisition: "Aquisição",
        handlingNumber: "Identificação",
        handlingNumberPlaceHolder: "N. de identificação do animal",
        breed: "Raça",
        breedPlaceHolder: "Selecione uma raça",
        cap: "Animal castrado?",
        birthday: "Data de nascimento",
        birthdayWeight: "Peso Nascimento",
        firstInseminationWeight: "Primeira inserminação",
        weaningWeight: "Peso Desmame",
        weaningDate: "Data Desmame",
        asymptoticWeight: "Peso Assintótico",
        asymptoticWeightInfoTooltip:
          "Peso assintótico é o peso que, com base nas informações que o usuário/produtor possui, sobre o histórico do Animal ou Lote de Animais, (considerando a genética, manejo anterior, interação genótipo-ambiente, frame, entre outras variáveis), acredite ser o potencial limite de peso. Ou seja, acima deste peso, dificilmente este animal ganhará peso, em condições normais.",
        acquisitionDate: "Data de aquisição",
        purchaseCost: "Preço da aquisição",
        acquisitionEstimatedWeight: "Peso aquisição",
        carcassHarnessing: "Aproveitamento da Carcaça",
        animalFarmFunction: "Finalidade",
        animalReproductionCategory: "Categoria",
        sisbovIdentificationDate: "Data Identificação",
        sisbovInsertBNDDate: "Data Inclusão BND",
        sisbovSlaughterDate: "Data Liberação Abate",
        farmOwner: "Proprietário",
      },
      weights: "PESOS",
      receipt: "RECEBIMENTO",
      sale: "VENDA",
    },
    drawerAnimalDiscardTitle: "Descarte do animal",
    discard: {
      motives: {
        Age: "Idade",
        ReproductiveProblems: "Problemas Reprodutivos",
        IllnessesOrPhysicalProblems: "Doenças ou Problemas Físicos",
        BodyCondition: "Condição corporal",
        NegativePregnancy: "Prenhez Negativa",
        MaternalAbility: "Habilidade Materna",
      },
    },
    carcassHarnessingInfoTooltip:
      "O aprov do animal deve ser informado caso tenha uma análise de carcaça por ultrassom. O aproveitamento do Lote e da Estratégia de dieta são expectativas. Quando ambas estão presentes, prevalecem, nesta ordem Animal, Dieta, Lote",
  },
  dietStrategy: {
    new: {
      title: "Criação de estratégia",
      message:
        "Selecione o período total da estratégia e defina as dietas e o tempo de execução de cada uma",
      name: "Nome da estratégia",
      namePlaceHolder: "Nome da estrtégia de dieta",
      totalPeriod: "Período total",
      year: "Ano",
      oneYear: "1 ano",
      twoYears: "2 anos",
      threeYears: "3 anos",
      dietAndExecutionTime: "Dietas & tempo de execução",
      dietPlaceHolder: "Selecione uma dieta",
      deleteDietPeriod: "Apagar dieta",
      totalTime: "Tempo total",
      weeks: "Semana(s)",
      buttonAddDiet: "Adicionar Dieta",
      drawerTitle: "Resumo da estratégia",
      start: "Início",
      end: "Fim",
      notification: {
        success: {
          message: "Estratégia de dieta criada!",
          description: "A estratégia foi inclusa no sistema.",
        },
        error: {
          dontHaveName: {
            message: "Erro",
            description:
              "É necessário digitar um nome para a estratégia de dieta !",
          },
          dontHaveDiet: {
            message: "Erro",
            description:
              "Todos os períodos precisam ter uma dieta selecionada.",
          },
        },
      },
    },
    title: "Lista de estratégias de dieta",
    buttonNewDietStrategy: "Nova estratégia",
    table: {
      status: "Status",
      name: "Nome",
      diet: "Dieta",
      totalTime: "Tempo total",
    },
    modal: {
      simple: {
        title: "SubSistema de Produção divergente",
        message:
          "O sub sistema de produção selecionado para este período não corresponde ao sub sistema utilizado no piquete / baia em que o lote está alocado. Para obter uma boa performance é recomendado que ambos utilizem o mesmo sistema.",
        confirmButton: "OK, entendi.",
      },
    },
  },
  generalParameters: {
    weatherSeason: {
      title: "Estações climáticas",
      editMessage:
        "Defina as estações climáticas mais adequadas para a localização da fazenda utilizando a caixa ao lado:",
      table: {
        columnDayMonth: "DIAS/MÊS",
        columnNextSeason: "PRÓXIMA ESTAÇÃO",
        columnMonthSeason: "ESTAÇÃO DO MÊS",
      },
    },
    farmDeathReason: {
      buttonNew: "Novo",
      table: {
        title: "Causa Mortis",
        columns: {
          name: "Nome",
          status: "Situação",
        },
      },
    },
    quotas: {
      buttonNew: "Novo",
      table: {
        title: "Cotas",
        columns: {
          name: "Nome",
          type: "Tipo",
          status: "Situação",
        },
      },
    },
    successParameterUpdated: "Parâmetros alterados com sucesso!",
    errorParameterUpdated: "Ops... os parâmetros não foram alterados",
  },
  cost: {
    tabs: {
      fixedCost: "Imobilizados",
      infrastructureCost: "Infraestrutura e mão de obra",
      operatingVariableCost: "Variáveis operacionais",
      zootechnicalVariableCost: "Zootécnicos",
      sanityCost: "Sanidade",
    },
    form: {
      error:
        'É necessário informa os campos "Valor do Custo" e "Depreciação" nos custos marcados em vermelho',
      growth: "Cria",
      recreateFattenPasture: "R&E - Pasto",
      recreateFattenSemiConfinement: "R&E - Semi-Confinamento",
      recreateFattenConfinement: "R&E - Confinamento",
      area: "Área",
      potentialOccupation: "Ocupação Potencial",
      staticCapacity: "Capacidade Estática",
      staticCapacityTooltip:
        "Os valores deste campo são calculados, multiplicando-se a Área pela Ocupação Potencial.",
      fence: "Cercas",
      costValue: "Valor do Custo",
      depreciationValue: "Depreciação",
      total: "Total",
      amountAnimals: "Qtd. de Cabeças",
      totalDailyCostPerAnimal: "Custo diário por Animal",
      dietCost: "Dieta",
      costProtocol: "Custo de Protocolo",
    },
    costType: {
      FixedCost: "Imobilizados",
      InfrastructureCost: "Infraestrutura e mão de obra",
      OperatingVariableCost: "Variáveis operacionais",
      ZootechnicalVariableCost: "Zootécnicos",
      sanityCost: "Sanidade",
    },
    code: {
      1: "Construção sede e instal.",
      2: "Impostos (ITR, CAN)",
      3: "Seguros",
      4: "Formação de pastos",
      5: "Construção de cercas",
      6: "Construção de curral",
      7: "Construção de açudes",
      8: "Aquisição de máquinas e implementos",
      9: "Construção de galpões e instal.",
      10: "Mão de obra operacional",
      11: "Mão de obra técnica prop.",
      12: "Assitência Técnica 3RDS",
      13: "Energia",
      14: "Telefone / Internet",
      15: "ERP",
      16: "Contador",
      17: "Manutenção de Instal. e Galpões",
      18: "Manutenção de Máquinas e Equipamentos",
      19: "Combustível e Lubrificantes",
      20: "Manutenção de pastos",
      21: "Adubação",
      22: "Manutenção de Currais e Cercas",
      23: "Aluguel de Pasto",
      24: "Vacinas",
      25: "Vermífugos",
      26: "Medicamentos",
      27: "Identificação",
      28: "Custo com Tropa & Relacionados",
      29: "Outros custos Adm.",
      30: "Valor do Protocolo",
    },
  },
  saleScenario: {
    error: {
      DietStrategyExpired:
        "Não foi possível processar o cenário de venda, pois o mesmo possui estratégias de dietas vencidas.",
      InternalError: "Houve um erro ao processar o cenário. Contate o suporte.",
    },
  },
  purchaseScenario: {
    error: {
      DietStrategyExpired:
        "Não foi possível processar o Cenário de Compra, pois a Estratégia de Dieta informada está Vencida.",
    },
  },
  movementHistory: {
    title: "Histórico(s) de Movimentações",
    table: {
      columns: {
        originalLotsNames: "Lotes de Origem",
        destinationLotsNames: "Lotes de Destino",
        requestDate: "Data da Movimentação",
        fromDismemberLot: "Desmembramento?",
        fileName: "Nome do arquivo",
        animalHandlingNumber: "Animal",
        lotOriginName: "Lote de Origem",
        lotDestinationName: "Lote de Destino",
        createdAtDate: "Data Registro",
      },
    },
  },
  financial: {
    tabs: {
      costing: "Custeio",
      cashFlow: "Fluxo de Caixa",
      parameters: "Parâmetros",
      transactions: "Movimentações Financeiras",
      animalSell: "Transf/Venda",
      resultCenterAnalysis: "Análise CR/CC",
    },
    costType: {
      Growth: "Cria",
      RecreateFattenPasture: "Extensivo",
      RecreateFattenSemiConfinement: "Semi-Intensivo",
      RecreateFattenConfinement: "Intensivo",
      Agriculture: "Agricultura",
      Others: "Outros",
    },
    transactions: {
      title: "Movimentações",
      buttonCreateNewFinancialTransaction: "Novo lançamento",
      buttonExportTransactions: "Exportar movimentações",
      form: {
        title: "Novo lançamento",
        documentNumber: "Nº Doc. Fiscal",
        supplierId: "Fornecedor",
        billingDate: "Data do faturamento",
        expirationDate: "Data do vencimento",
        mainValueCurrency: "Selecione uma moeda",
        mainValue: "Valor Principal",
        fees: "Valor Juros/Multas",
        discount: "Descontos",
        value: "Valor da transação",
        payment: "Pagamento",
        actions: "Ação",
        actionsOptions: {
          launch: "Lançar",
          launchAndSettle: "Lançar e Baixar",
          settle: "Baixar",
        },
        settleDate: "Data da Baixa",
        inCash: "À vista",
        part: "Parcelado",
        financialNatureId: "Natureza",
        financialClassId: "Classe",
        costActivityType: "Atividade",
        description: "Descrição",
        resultCenter: "Centro de Resultado",
        resultCenterApportionmentDefault: "Padrão",
        resultCenterApportionmentCustom: "Redefinir",
        transactionType: "Tipo",
        clientId: "Cliente",
        financialProjectId: "Projeto Financeiro",
        bonus: "Bonificação",
        errors: {
          installmentsNotGeneratedError:
            'É necessário gerar as parcelas. Para isso clique na opção "Parcelado" presente no campo "Pagamento".',
        },
      },
      modalInstallments: {
        title: "Gerar parcelas",
        value: "Valor da transação",
        expirationDate: "Data do vencimento",
        installmentTotal: "Nº de Parcelas",
        buttonCalc: "Calcular",
      },
      table: {
        columns: {
          nature: "Natureza",
          class: "Classe",
          values: "Valores",
          origin: "Origem",
          billingDate: "Data faturamento",
          expirationDate: "Data vencimento",
          installment: "Parcela",
          documentNumber: "Nº Doc. Fiscal",
          value: "Valor",
          isPaidPartially: "Parc. Pago",
          isSettled: "Status",
          settleDate: "Data da Baixa",
          financialClassDepreciation: "Alocação",
          valueDepreciation: "Custo Alocação",
          supplierName: "Fornecedor/Cliente",
          costActivityType: "Atividade",
          r: "CR",
          financialProjectName: "Projetos",
          transactionType: "Tipo",
          entityName: "Fornecedor/Cliente",
        },
      },
      modalResultCenterApportionment: {
        title: "Rateio por Centro de Resultado",
      },
    },
    cashFlow: {
      title: "Fluxo de Caixa",
      buttonDisbursementReport: "Relatório de Desembolso",
      buttonCashFlowReport: "Relatório de Fluxo de Caixa",
      buttonCreateBudget: "Novo orçamento",
      filters: {
        status: "Status",
        visualization: "Visualização",
        exibition: "Exibição",
        harvest: "Safra",
        costType: "Atividade",
        financialProjectId: "Projeto Financeiro",
      },
      table: {
        columns: {
          nature: "Natureza",
          class: "Classe",
          values: "Valores",
        },
      },
    },
    costing: {
      title: "Tabela de Custos",
      filters: {
        visualization: "Visualização",
        harvest: "Safra",
        costType: "Atividade",
        financialProjectId: "Projeto Financeiro",
      },
      table: {
        columns: {
          nature: "Natureza",
          class: "Classe",
          values: "Valores",
        },
      },
    },
    parameters: {
      tabs: {
        parameters: "Parâmetros",
        costing: "Custeio",
        resultCenter: "Centro de Resultado",
        costCenter: "Centro de Custo",
        financialProject: "Projetos Financeiros",
      },
      generalFinancialParameters: {
        title: "Parâmetros",
        parameter4001: "Utilizar conversão de moedas?",
        parameter4002: "Custos Nutricionais",
        parameter4002Help:
          "Defina se os custos nutricionais serão apropriados aos animais a partir da Estratégia de Dieta ou do Fornecimento",
        farmConvertions: {
          title: "Moedas",
          buttonNew: "Nova Moeda",
          table: {},
          form: {
            titleNew: "Nova Moeda",
            currency: "Moeda",
            standard: "Colocar como padrâo?",
          },
        },
      },
      costing: {
        title: "Tabela de Parâmetros Custeio",
        table: {
          columns: {
            nature: "Natureza",
            stock: "Controle de Estoque",
            class: "Classe",
            depreciation: "Depreciação (Meses)",
            shouldCost: "Custeio",
            shouldDisbursement: "Desembolso",
            product: "Produto",
            costCenter: "Centro de Custo",
            type: "Tipo",
          },
        },
      },
      resultCenter: {
        title: "Tabela de Centros de Resultados",
        buttonNew: "Novo",
        form: {
          title: "Centros de Resultado",
        },
        financialNatureApportionmentForm: {
          title: "Critérios de Rateio de Despesas/Custos Indiretos",
        },
      },
      costCenter: {
        title: "Tabela de Centros de Custos",
        buttonNew: "Novo",
        form: {
          title: "Centros de Custos",
        },
        apportionmentForm: {
          title: "Centros de Custo x Centros de Resultado",
        },
        modal: {
          title: "Centros de Custos",
          table: {
            columns: {
              financialClass: "Classe",
            },
          },
        },
      },
    },
    animalSell: {
      title: "Transferência/Venda",
      transferPanelTitle: "Trânsferências",
      buttonNew: "Nova Transf./Venda",
      buttonExport: "Resultado de Vendas",
      form: {
        title: "Transferência/Venda",
        animalSelectionDescription:
          "Selecione os animais na tabela para proceder com a venda.",
        sellDate: "Data de saída",
        client: "Cliente",
        financialNatureId: "Natureza",
        financialClassId: "Classe",
        priceNegotiated: "Valor negociado",
        weightPerAnimal: "Peso médio/animal",
        carcassHarnessing: "Ap. carcaça",
        documentNumber: "Nº Doc. Fiscal",
        supplier: "Fornecedor",
        billingDate: "Data do faturamento",
        expirationDate: "Data do vencimento",
        payment: "Pagamento",
        inCash: "À vista",
        part: "Parcelado",
        resultCenter: "Centro de Resultado",
        resultCenterApportionmentDefault: "Padrão",
        resultCenterApportionmentCustom: "Redefinir",
        totalValue: "Fat. Total",
        AVERAGE_COST: "Custo Méd. Atual",
        AVERAGE_MARKET_VALUE: "Valor Méd. Mercado",
        MARGIN_VALUE: "Margem s/ custo",
        MARGIN_VALUE_INFO_TOOLTIP:
          'O valor do campo "Valor Negociado" será o Custo Méd. Atual mais a margem informada.',
        INPUT_PRICE: "Valor Informado",
        AVERAGE_LAST_WEIGHT: "Peso Med Ult. Pesagem",
        AVERAGE_PROGRESSIVE_WEIGHT: "Peso Med Estimado Atual",
        INPUT_WEIGHT: "Peso informado",
        animalTableColumns: {
          handlingNumber: "Identificação",
          lotName: "Lote",
          picketName: "Piquete/Baia",
          breedName: "Raça",
          gender: "Sexo",
          age: "Idade",
          lastDailyWeight: "Último peso",
          lastDailyWeightDate: "Últ Pesagem",
          weightSell: "Peso venda",
          carcassHarnessing: "% Aprov. carcaça",
          pricePerKilo: "Preço/Kg",
          pricePerArroba: "Preço/@",
          priceNegotiated: "Preço venda",
        },
      },
      table: {
        columns: {
          origin: "Origem",
          isTransfer: "Transf.",
          documentNumber: "Nº Doc. Fiscal",
          client: "Cliente",
          sellDate: "Dt. Venda",
          amountAnimais: "Qtde Animais",
          gender: "Sexo",
          totalValue: "Valor Total",
          averageValue: "Valor/Cabeça",
          carcassHarnessing: "% Aprov. Carcaça",
          paymentType: "Pagamento",
          financialProjectName: "Projetos",
        },
      },
      transferPanelTable: {
        columns: {
          status: "Status",
          originGroupName: "Grupo Origem",
          originFarmName: "Fazenda Origem",
          amountAnimals: "Qtd. Animais",
        },
      },
      messages: {
        selectClientText1:
          "Esta transferência de animais envolverá compensação financeira (pagamento) entre as partes?",
        selectClientText2:
          " Detectamos que esta pode ser uma operação de transferência de animais entre unidades de um mesmo grupo econômico. BovExo não controla os aspectos fiscais/legais da operação, mas, para efeitos de análise de resultado de operações, o valor mínimo que será registrado, para efeitos gerais, é o do custo acumulado dos animais tansacionados.",
        infoTooltipText1:
          "Este valor, será calculado, de acordo com o tipo de Valor Negociado, sendo:",
        infoTooltipText2:
          "Valor/@ Negociado: Corresponde ao peso médio dos animais, transformado em @, ou seja, dividido por 15. A quantidade de @ é multiplicada pelo valor da @ negociado com o comprador, multiplicado pelo aproveitamento de carcaça. Desta forma, se tem o valor médio por animal e multiplicado pela quantidade de animais, chegando assim, no valor de faturamento total.",
        infoTooltipText3:
          "Valor/Kg Negociado: Corresponde ao peso médio dos animais, multiplicando pela quantidade de animais e posteriormente multiplicado pelo valor do Kg informado, chegando assim, no valor de faturamento total.",
        successSubmitCreate:
          "A sua Venda está sendo processada, e logo será concluída.",
        errorSubmitCreate:
          "Não foi possível realizar a venda. Contate o suporte.",
        successSubmitUpdate:
          "A atualização da sua Venda está sendo processada, e logo será concluída.",
        errorSubmitUpdate:
          "Não foi possível atualizar a venda. Contate o suporte.",
        error5032:
          'Atenção! Esta venda não pode ser alterada pois sua movimentação financeira foi "Baixada".',
        modalErrorAnimalsWithPreviousEvents:
          "Animais com eventos posteriores à data de venda informada!",
        modalAlertAnimalsInSaleScenarioText1: "Atenção!",
        modalAlertAnimalsInSaleScenarioText2:
          "Existem animais selecionados, que estão associados a Cenários de Vendas Ativos. Ao confirmar a venda, estes Cenário de Venda, serão reprocessados, gerando um novo resultado, somente para os animais ainda ativos.",
        modalConfirmPriceNegotiatedText1:
          'O valor informado no campo "Valor Negociado" é menor que, gerencialmente, deveria ser atribuído.',
        modalConfirmPriceNegotiatedText2: "Confirma o valor?",
        successOnDelete: "Transferência/Venda deletada com sucesso.",
        errorMessageOnDeleteTransferAlreadyConfirmed:
          "Atenção! Esta venda/transferência já foi confirmada na fazenda Destino.",
        errorMessageOnDelete: "Venda não foi deletada. Contate o suporte.",
        modalConfirmFinancialTransactions:
          "Atualizar Movimentação Financeira desta Venda?",
        modalConfirmExpirationDate:
          "Atenção! Data de Vencimento menor que a de Faturamento.",
      },
    },
    resultCenterAnalysis: {
      filters: {
        resultCenter: "Centro de Resultado",
        costCenter: "Centro de Custo",
        period: "Período",
        visualization: {
          label: "Visualização",
          costing: "Custeio",
          cashFlow: "Fluxo de Caixa",
        },
        buttonConfirm: "Processar",
      },
      income: {
        title: "Receitas",
        costCenterName: "Centro de Custo",
        total: "Valor Total",
        totalPercentage: "Total (%)",
      },
      expense: {
        title: "Despesas",
        costCenterName: "Centro de Custo",
        total: "Valor Total",
        totalPercentage: "Total (%)",
      },
      total: "Resultado",
    },
  },
  costType: {
    AllTypes: "Todos",
    Growth: "Cria",
    RecreateFattenPasture: "R&E - Pasto",
    RecreateFattenSemiConfinement: "R&E - Semi-Confinamento",
    RecreateFattenConfinement: "R&E - Confinamento",
    Agriculture: "Agricultura",
    Others: "Outros",
  },
  buttons: {
    assign: "Atribuir",
    back: "Voltar",
    save: "Salvar",
    cancel: "Cancelar",
    confirm: "Confirmar",
    login: "Logar",
    buttonNewProfitCenter: "Nova atividade",
    buttonNewFarm: "Nova fazenda",
    buttonNewSupplier: "Novo fornecedor",
    yes: "Sim",
    no: "Não",
    putToDeath: "Colocar em óbito",
    allocateLot: "Alocar lote",
    download: "Download",
    processWeights: "Processar pesagens",
    processAnimals: "Processar animais",
    processSupplements: "Processar suplementos",
    edit: "Editar",
    editing: "Editando",
    addInfo: "Informar dados",
    delete: "Deletar",
    useAsFavorite: "Utilizar como favorito",
    generate: "Gerar",
    putToDiscard: "Descartar",
    process: "Processar",
    accept: "Aceitar",
    reject: "Rejeitar",
    clickInEdit: 'Clique em <span className="red">"editar"</span>',
    updateTable: "Atualizar tabela",
    viewAll: "Visualizar todos",
  },
  topbar: {
    title: "Administração  ",
    group: {
      title: "Grupos",
      subtitle: "Selecione um grupo",
      submenus: {
        farm: "Fazenda(s)",
        add_farm: "ADICIONAR FAZENDA",
      },
    },
    question: {
      title: "Dúvidas",
      submenus: {},
    },
    notification: {
      title: "Notificações",
      submenus: {},
      footer: "Ver todos",
    },
    add: {
      title: "Adicionar",
      submenus: {
        profit_center: "Atividade",
        picket: "Piquete / Baia",
        picketSupplementSupply: "Novo/Editar Fornecimento",
        user: "Usuário",
        balance: "Balança",
        sell_and_buy: "Cenário de compra e venda",
        animal: "Animal",
        lotProduction: "Lote de produção",
        buy: "Compra",
        birth: "Nascimento",
        importAnimalsWeights: "Importar pesagens",
        importAnimals: "Importar animais",
        importSupplements: "Importar suplementos",
        importFinancialTransactions: "Importar mov. financeiras",
        downloadBovManager: "Dowload BovManager",
      },
    },
    language: {
      title: "Tradução",
      submenus: {
        pt_br: "Português - BR",
        en: "Inglês - US",
        es: "Espanhol - ES",
      },
    },
    user: {
      title: "Usuário",
      submenus: {
        edit_profile: "Editar perfil",
        change_user: "Trocar usuário",
        change_password: "Trocar senha",
        add_new_user: "Adicionar usuário",
        signout_application: "Sair do Aplicativo",
      },
    },
    search: {
      title: "Pesquisa",
      submenus: {
        user: "Usuário",
        profit_center: "Atividade",
        picket: "Piquete / Baia",
        balance: "Balança",
        animal: "Animal",
        buy: "Compra",
        birth: "Nascimento",
      },
    },
  },
  table: {
    menu: {
      edit: "Editar",
      details: "Detalhes",
      delete: "Excluir",
      inactivate: "Inativar",
      activate: "Ativar",
      addAnimalsToLotProduction: "Adicionar animais",
      dismemberLotProduction: "Desmembrar Lote",
      allocateLotToPicket: "Alocar lote ao piquete / baia",
      putPicketInMaintenance: "Fazer manutenção",
      putPicketInBreak: "Descansar",
      putPicketInRenovation: "Reformar",
      setBaseline: "Definir como Padrão",
      movePickets: "Mov. Piquetes",
    },
  },
  error: {
    formError: "Por favor, preencha os campos obrigatórios(*) corretamente.",
    noGroupSelected: "Selecione um grupo no menu superior",
    sisbovInvalidTitle: "Sisbov inválido",
    sisbovInvalidMessage: "O número SISBOV deve conter no mínimo 15 caracteres",
  },
  notifications: {
    types: {
      newObject: "Novo objeto",
      purchase: "Compra",
      sale: "Venda",
      alert: "Alerta",
      warning: "Aviso",
      other: "Outro",
    },
    drawer: {
      title: "Suas notificações e alertas",
      buttonVilualizeAll: "MARCAR TODOS COMO LIDA",
      buttonLoadOldNotifications: "Carregar antigas",
      notificationsNotRead: "notificações não lidas",
      tabNotificationTitle: "Notificações",
      tabAlertTitle: "Alertas",
    },
  },
  sessionExpired: {
    title: "Sua sessão expirou!",
    message:
      "Para manter sua conta segura, periodicamente checamos sua presença,faça login novamente",
    titleAlmostExpired: "Sua sessão irá expirar em breve!",
    messageAlmostExpired:
      "Para manter sua conta segura, periodicamente checamos sua presença, não esqueça de salvar o que esta fazendo antes da sessão expirar",
    buttonLogout: "Deslogar agora",
    buttonConfirm: "Ok, entendi",
    toExpire: "para expirar...",
  },
  sidebar: {
    home: "Home",
    farm: "Fazendas",
    profit_center: "Atividades",
    picket: "Retiros/Piquetes",
    lot: "Lotes",
    animal: "Animais",
    decision: "Decisões",
    parameters: "Parâmetros",
    imports: "Importações",
    administration: "Administração",
    suppliers: "Fornecedores",
    operations: "Operações",
    breed: "Raças",
    handling: "Manejos",
    diet: "Dietas",
    pasture: "Pastos",
    supplement: "Suplementos/Concent.",
    adminAndUser: "Colaboradores da fazenda",
    userInvite: "Convites de acesso",
    client: "Clientes",
    payment: "Pagamentos",
    supplierManagement: "Gestão de fornecedores",
    financial: "Movimentações Financeiras",
    report: "Relatórios",
  },
  languages: {
    pt_br: "PT",
    en: "IN",
    es: "ES",
  },
  status: {
    active: "Ativo",
    inactive: "Inativo",
    dismembered: "Desmembrado",
    pendingData: "Dados pendentes",
    informedData: "Dados informados",
    pending: "Pendente",
    processing: "Processando",
    completed: "Completado",
    refused: "Rejeitado",
    error: "Erro",
    transfer: "Transferido",
    dead: "Morto",
    sold: "Vendido",
    processed: "Processado",
  },
  productionSubSystem: {
    semiIntensive: "Semi-Intensivo",
    intensive: "Intensivo",
    extensive: "Extensivo",
    SI: "Semi-Intensivo",
    I: "Intensivo",
    E: "Extensivo",
  },
  productionSystem: {
    fatten: "Engorda",
    create: "Cria",
    recreate: "Recria",
  },
  kpi: {
    capacity: "Lotação",
    production: "Produção",
    cost: "Custo",
    margin: "Margem",
    gdp: "GPD",
    enjoy: "Desfrute",
    carbonFootprint: "Pegada Carbônica",
    disbursement: "Desembolso Cab/Mês",
    weanCalf: "Kg Bez. Desm / Kg Vaca",
    iep: "IEP",
    reproductiveEfficiency: "Eficiência Reprodutiva",
    weanCalfCost: "Custo Bezerro Desmamado",
    discardedCows: "Vacas Descartadas",
    assetValue: "Valor do Ativo",
    incrementalMargin: "Margem Incremental",
    tooltips: {
      tooltipCapacity:
        "A Lotação é a relação (em %) da quantidade atual de cabeças alocadas e a capacidade total, conforme definido no cadastro das baias de confinamento.",
      tooltipEnjoy:
        "A taxa de desfrute mede a capacidade do rebanho em gerar excedente (@) em um determinado espaço de tempo.",
      tooltipProduction: "Quantidade de @ produzida por hectare/ano.",
      tooltipGDP: "Ganho de Peso Diário, desde a entrada no animal da Fazenda.",
      tooltipMargin: "Margem de lucro esperada por @ produzida.",
      tooltipCost: "Custo de Produção da @.",
      tooltipCarbonFootprint:
        "Meta de emissão máxima em Kg de CO₂E por Kg de Ganho de Peso.",
      tooltipDisbursement:
        "Desembolso Cabeça/Mês dos animais atualmente em cada subsistema de produção.",
      tooltipAssetValue: "Valor ativo.",
      tooltipIncrementalMargin: "Margem incremental.",
      tooltipWeanCalf:
        "Indicador da proporção do peso dos animais na desmama, em relação às fêmeas de reprodução aptas a reprodução. São consideradas, como aptas, as fêmeas de finalidade “Reprodução” e que atendam os parâmetros reprodutivos.",
      tooltipIEP:
        "Intervalo entre partos das fêmeas de reprodução, que tenham parido ao menos 2 vezes (Secundíparas)",
      tooltipReproductiveEfficiency:
        "Eficiência Reprodutiva final das fêmeas expostas, considerando a Taxa de Prenhez (TP), Taxa de nascimento (TN) e Taxa de Desmama (TD).",
      tooltipWeanCalfCost:
        "Custo do bezerro desmamado, incluindo todos os custos incorridos pela vaca e pelo bezerro.",
      tooltipDiscardedCows: "% de vacas descartadas no período.",
    },
  },
  zipCodeValid: "CEP encontrado",
  zipCodeInvalid: "CEP não encontrado",
  defaultSelectPlaceholder: "Selecione uma opção",
  defaultMultiSelectPlaceholder: "Selecione uma ou mais opções",
  defaultDatePickerPlaceholder: "Selecione uma data",
  defaultPlaceholder: "Informe um valor",
  filters: {
    applyFilter: "APLICAR FILTRO",
    cleanFilter: "Limpar filtro",
    filterBy: "Filtrar por",
    filterByOptions: {
      age: "Idade",
      gender: "Sexo",
      breed: "Raça",
      supplier: "Fornecedores",
      farmOwner: "Proprietários",
    },
    compareBy: "Comparar por",
    value: "Valor(es)",
    male: "Macho",
    female: "Fêmea",
    min: "Mínimo",
    max: "Máximo",
    equal: "Igual",
    greaterThan: "Maior igual que",
    lessThan: "Menor igual que",
    between: "Entre",
  },
  groupAndFarmModal: {
    hello: "Olá",
    groupSelectedLabel: "Selecione um grupo de fazendas",
    groupSelectedPlaceHolder: "Selecione um grupo",
    farmSelectedLabel: "Selecione uma fazenda",
    farmSelectedPlaceHolder: "Selecione uma fazenda",
    buttonAccessFarm: "Acessar fazenda",
  },
  importAnimalWeights: {
    title: "Importar histórico de pesagens",
    message:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com as pesagens deste lote, <strong>faça a importação</strong> em seguida e clique em processar pesagens</p>',
    draggerMessage:
      "Clique ou arraste o arquivo para esta área para fazer o upload",
  },
  importAnimals: {
    title: "Importar animais ao lote de produção",
    message:
      '<p class="message">Selecione um lote de produção, faça <strong>download do template abaixo, complete a planilha</strong> com os animais que compõe o lote, <strong>faça a importação</strong> em seguida e clique em processar animais</p>',
    messageForDifferentsLots:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com os animais e seus respectivos lotes, <strong>faça a importação</strong> em seguida e clique em processar animais</p>',
    messageForIdentifyAnimals:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com os animais e seus respectivos lotes e códigos de lote de recebimento, <strong>faça a importação</strong> em seguida e clique em processar animais</p>',
    draggerMessage:
      "Clique ou arraste o arquivo para esta área para fazer o upload",
    helpMessage:
      'Importar animais por lote e fornecedor: Informe na planilha o Lote e Fornecedor para cada animal. Desta forma, é possível importar animais de diferentes fornecedores e para mais de um Lote de Produção. O Fornecedor e o Lote de Produção devem ser cadastrados previamente, sendo que deverá ser utilizado na planilha o campo "Identificação do Lote" para o Lote de Produção, enquanto para o Fornecedor, informe o "CPF/CNPJ" de seu cadastro.\nImportar animais para um único lote e fornecedor: Selecione um Lote de Produção e um Fornecedor já cadastrado. Se o animal a ser importado é de origem da própria fazenda, selecione a opção "Animal(is) fornecido(s) pela própria fazenda".',
  },
  error404: {
    title: "ERRO 404",
    subTitle: "Página não encontrada",
    button: "Ir à Home",
  },
  months: {
    january: "Janeiro",
    jan: "Jan",
    february: "Fevereiro",
    feb: "Feb",
    march: "Março",
    mar: "Mar",
    april: "Abril",
    apr: "Abr",
    may: "Maio",
    mmay: "Mai",
    june: "Junho",
    jun: "Jun",
    july: "Julho",
    jul: "Jul",
    august: "Agosto",
    aug: "Ago",
    september: "Setembro",
    sep: "Sep",
    october: "Outubro",
    oct: "Out",
    november: "Novembro",
    nov: "Nov",
    december: "Dezembro",
    dec: "Dez",
  },
  notInformed: "Não informado",

  Profile: {
    drawerTitle: "Dados Pessoais",
    form: {
      fullName: "Nome Completo",
    },
  },
  scenarePage: {
    title: "Cenários Boitel",
    table: {
      column: {
        clientID: "Client ID",
        name: "Nome Cenário",
        createdAt: "Data Criação",
        updatedAt: "Data Ult. Atualiz.",
        status: "Status",
        labelStatus: {
          hired: "Contratado",
          saved: "Salvo",
        },
      },
      detailsRow: {
        rangeWeight: "Faixa Peso",
        amountAnimals: "Qtde Animais",
        entryDate: "Data Entrada",
        exitDay: "Data Saída",
        daysOfStay: "Qtd Diárias",
        dailyPrice: "Preço Dia",
        dietStrategy: "Dieta",
        carcassHarnessing: "Aprov. Carcaça - %",
        totalIncome: "Receita Total",
        incomeForCab: "Receita/Cab",
        marginForCab: "Lucro/Cab",
      },
    },
  },
  boitelSaleScenario: {
    title: "Cenários venda",
    result: "Resultado(s)",
    btn: {
      newScenare: "Novo Cenário",
    },
    table: {
      column: {
        scenare: "Cenário",
        client: "Cliente",
        favorite: "Favorito",
        status: "Status",
        actions: "Ações",
        labelStatus: {
          analyzing: "Avaliando",
          processed: "Processado",
          canceled: "Cancelado",
          partialCanceled: "Cancelado Parc.",
          sold: "Vendido",
          partialSold: "Vendido Parc.",
        },
      },
      detailsRow: {
        identification: "Identificação",
        saleAnimals: "Animais à vender",
        receive: "A receber",
        margin: "Margem",
        processedAt: "Processado às",
      },
    },
    new: {
      title: "Criação: Boitel Venda",
      tabs: {
        lots: {
          title: "Seleção de lotes",
        },
        dietStrategy: {
          title: "Seleção de estratégia de dieta",
        },
        parameters: {
          title: "Definição dos parâmetros",
          form: {
            name: "Nome do cenário*",
            animalsInTruck: "Animais no caminhão*",
            minimumAge: "Idade minima para venda*",
            maximumAge: "Idade máxima para venda*",
            minimumWeight: "Peso minimo para venda*",
            maximumWeight: "Peso máximo para venda*",
            useDefaultMaximumWeight: "Peso máximo para venda*",
            allowSeparateSell: "Lotes podem sofrer descasca?",
            goalType: "Objetivo do cenário*",
            commitments: "O cenário possui algum compromisso?",
            newCommitment: "Adicionar novo compromisso",
            marketAnalysisId: "Análise de mercado*",
            useCustomCarcassHarnessing: "Informar novo % de Aprov. de Carcaça?",
            useCustomCarcassHarnessingHelp:
              "Por padrão, o sistema utiliza o % de Aproveitamento de Carcaça informado no processo de contratação dos animais.",
            carcassHarnessing: "Aprov. de Carcaça",
          },
        },
      },
    },
    edit: {
      title: "Edição: Boitel Venda",
    },
    details: {
      messageSelectDates:
        "Selecione uma(s) data(s) sugeridas no calendário para visualizar as informações dos animais.",
      calendarSuggested: "Calendário sugerido",
      weekDateNull: "Animais com violação Boitel",
      animalsToSell: "Animais a vender",
      planningIncome: "Receita planejada",
      dietStrategiesSuggested: "Estrat. de dieta sugerida",
      commitments: "Compromissos",
      violations: "Violações",
      parameters: "Parâmetros do cenário",
      marketValues: "Referência de valores",
      violationAge: "Idade",
      violationWeight: "Peso",
      violationBoitel: "Boitel",
      violationMinAge: "Idade mínima",
      violationMaxAge: "Idade máxima",
      violationMinWeight: "Peso mínimo",
      violationMaxWeight: "Peso máximo",
      table: {
        columns: {
          animalHandlingNumber: "Identificação",
          sellDate: "Data sugerida",
          weight: "Peso estim.",
          lastDailyWeight: "Último peso",
          averageWeightGain: "GMD",
          aggregateArroba: "@Liq. agregadas",
          saleIncome: "Receita",
          aggregateIncome: "Receita Agregada",
          cost: "Custo",
          aggregateMargin: "Lucro agregado",
          margin: "Lucro",
        },
      },
    },
  },
  importSupplements: {
    title: "Importar suplementos",
    message:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com os suplementos, <strong>faça a importação</strong> em seguida e clique em processar suplementos</p>',
    draggerMessage:
      "Clique ou arraste o arquivo para esta área para fazer o upload",
  },
  eventLog: {
    fromValueLabel: "Modificado de",
    toValueLabel: "para",
    dateLabel: "Data",
    attributeLabel: "Propriedade",
    userLabel: "Usuário",
    nullLabel: "vazio",
    Supplement: {
      createdAt: "Criação do registro: ",
      name: "Nome",
      type: "Tipo",
      classification: "Classificação",
      industrialName: "Nome Industrial",
      status: "Situação",
      cost: "Custo",
      cp: "PB",
      tdn: "NDT",
      npn: "NNP",
      dryMatterPercentage: "MS",
      nitrogen: "Nitrogênio",
      calcium: "Cálcio",
      phosphorus: "Fósforo",
      zinc: "Zinco",
      sodium: "Sódio",
      minimumVoluntaryConsumption: "Consumo Vonluntário (Mínimo)",
      maximumVoluntaryConsumption: "Consumo Vonluntário (Máximo)",
      voluntaryConsumptionType: "Unidade",
      minimumPure: "Oferta Pura (Mínimo)",
      maximumPure: "Oferta Pura (Máximo)",
      minimumPureTrough: "Cocho Recomendado (Mínimo)",
      maximumPureTrough: "Cocho Recomendado (Máximo)",
      pureConsumptionType: "Unidade",
      minimumMix: "Oferta Mistura (Mínimo)",
      maximumMix: "Oferta Mistura (Máximo)",
      minimumMixTrough: "Cocho Recomendado (Mínimo)",
      maximumMixTrough: "Cocho Recomendado (Máximo)",
      mixConsumptionType: "Unidade",
      currentVersion: "Versão",
      proportion: "Proporção",
      additiveEffect: "Efeito Aditivo",
      additiveEffectUnit: "Unidade Efeito Aditivo",
      fda: "FDA",
      lignin: "Lignina",
      ee: "Extrato Etéreo",
    },
  },
  reproductionParameters: {
    title: "Parâmetros de Reprodução",
    parameters: {
      IDADE_INICIO_FASE_REPRODUTIVA: "Idade para início da fase reprodutiva*",
      PERIODO_DESCANSO_POS_PARTO: "Período de descanso pós parto*",
      TEMPO_APOS_COBERTURA_PARA_DG:
        "Tempo pós cobertura para Diagnóstico de Gestação*",
      TOURO_COBERTURAS_DESCONHECIDAS: "Touro de Coberturas Desconhecidas*",
      IDADE_DESMAMA: "Idade para Desmama*",
    },
    reproductionPeriod: {
      title: "Estações de Monta",
      titleHelper:
        "A Estação de Monta é o período reprodutivo da propriedade, sendo iniciado no D0 do protocolo do primeiro Lote de IATF e finalizando no Diagnostico de Gestação Final.",
    },
    score: {
      title: "Score de Probabilidade de Sucesso Reprodutivo",
      titleHelper:
        "Defina, com base na escala de 1 a 9 de score, a probabilidade em % de sucesso reprodutivo. Este score, será aplicado na tabela para geração da Curva de Idade X Peso X Score.",
    },
    breedScore: {
      title: "Curva de Idade X Peso X Score",
      titleHelper:
        "Defina para as Fêmeas de Reprodução, considerando a Idade, Peso Atual e Score de Probabilidade de Sucesso Reprodução, as variáveis necessárias para cálculo do custo para, até a próxima Estação de Reprodutiva, atingir cada score.",
      form: {
        title: "Nova entrada (Raça X Idade X Peso X Score)",
        breedId: "Raça*",
        age: "Idade*",
        startWeight: "Peso inicial*",
        endWeight: "Peso final*",
        weight: "Peso",
        score: "Score*",
        errors: {
          errorWithFieldWeights:
            'Existem alguns pesos sem "Score". Preencha-los para salvar o formulário.',
        },
      },
    },
  },
  semen: {
    title: "Listagem de Sêmens",
    buttonNewSemen: "Novo Sêmen",
    table: {
      columns: {
        name: "Nome",
        registration: "Registro",
        breedName: "Raça",
      },
    },
    form: {
      titleCreateNewSemen: "Novo Sêmen",
      titleEditSemen: "Editar Sêmen",
      name: "Nome",
      registration: "Registro",
      breedId: "Raça",
    },
  },
  movimentAnimals: {
    title: "Movimentação entre lotes",
    description:
      'Selecione os animais na tabela e clique no botão "Movimentar" para realocar os animais',
    buttonMovementAnimals: "Movimentar",
    form: {
      title: "Nova movimentação - Lote de Destino",
    },
  },
  closedSalesReport: {
    form: {
      title: "Resultado de Vendas",
      salesPeriod: "Período das Vendas",
      clientId: "Cliente",
      supplierId: "Fornecedor",
      saleScenarioId: "Cenário de Venda(s)",
      animalGender: "Sexo",
    },
  },
  exportFinancialTransactions: {
    modal: {
      title: "Exportar movimentações financeiras",
      billingPeriod: "Faturamento",
      expirationPeriod: "Vencimento",
      suppliers: "Fornecedor(es)",
      costActivities: "Atividade(s)",
      clients: "Cliente(s)",
      transactionType: "Tipo",
      financialProjectId: "Projeto Financeiro",
      status: "Status",
    },
  },
  unit: {
    title: "Listagem de Unidades",
    buttonNew: "Nova Unidade",
    table: {
      columns: {
        name: "Abreviatura",
        description: "Descrição",
        basicUnit: "Unidade Básica",
        multiplier: "Multiplicador",
        status: "Status",
        createdAt: "Data Criação",
        createdBy: "Responsável Criação",
      },
    },
    form: {
      titleCreateNew: "Nova Unidade",
      titleEdit: "Editar Unidade",
      groupFarmVisibility: "Quem irá utilizar a Unidade?",
      name: "Abreviatura",
      description: "Descrição",
      basicUnit: "Unidade Básica",
      multiplier: "Multiplicador",
      status: "Status",
    },
  },
  veterinary: {
    title: "Listagem de Insumos Veterinários",
    buttonNew: "Novo insumo veterinário",
    table: {
      columns: {
        name: "Nome Comercial",
        type: "Tipo",
        supplierId: "Fornecedor",
        supplierName: "Fornecedor",
        status: "Status",
        createdAt: "Data Criação",
        createdBy: "Responsável Criação",
      },
    },
    form: {
      titleCreateNew: "Novo Insumo Veterinário",
      titleEdit: "Editar Insumo Veterinário",
      groupFarmVisibility: "Quem irá utilizar o Insumo Veterinário?",
      name: "Nome Comercial",
      type: "Tipo",
      supplierId: "Fornecedor",
      applicationGender: "Sexo",
      applicationAge: "Idade",
      applicationType: "Forma de Aplicação",
      applicationValue: "Qtd. de Aplicação",
      applicationWeightValueParam: "Peso",
      unitId: "Unidade de uso",
      applicationCost: "Custo Aplicação",
      status: "Status",
    },
  },
  handlingReproduction: {
    title: "Manejos",
    tabs: {
      reproductiveProtocol: "Protocolos",
      coverage: "Cobertura",
      pregnancyDiagnosis: "Diagnóstico de Gestação",
      animalBirth: "Partos e Perdas",
    },
  },
  handlingReproductionCoverage: {
    title: "Listagem de Coberturas",
    buttonHandlingReproductionCoverage: "Nova Cobertura",
    type: {
      iatf: "IA/IATF",
      repasse: "MC/REPASSE",
    },
    table: {
      columns: {
        animalHandlingNumber: "Fêmea",
        coverageDate: "Data",
        type: "Tipo",
        maleHandlingNumber: "Reprodutor",
        semenNameAndRegistration: "Sêmen",
        semenOrMaleIdentification: "Reprodutor/Sêmen",
        lotName: "Lote",
        dose: "Dose",
        reproductionPeriodDescription: "Estação de Monta",
        inseminatorName: "Inseminador",
        pregnancyDiagnosisResult: "Resultado",
      },
    },
    form: {
      type: "Tipo",
      animalId: "Identificação",
      category: "Categoria",
      semenId: "Sêmen",
      maleId: "Reprodutor",
      dose: "Dose",
      inseminatorId: "Inseminador",
      coverageDate: "Data da Cobertura",
      reproductionPeriodId: "Estação de Monta",
    },
    errorsAndMessages: {
      successMessage: "Cobertura salva/atualizada com sucesso!",
      femaleWithoutReproductionAge:
        "Fêmea não possui idade para início da fase reprodutiva, deseja continuar?",
      femaleDontHaveAgeToCoverage:
        "Atenção! Fêmea não tem idade suficiente para esta cobertura.",
      reproductionPeriodWithoutCurrentDate:
        "Atenção! Necessário ter uma Estação de Monta cadastrada no período da cobertura.",
      errorFemaleWithFutureCoverages: `Atenção! Fêmea possui coberturas em datas posteriores.`,
      errorFemaleWithCoverageInSameDateAndType:
        "Atenção! Cobertura já registrada anteriormente.",
      errorInternalError:
        "Erro ao fazer o cadastro/atualizar cobertura. Contate o suporte.",
    },
  },
  inseminator: {
    title: "Listagem de Inseminadores",
    buttonNewInseminator: "Novo Inseminador",
    table: {
      columns: {
        name: "Nome",
        code: "Código",
        status: "Status",
      },
    },
    form: {
      titleCreateNewInseminator: "Novo Inseminador",
      titleEditInseminator: "Editar Inseminador",
      name: "Nome",
      code: "Código",
    },
  },
  multipleBull: {
    title: "Touros Múltiplos",
    buttonNewMultipleBull: "Novo Touro Múltiplo",
    table: {
      columns: {
        name: "Nome",
        code: "Código",
        males: "Reprodutores",
        formationDate: "Data de Formação",
        status: "Status",
      },
    },
    form: {
      titleCreateNewMultipleBull: "Novo Touro Múltiplo",
      titleEditMultipleBull: "Editar Touro Múltiplo",
      name: "Nome",
      code: "Código",
      males: "Reprodutores",
      formationDate: "Data de Formação",
      status: "Status",
    },
  },

  ovarianResult: {
    SemAvaliacao: "Sem Avaliação",
    CorpoLuteo: "Corpo Lúteo",
    Foliculo: "Folículo",
    Cisto: "Cisto",
    Anestro: "Anestro",
    Normal: "Normal",
    Reabsorvendo: "Reabsorvendo",
    Gemelar: "Gemelar",
  },
  animalPregnancyDiagnosis: {
    title: "Diagnóstico de Gestação",
    buttonNew: "Novo Diagnóstico de Gestação",
    table: {
      columns: {
        animalHandlingNumber: "Fêmea",
        lotOriginalName: "Lote",
        diagnosisDate: "Data do DG",
        result: "Resultado",
        ovarianEvaluationResult: "Avaliação Ovariana",
      },
    },
    form: {
      titleCreateNew: "Novo Diagnóstico de Gestação",
      titleEdit: "Editar Diagnóstico de Gestação",
      diagnosisDate: "Data do Diagnóstico",
      animalId: "Identificação",
      animalReproductionCategory: "Categoria",
      femaleSituation: "Situação",
      lastCoverage: "Última cobertura",
      lotName: "Lote Atual",
      result: "Resultado",
      ovarianEvaluationResult: "Avaliação Ovariana",
      lastCoverageReproductionPeriodId: "Estação de Monta",
      lotDestination: "Lote Destino",
      timeGestationMC: "Tempo de Gestação MC",
      gestationDays: "Tempo de Gestação",
      birthForecast: "Previsão de Parto",
      shouldDiscardFemale: "Descartar Fêmea?",
      discardMotive: "Causa",
    },
  },
  animalWeightHistory: {
    title: "Lista de Pesagens",
    buttonExport: "Análise de Pesagens",
    buttonNew: "Nova Pesagem",
    table: {
      columns: {
        origination: "Origem",
        handlingNumber: "Animal",
        date: "Data",
        weight: "Peso",
        originationLotName: "Lote",
        originationPicketName: "Piquete",
        event: "Tipo",
      },
    },
    originEvents: {
      birthday: "Nascimento",
      sale: "Venda",
      weaning: "Desmama",
      purchase: "Compra",
    },
    form: {
      titleCreateNew: "Adicionar Pesagem",
      titleEdit: "Editar Pesagem",
      animalId: "Identificação Animal",
      date: "Data da Pesagem",
      weight: "Peso",
      gmd: "GMD",
      lotOriginalId: "Lote Atual",
      lotDestinationId: "Lote Destino",
      lastDateWeight: "Data da Última Pesagem",
      lastWeight: "Última Pesagem",
      weaningWeight: "Desmama?",
      editIdentifications: "Editar Identificações?",
    },
    modalExportReport: {
      title: "Análise de Pesagens",
      periodDate: "Período das Pesagens",
      gender: "Sexo",
      status: "Status dos Animais",
      periodAge: "Idade(meses)",
      lotId: "Lote",
      supplierId: "Fornecedor",
      listAnimalsWithoutWeight: "Listar animais sem pesagem no período?",
      showProjectedWeight:
        "Apresentar o peso projetado pela Estratégia de Dieta na Data atual?",
    },
  },
  dailyLiveCattlesDataAgro: {
    codes: {
      D_PEPR_GO_BR: "@ GO",
      D_PEPR_MG_BR: "@ MG",
      D_PEPR_MT_BR: "@ MT",
      D_PEPR_MS_BR: "@ MS",
      D_PEPR_SP_BR: "@ SP",
      D_PEPR_RO_BR: "@ RO",
      D_PEPR_TO_BR: "@ TO",
      D_PEPR_PA_BR: "@ PA",
      D_PEPR_BA_BR: "@ BA",
    },
  },
  animalBirth: {
    title: "Listagem de Partos e Perdas Reprodutivas",
    buttonNew: "Novo Parto/Perda",
    table: {
      columns: {
        matrizAnimalHandlingNumber: "Matriz",
        birthDate: "Data do parto",
        type: "Tipo",
        coverageDate: "Dt. Cobertura",
        coverageType: "Tipo Cobertura",
        reproductor: "Reprodutor",
        childs: {
          handlingNumber: "Identificação",
          gender: "Sexo",
          birthdayWeight: "Peso Nascimento",
          situation: "Situação",
        },
      },
    },
    types: {
      parto: "Parto",
      aborto: "Aborto",
      morteEmbrionaria: "Morte Embrionária",
    },
    form: {
      titleCreateNew: "Novo Parto/Perda",
      titleEdit: "Editar Parto/Perda",
      matrizAnimalId: "Identificação",
      type: "Tipo",
      birthDate: "Data",
      animalReproductionCategory: "Categoria",
      femaleSituation: "Situação",
      gestationDays: "Tempo de Gestação",
      coverageInfoType: "Tipo",
      coverageInfoDate: "Data",
      coverageInfoGestationTime: "Tempo de Gestação",
      coverageInfoBirthForecast: "Prev. Parto",
      coverageInfoReprodutor: "Reprodutor",
      coverageInfoDGDate: "Data do DG",
      coverageInfoDGResult: "Resultado do DG",
      shouldDiscardFemale: "Descartar Fêmea?",
      discardMotive: "Causa",
      lotMatrizDestinationId: "Lote de Produção",
      observation: "Observações",
      child: {
        nickname: "Nome",
        nicknamePlaceHolder: "Nome",
        sisbov: "SISBOV",
        sisbovPlaceHolder: "SISBOV",
        tagId: "Id. Eletrônica",
        tagIdPlaceHolder: "Id. Eletrônica",
        sex: "Sexo",
        male: "Macho",
        female: "Fêmea",
        acquisition: "Aquisição",
        handlingNumber: "Identificação",
        handlingNumberPlaceHolder: "N. de identificação do animal",
        breed: "Raça",
        breedPlaceHolder: "Selecione uma raça",
        cap: "Animal castrado?",
        birthday: "Data de nascimento",
        birthdayWeight: "Peso Nascimento",
        firstInseminationWeight: "Primeira inserminação",
        weaningWeight: "Peso Desmame",
        weaningDate: "Data Desmame",
        asymptoticWeight: "Peso Assintótico",
        asymptoticWeightInfoTooltip:
          "Peso assintótico é o peso que, com base nas informações que o usuário/produtor possui, sobre o histórico do Animal ou Lote de Animais, (considerando a genética, manejo anterior, interação genótipo-ambiente, frame, entre outras variáveis), acredite ser o potencial limite de peso. Ou seja, acima deste peso, dificilmente este animal ganhará peso, em condições normais.",
        acquisitionDate: "Data de aquisição",
        acquisitionEstimatedWeight: "Peso aquisição",
        carcassHarnessing: "Aproveitamento da Carcaça",
        animalFarmFunction: "Finalidade",
        animalReproductionCategory: "Categoria",
        sisbovIdentificationDate: "Data Identificação",
        sisbovInsertBNDDate: "Data Inclusão BND",
        sisbovSlaughterDate: "Data Liberação Abate",
        lotId: "Lote de Produção",
        farmOwner: "Proprietário",
        purchaseCost: "Valor de Entrada",
        stillborn: "Natimorto?",
      },
    },
  },
  animalReproductionState: {
    solteira: "Solteira",
    parida: "Parida",
  },
  animalReproductionCategory: {
    nulipara: "Nulípara", // Nulíparas, são fêmeas de reprodução, desmamadas, que não foram trabalhadas (inseminadas ou colocadas à disposição do touro).
    novilha: "Novilha", // Novilhas, são fêmeas de reprodução, desmamadas, que foram trabalhadas, mas inda não pariram.
    primipara: "Primípara", // Primíparas, são fêmeas de reprodução, que tiverem 1 parto.
    secundipara: "Secundípara", // Secundíparas, são fêmeas de reprodução, que tiverem 2 partos.
    multipara: "Multípara", // Multíparas, são fêmeas de reprodução, que tiveram 3 ou mais partos.
    // Macho
    reprodutor: "Reprodutor", // Reprodutor: Macho destinado para uso na reprodução.
  },
  femaleSituation: {
    vazia: "Vazia",
    prenha: "Prenha",
    servida: "Servida",
  },
  animalFarmFunction: {
    reproduction: "Reprodução",
    fatten: "Engorda",
  },
  birthTypes: {
    parto: "Parto",
    aborto: "Aborto",
    morteEmbrionaria: "Morte Embrionária",
  },
  animalStatus: {
    A: "Ativo",
    Active: "Ativo",
    S: "Vendido",
    Sold: "Vendido",
    D: "Morto",
    Dead: "Morto",
    I: "Inativo",
    Inactive: "Inativo",
  },
  animalMovementEvents: {
    weight: "Pesagem",
    vacination: "Vacinação",
    coverage: "Cobertura",
    pregnancyDiagnosis: "Diagnóstico de Gestação",
    birth: "Parto",
    discard: "Descarte",
    lotEntry: "Entrada no Lote",
  },
  financialClass: {
    buttonNew: "Nova Classe",
    form: {
      titleCreateNew: "Nova Classe",
      titleEdit: "Editar Classe",
      financialNatureId: "Natureza",
      name: "Nome",
      shouldCost: "Considerado para Custeio?",
      shouldDisbursement: "Considerado para Desembolso?",
      depreciation: "Depreciação",
      costCenterName: "Centro de Custo",
    },
  },
  costCenterDefaultNames: {
    PRODUCAO_ANIMAL: "Produção Animal",
    UTILIDADES: "Utilidades",
    INFRAEST_PRODUTIVA: "Infraest. Produtiva",
    INFRAEST_OPERACIONAL: "Infraest. Operacional",
    INFRAEST_APOIO: "Infraest. Apoio",
    TAXA_IMPOSTOS: "Taxas & Impostos",
    SERVICOS: "Serviços",
    INVSUMOS_OPERACIONAIS: "Insumos Operacionais",
    PRODUCAO_PASTO_AGRICOLA: "Produção Pasto/Agrícola",
  },
  financialProject: {
    title: "Projetos Financeiros",
    buttonNew: "Novo Projeto",
    table: {
      columns: {
        name: "Nome Projeto",
        description: "Observações",
        startProjectDate: "Data Início",
        finishProjectDate: "Término",
        status: "Status",
      },
    },
    form: {
      titleCreateNew: "Novo Projeto Financeiro",
      titleEdit: "Editar Projeto Financeiro",
      name: "Nome Projeto",
      description: "Observações",
      startProjectDate: "Data Início",
      finishProjectDate: "Término",
      status: "Ativo?",
    },
  },
  animalGender: {
    Female: "Fêmea",
    Male: "Macho",
  },
  lotItemEntry: {
    form: {
      entryDate: "Data de Entrada",
      amountAnimals: "Quantidade",
      averageWeight: "Peso Médio",
      ageInDays: "Idade (dias/meses/anos)",
      prefixIdentification: "Prefixo para Identificação",
      lotDestinationId: "Lote Destino",
      animalFarmFunction: "Finalidade",
      animalReproductionCategory: "Categoria",
    },
  },
  picketSupplementSupply: {
    title: "Fornecimento",
    buttonNew: "Informe de Fornecimento",
    table: {
      columns: {
        supplyDate: "Data",
        picketsCount: "Piquetes/Baias",
        totalSupplementSupplied: "Total Ofertado(Kg)",
        responsibleName: "Responsáveis",
        subTable: {
          retreatName: "Retiro/Linha",
          picketName: "Piquetes/Baias",
          lotName: "Lote",
          totalAnimals: "Qtde. Animais",
          supplementName: "Suplemento/Ração",
          weightedAverageDryMatter: "%MS",
          totalSupplementSupplied: "Qtde. Ofertada",
          productionSubSystem: "Subsistema de Produção",
          subTable: {
            supplyNumber: "N° Trato",
            supplementName: "Suplemento/Ração",
            dryMatterPercentage: "%MS",
            quantitySupplied: "Qtde. Ofertada",
            responsibleName: "Responsável",
            version: "Versão",
            prevSupplyHourInMinutes: "Hora prev.",
            supplyHourInMinutes: "Hora real",
            readFeederValue: "Leitura de Cocho",
          },
        },
      },
    },
    form: {
      titleCreateNew: "Novo/Editar Fornecimento",
      titleEdit: "Editar Fornecimento",
      supplyDate: "Data",
      supplyNumber: "N° Trato",
      responsibleName: "Responsável",
      retreats: "Retiro/Linha",
      pickets: "Piquetes/Baias",
      lots: "Lotes",
      subSystems: "Subsistema de Produção",
      quantitySupplied: "Qtde Ofertada",
      supplement: "Suplemento/Ração",
      hourSupplied: "Horário de Fornecimento",
      saveOptions: {
        only_save: "Salvar somente",
        save_and_create_new: "Salvar e criar novo fornecimento",
      },
      tableSupplies: {
        picketName: "Piquete/Baia",
        lotName: "Lote",
        animalsCount: "Qtd. Animais",
        avgWeight: "Peso Estim.",
        avgGdp: "GDP Estim.",
        supplementId: "Suplemento/Ração",
        dryMatterPercentage: "MS: Matéria Seca",
        quantitySupplied: "Qtd. Ofertada",
        prevSuppliedPerAnimal: "Prev. cab/dia",
        prevSuppliedDryMatterByWeight: "Prev. MS %PV",
        prevSupplyHourInMinutes: "Hora Prev.",
        supplyHourInMinutes: "Hora Real",
        observation: "Observação",
      },
    },
  },
  partialPayment: {
    title: "Criar Pagamento Parcial",
    form: {
      valuePartialPayment: "Valor Pag. Parcial",
      settleDate: "Data Baixa",
      expirationDate: "Nova Data Vencimento",
      valuePendingPayment: "Valor Pend. Pgto",
      fees: "Valor Juros/Multas",
    },
  },
  financialDashboard: {
    resultCenter: "Centro de Resultado",
    costCenter: "Centro de Custo",
    costType: "Atividade",
    period: "Período",
    financialProject: "Projeto",
    bankAccount: "Conta Bancária",
    buttonConfirm: "Processar",
    periods: {
      lastSevenDays: "Últimos 7 dias",
      lastThirdyDays: "Últimos 30 dias",
      currentMonth: "Mês Atual",
      lastMonth: "Mês Anterior",
      currentYear: "Ano Atual",
      lastYear: "Ano Anterior",
      currentHarvest: "Safra Atual",
      lastHarvest: "Safra Anterior",
      customDateRange: "Especificar Data",
    },
  },
  reproductiveProtocol: {
    title: "Protocolos Reprodutivos",
    buttonNew: "Nova Protocolo",
    table: {
      columns: {
        status: "Status",
        name: "Nome",
        duration: "Duração",
        createdAt: "Data de Criação",
        createdBy: "Responsável Criação",
        applicationDays: {
          orderApplication: "Ordem",
          dayApplication: "D?",
          product: "Produto/Hormônio",
        },
      },
    },
    form: {
      titleCreateNew: "Nova Protocolo",
      titleEdit: "Editar Protocolo",
      groupFarmVisibility: "Quem irá utilizar o Protocolo?",
      name: "Nome genérico",
      duration: "Duração Protocolo",
      newApplicationDay: "Linha",
      observations: "Observações",
      applicationDays: {
        orderApplication: "Ordem",
        dayApplication: "D?",
        product: "Produto/Hormônio",
      },
    },
  },
  animalFarmReproductiveProtocol: {
    title: "Lançamentos de Protocolos",
    buttonNew: "Aplicar Protocolo",
    table: {
      columns: {
        handlingNumber: "Fêmea",
        dateApplication: "Data",
        reproductiveProtocolName: "Protocolo",
        reproductionPeriodDescription: "Estação de Montar",
        lotOriginName: "Lote de Origem",
        lotDestinationName: "Lote Destino",
      },
    },
    form: {
      titleCreateNew: "Aplicar Protocolo",
      titleEdit: "Editar Protocolo",
      buttonApply: "Aplicar",
      applyForAnimalsGroupForm: {
        dateApplication: "Data D0",
        reproductiveProtocolId: "Protocolo",
        reproductionPeriodId: "Estação de Monta",
        lotDestinationId: "Lote Destino",
      },
      groupTitle: "Aplicações",
      animalsTable: {
        handlingNumber: "Identificação",
        lotName: "Lote",
        breedName: "Raça",
        age: "Idade",
        femaleSituation: "Situação",
        animalFarmFunction: "Finalidade",
        animalReproductionState: "Estado",
        animalReproductionCategory: "Categoria",
        lastBirthDate: "Dt. Último Parto",
      },
    },
  },
  currency: {
    AED: "Dirham dos Emirados",
    AFN: "Afghani do Afeganistão",
    ALL: "Lek Albanês",
    AMD: "Dram Armênio",
    ANG: "Guilder das Antilhas",
    AOA: "Kwanza Angolano",
    ARS: "Peso Argentino",
    AUD: "Dólar Australiano",
    AZN: "Manat Azeri",
    BAM: "Marco Conversível",
    BBD: "Dólar de Barbados",
    BDT: "Taka de Bangladesh",
    BGN: "Lev Búlgaro",
    BHD: "Dinar do Bahrein",
    BIF: "Franco Burundinense",
    BND: "Dólar de Brunei",
    BOB: "Boliviano",
    BRL: "Real Brasileiro",
    BRLT: "Real Brasileiro Turismo",
    BSD: "Dólar das Bahamas",
    BTC: "Bitcoin",
    BWP: "Pula de Botswana",
    BYN: "Rublo Bielorrusso",
    BZD: "Dólar de Belize",
    CAD: "Dólar Canadense",
    CHF: "Franco Suíço",
    CHFRTS: "Franco Suíço",
    CLP: "Peso Chileno",
    CNH: "Yuan chinês offshore",
    CNY: "Yuan Chinês",
    COP: "Peso Colombiano",
    CRC: "Colón Costarriquenho",
    CUP: "Peso Cubano",
    CVE: "Escudo cabo-verdiano",
    CZK: "Coroa Checa",
    DJF: "Franco do Djubouti",
    DKK: "Coroa Dinamarquesa",
    DOGE: "Dogecoin",
    DOP: "Peso Dominicano",
    DZD: "Dinar Argelino",
    EGP: "Libra Egípcia",
    ETB: "Birr Etíope",
    ETH: "Ethereum",
    EUR: "Euro",
    FJD: "Dólar de Fiji",
    GBP: "Libra Esterlina",
    GEL: "Lari Georgiano",
    GHS: "Cedi Ganês",
    GMD: "Dalasi da Gâmbia",
    GNF: "Franco de Guiné",
    GTQ: "Quetzal Guatemalteco",
    HKD: "Dólar de Hong Kong",
    HNL: "Lempira Hondurenha",
    HRK: "Kuna Croata",
    HTG: "Gourde Haitiano",
    HUF: "Florim Húngaro",
    IDR: "Rupia Indonésia",
    ILS: "Novo Shekel Israelense",
    INR: "Rúpia Indiana",
    IQD: "Dinar Iraquiano",
    IRR: "Rial Iraniano",
    ISK: "Coroa Islandesa",
    JMD: "Dólar Jamaicano",
    JOD: "Dinar Jordaniano",
    JPY: "Iene Japonês",
    JPYRTS: "Iene Japonês",
    KES: "Shilling Queniano",
    KGS: "Som Quirguistanês",
    KHR: "Riel Cambojano",
    KMF: "Franco Comorense",
    KRW: "Won Sul-Coreano",
    KWD: "Dinar Kuwaitiano",
    KYD: "Dólar das Ilhas Cayman",
    KZT: "Tengue Cazaquistanês",
    LAK: "Kip Laosiano",
    LBP: "Libra Libanesa",
    LKR: "Rúpia de Sri Lanka",
    LSL: "Loti do Lesoto",
    LTC: "Litecoin",
    LYD: "Dinar Líbio",
    MAD: "Dirham Marroquino",
    MDL: "Leu Moldavo",
    MGA: "Ariary Madagascarense",
    MKD: "Denar Macedônio",
    MMK: "Kyat de Mianmar",
    MNT: "Mongolian Tugrik",
    MOP: "Pataca de Macau",
    MRO: "Ouguiya Mauritana",
    MUR: "Rúpia Mauriciana",
    MVR: "Rufiyaa Maldiva",
    MWK: "Kwacha Malauiana",
    MXN: "Peso Mexicano",
    MYR: "Ringgit Malaio",
    MZN: "Metical de Moçambique",
    NAD: "Dólar Namíbio",
    NGN: "Naira Nigeriana",
    NGNI: "Naira Nigeriana",
    NGNPARALLEL: "Naira Nigeriana",
    NIO: "Córdoba Nicaraguense",
    NOK: "Coroa Norueguesa",
    NPR: "Rúpia Nepalesa",
    NZD: "Dólar Neozelandês",
    OMR: "Rial Omanense",
    PAB: "Balboa Panamenho",
    PEN: "Sol do Peru",
    PGK: "Kina Papua-Nova Guiné",
    PHP: "Peso Filipino",
    PKR: "Rúpia Paquistanesa",
    PLN: "Zlóti Polonês",
    PYG: "Guarani Paraguaio",
    QAR: "Rial Catarense",
    RON: "Leu Romeno",
    RSD: "Dinar Sérvio",
    RUB: "Rublo Russo",
    RUBTOD: "Rublo Russo",
    RUBTOM: "Rublo Russo",
    RWF: "Franco Ruandês",
    SAR: "Riyal Saudita",
    SCR: "Rúpias de Seicheles",
    SDG: "Libra Sudanesa",
    SDR: "DSE",
    SEK: "Coroa Sueca",
    SGD: "Dólar de Cingapura",
    SOS: "Shilling Somaliano",
    STD: "Dobra São Tomé/Príncipe",
    SVC: "Colon de El Salvador",
    SYP: "Libra Síria",
    SZL: "Lilangeni Suazilandês",
    THB: "Baht Tailandês",
    TJS: "Somoni do Tajiquistão",
    TMT: "TMT",
    TND: "Dinar Tunisiano",
    TRY: "Nova Lira Turca",
    TTD: "Dólar de Trinidad",
    TWD: "Dólar Taiuanês",
    TZS: "Shilling Tanzaniano",
    UAH: "Hryvinia Ucraniana",
    UGX: "Shilling Ugandês",
    USD: "Dólar Americano",
    USDT: "Dólar Americano",
    UYU: "Peso Uruguaio",
    UZS: "Som Uzbequistanês",
    VEF: "Bolívar Venezuelano",
    VND: "Dong Vietnamita",
    VUV: "Vatu de Vanuatu",
    XAF: "Franco CFA Central",
    XAGG: "Prata",
    XBR: "Brent Spot",
    XCD: "Dólar do Caribe Oriental",
    XOF: "Franco CFA Ocidental",
    XPF: "Franco CFP",
    XRP: "XRP",
    YER: "Riyal Iemenita",
    ZAR: "Rand Sul-Africano",
    ZMK: "Kwacha Zambiana",
    ZWL: "Dólar Zimbabuense",
    XAU: "Ouro",
  },
  importFinancialTransaction: {
    title: "Importar Movimentações Financeiras",
    message:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com as movimentações financeiras, <strong>faça a importação</strong> em seguida e clique em processar.</p>',
    draggerMessage:
      "Clique ou arraste o arquivo para esta área para fazer o upload",
  },
  confinementResultReport: {
    form: {
      title: "Resultado do Confinamento",
      salesPeriod: "Período das Vendas",
      clientId: "Cliente",
      supplierId: "Fornecedor",
      saleScenarioId: "Cenário de Venda(s)",
      documentNumber: "N° do Documento",
      lotIds: "Lotes",
      showDeathData: "Exibir dados de Mortes no período?",
    },
  },
  farmDayScaleTreatmentParameter: {
    title: "Listagem de Sêmens",
    buttonNewSemen: "Novo Sêmen",
    table: {
      columns: {
        name: "Nome",
        registration: "Registro",
        breedName: "Raça",
      },
    },
    form: {
      titleCreateNewSemen: "Novo Sêmen",
      titleEditSemen: "Editar Sêmen",
      name: "Nome",
      registration: "Registro",
      breedId: "Raça",
    },
  },
  farmReadFeederTreatmentParameter: {
    title: "Listagem de Sêmens",
    buttonNewSemen: "Novo Sêmen",
    table: {
      columns: {
        name: "Nome",
        registration: "Registro",
        breedName: "Raça",
      },
    },
    form: {
      titleCreateNewSemen: "Novo Sêmen",
      titleEditSemen: "Editar Sêmen",
      name: "Nome",
      registration: "Registro",
      breedId: "Raça",
    },
  },
  troughFeedingAssessment: {
    title: "Leitura de Cocho",
    buttonNew: "Informe de Leitura de Cocho",
    table: {
      columns: {
        readingDate: "Data",
        supplyDate: "Data Trato",
        responsibleName: "Responsáveis",
        picketName: "Piquetes/Baias",
        lotName: "Lote",
        dietStrategyName: "Est. Dieta",
        totalAnimals: "Qtde. Animais",
        quantitySupplied: "Qtde. Ofertada(Kg)",
        readValue: "Leitura Cocho",
      },
    },
    form: {
      titleCreateNew: "Novo/Editar Leitura Cocho",
      informType: "Informar por",
      informTypeValues: {
        byDate: "Data",
        byPicket: "Piquete/Curral",
      },
      readingType: "Formato de Leitura",
      readingTypeValues: {
        unique: "Única",
        perSupply: "Trato",
      },
      readingResult: "Resultado Leitura",
      readingDate: "Data Leitura",
      readingHour: "Hora Leitura",
      supplyNumber: "N° Trato(s)",
      supplyDates: "Data Trato",
      responsibleName: "Responsável",
      retreats: "Retiro/Linha",
      pickets: "Piquetes/Baias",
      lots: "Lotes",
      tableSupplyNumber: "#Trato",
      amountAnimals: "#Cab",
      supplement: "Suplemento/Ração",
      realHour: "Hora Real",
      prevHour: "Hora Prev",
      prevQuantitySupplied: "Prev Lote (Kg)",
      realQuantitySupplied: "Qtd. Ofertada (Kg)",
      observation: "Observação",
      saveOptions: {
        only_save: "Salvar somente",
        save_and_create_new: "Salvar e criar nova Leitura",
      },
    },
  },
  processTransferAnimalTransactionModal: {
    title: "Processar Transferência de Animais",
    form: {
      origin: "Origem",
      amountAnimals: "Qtd. Cab.",
      sellDate: "Data",
      animalValue: "Valor Med.",
      totalValue: "Valor Total",
      totalInstallments: "Qtd. Parc.",
      firstInstallmentDate: "Data 1° Parc.",
      firstInstallmentValue: "Valor 1° Parc.",
      resultCenter: "Centro de Resultado",
      lotDestinationId: "Lote Destino",
      financialNatureId: "Natureza",
      financialClassId: "Classe",
      farmOwnerId: "Proprietário",
      dividerTitle: "DADOS COMPLEMENTARES",
      useFarmOwnersFromOrigin: "Manter proprietários da Fazenda de Origem",
    },
    messages: {
      rejectModalTitle: "Alerta",
      rejectModalContent:
        "Você realmente deseja rejeitar esta movimentação? Esta ação é irreversível!",
      successSubmit:
        "A transferência foi confirmada com sucesso, e está em processamento. Logo a movimentação será completada.",
      errorSubmit:
        "Houve um erro ao tentar confirmar transferência. Contate o suporte.",
      successReject:
        "A transferência está sendo rejeitada. Logo a movimentação será rejeitada com sucesso.",
      errorReject:
        "Houve um erro ao tentar rejeitar transferência. Contate o suporte.",
      errorNotSelectFarmOwnerOrCheckUseOriginalFarmOwner:
        "É necessário selecionar um Proprietário ou marcar para manter proprietários da Fazenda de Origem.",
      errorFarmOwnerNotCreated:
        "Atenção! Existem proprietários (listados abaixo) de animais, não cadastrados nesta fazenda. Para ajustar, acesse [Administração/Fazendas]",
    },
  },
  weightRanking: {
    top: "Cabeceira",
    middle: "Meio",
    bottom: "Fundo",
  },
  menuNavigation: {
    menu_diet_strategy: "ESTRATÉGIA DE DIETA",
    menu_picket_supplement_supply: "FORNECIMENTO",
    menu_trough_feeding_assessment: "LEITURA DE COCHO",
    menu_weights: "PESAGENS",
    menu_veterinary: "VETERINÁRIO",
    menu_reproductive: "REPRODUTIVO",
    menu_dashboard: "DASHBOARD",
    menu_purchase_scenario: "CENÁRIO COMPRA",
    menu_sale_scenario: "CENÁRIO VENDA",
    menu_purchase_scenario_boitel: "CENÁRIO COMPRA",
    menu_sale_scenario_boitel: "CENÁRIO VENDA",
    menu_nutritional: "NUTRICIONAL",
    menu_entry: "ENTRADA",
    menu_technician_veterinary: "VETERINÁRIO",
    menu_exit: "SAÍDA",
    menu_manager: "GESTÃO",
    menu_handling: "MANEJOS",
    menu_purchase: "COMPRA",
    menu_sale: "VENDA",
    menu_purchase_boitel: "COMPRA",
    menu_sale_boitel: "VENDA",
    menu_recreate_fatten_extensive: "R/E PASTO",
    menu_growth: "CRIA",
    menu_confinement: "R/E CONFINAMENTO",
    menu_boitel: "BOITEL",
  },
  weather: {
    title: "Dados Climáticos",
  },
  arrobaPrice: {
    title: "Dados Financeiro",
    dataAgro: {
      selectDate: "Selecione uma data: ",
    },
    marketAnalysisDash: {
      title: "Curva de preços",
      selectCurve: "Selecione a curva que deseja visualizar:",
      emptyMessage: "Selecione uma curva para que seja exibido o gráfico",
    },
  },
  alertPanel: {
    title: "alertas & pendências",
    emptyMessage: "Nenhum alerta encontrado nessa data",
  },
  viewTransferAnimalTransactionModal: {
    title: "Detalhes Transferência de Animais",
    form: {
      origin: "Origem",
      amountAnimals: "Qtd. Cab.",
      sellDate: "Data",
      animalValue: "Valor Med.",
      totalValue: "Valor Total",
      totalInstallments: "Qtd. Parc.",
      firstInstallmentDate: "Data 1° Parc.",
      firstInstallmentValue: "Valor 1° Parc.",
      resultCenter: "Centro de Resultado",
      lotDestinationId: "Lote Destino",
      financialNatureId: "Natureza",
      financialClassId: "Classe",
      farmOwnerId: "Proprietário",
      dividerTitle: "DADOS COMPLEMENTARES",
    },
    table: {
      columns: {
        handlingNumber: "Animal",
        dateTransferCreation: "Data de Registro",
        dateTransferAccept: "Data de Confirm.",
        weightTransfer: "Peso",
        priceTransfer: "Valor",
        lotOriginName: "Lote de Origem",
        lotDestinationName: "Lote de Destino",
        responsibleCreateTransfer: "Responsável Origem",
        responsibleAcceptTransfer: "Responsável Destino",
      },
    },
  },
  movementAnimalReportParameter: {
    title: "Dinâmica de rebanho por idade",
    classification: "Classificação",
    messages: {
      errorLenghtEqualZero:
        "É necessário ter ao menos uma classificação de idade por gênero.",
      errorClassificationWithoutMinAge:
        "É necessário que as classificações tenham idade minima.",
      errorClassificationWithManyMaxAgeNull:
        "Só é permitido uma classificação sem idade máxima por gênero",
      errorClassificationWithMaxAgeLessThanOrEqualToMinAge:
        "É necessário que a idade máxima seja maior que a idade mínima",
      errorClassificationWithWrongOrder:
        "É necessário que as classificações sigam uma ordem de idade, onde a idade mínima de uma classificação deve ser maior que a máxima da anterior.",
    },
  },
  movementAnimalReportModalRequest: {
    title: "Movimentações de Animais",
    form: {
      onlyFinalStock: "Estoque Atual de Animais",
      allFarmGroups: "Todas as Fazendas",
      datePeriod: "Período",
      breedId: "Raça",
      ownerId: "Proprietário",
      supplierId: "Fornecedor",
    },
  },
  successGenerateReport: "Relatório gerado com sucesso",
  errorGenerateReport:
    "Houve um erro ao gerar relatório. Entre em contato com o suporte",
  reports: {
    tabs: {
      favorite: "Favoritos",
      animal: "Animais",
      produciton: "Produção",
      financial: "Financeiros",
      management: "Gerenciais",
      operational: "Operações",
    },
    titles: {
      SimpleAnimalReport: "Relatório Simples de Animais",
      ListAnimalReportReproduction:
        "Relatório de listagem de animais (Reprodução)",
      ListAnimalReportFatten: "Relatório de listagem de animais (Engorda)",
      AnimalWeightImportReport: "Relatório para importação de pesagens",
      ReportMovementAnimals: "Relatório de Movimentações",
      ReportNutritionalConsumption:
        "Relatório de Tratos/Consumo de Ração/Suplemento",
      ReportPicketFeedingAssessment: "Planilha de Fornecimento",
      IntensiveResultReport: "Relatório de Resultado de Confinamento",
      FarmSalesReport: "Relatório de Resultado de Vendas",
      FinancialTransactionsReport: "Exportação de Movimentações Financeiras",
      FinancialCashFlowReport: "Relatório de Fluxo de Caixa",
      DisbursementReport: "Relatório de Desembolso",
      ReportAnimalWeightHistory: "Relátorio de Pesagens",
    },
    messages: {
      errorUpdateFavorite:
        "Houve um erro ao atualizar/remover o relatório de favoritos",
    },
  },
  financialCashFlowReportModalRequest: {
    title: "Relatório de Fluxo de Caixa",
    form: {
      transactionType: "Tipo",
      periodStartDate: "Inicio",
      periodEndDate: "Fim",
      periodType: "Período",
      intervalType: "Intervalo",
      farmIds: "Fazenda(s)",
      financialNatureId: "Natureza",
      financialClassId: "Classe",
      costActivityType: "Atividade",
      showZeroValues: "Exibir Classes sem movimentações no período?",
      showAnalyticalData: "Gerar relatório analítico?",
    },
    periods: {
      lastSevenDays: "Últimos 7 dias",
      lastThirdyDays: "Últimos 30 dias",
      currentMonth: "Mês Atual",
      lastMonth: "Mês Anterior",
      currentYear: "Ano Atual",
      lastYear: "Ano Anterior",
      currentHarvest: "Safra Atual",
      lastHarvest: "Safra Anterior",
      customDateRange: "Especificar Data (Máx. 12 meses)",
    },
    intervals: {
      daily: "Diário",
      weakly: "Semanal",
      monthly: "Mensal",
      yearly: "Anual",
    },
    messages: {
      errorPeriodMoreThanTwelveMonths: "Período não pode passar de 12 meses.",
      errorNoDataFound:
        "Não foi encontrado nenhum resultado com o filtro informado",
    },
  },
  disbursementReportModalRequest: {
    title: "Relatório de Desembolso",
    form: {
      transactionType: "Tipo",
      periodStartDate: "Inicio",
      periodEndDate: "Fim",
      periodType: "Período",
      intervalType: "Intervalo",
      farmIds: "Fazenda(s)",
      financialNatureId: "Natureza",
      financialClassId: "Classe",
      costActivityType: "Atividade",
      showZeroValues: "Exibir Classes sem movimentações no período?",
      showAnalyticalData: "Gerar relatório analítico?",
    },
    periods: {
      lastSevenDays: "Últimos 7 dias",
      lastThirdyDays: "Últimos 30 dias",
      currentMonth: "Mês Atual",
      lastMonth: "Mês Anterior",
      currentYear: "Ano Atual",
      lastYear: "Ano Anterior",
      currentHarvest: "Safra Atual",
      lastHarvest: "Safra Anterior",
      customDateRange: "Especificar Data (Máx. 12 meses)",
    },
    intervals: {
      daily: "Diário",
      weakly: "Semanal",
      monthly: "Mensal",
      yearly: "Anual",
    },
    messages: {
      errorPeriodMoreThanTwelveMonths: "Período não pode passar de 12 meses.",
      errorNoDataFound:
        "Não existem movimentações financeiras e/ou animais no período informado.",
    },
  },
};
