export default {
  diet: {
    title: "Diets",
    buttonNewDiet: "New diet",
    table: {
      title: "Diets list",
      columns: {
        status: "Status",
        description: "Diet",
        phosphorus: "Phosporus (mg)",
        nitrogen: "Nitrogen (mg)",
        tdn: "TDN (g)",
        cp: "Crude Protein (g)",
        calcium: "Calcium (mg)",
        cost: "Cost (R$/Kg)",
      },
    },
    drawerTitle: "Diet Register",
    drawerTitleEdit: "Diet edition",
    drawerSubTitle: "Complete the fields below to create a new diet",
    drawerSubTitleEdit: "Complete the fields below to edit a new diet",
    validationMessage: "Fill in all required fields (*) correctly",
    form: {
      name: "Diet ID",
      namePlaceHolder: "Diet name",
      pasture: "Forage",
      pasturePlaceHolder: "What is the dietary forage?",
      pastureNoSelection: "None",
      supplements: "Supplem/Concent.",
      supplementsPlaceHolder:
        "What are the supplem/concentr inserted in the diet??",
      cost: "Diet Cost ($/Kg)",
      costPlaceHolder: "What is the cost of the diet in $/kg?",
      phosphorus: "Phosphorus (mg)",
      phosphorusPlaceHolder: "How much Phosphorus?",
      nitrogen: "Nitrogen (mg)",
      nitrogenPlaceHolder: "How much Nitrogen?",
      calcium: "Calcium (mg)",
      calciumPlaceHolder: "How much Calcium?",
      tdn: "TDN (g)",
      tdnPlaceHolder: "How much TDN?",
      cp: "Crude Protein (g)",
      cpPlaceHolder: "How much Crude Protein?",
      status: "Diet status",
      error: {
        requiredPastureSupplement: {
          title: "Error",
          message:
            "Either Forage or at least one Supplement/Concent. must be selected.",
        },
      },
    },
    saveOptions: {
      only_save: "Save",
      save_and_create_new: "Save and add new diet",
    },
  },
  pasture: {
    title: "Forage",
    buttonNewPasture: "New Forage",
    table: {
      title: "Forage list",
      columns: {
        status: "Status",
        description: "Forage",
        phosphorus: "Phosphorus (mg)",
        nitrogen: "Nitrogen (mg)",
        tdn: "TDN (g)",
        cp: "CP (g)",
        calcium: "Calcium (mg)",
        cost: "Cost ($/kg)",
        entryHeight: "Entry height",
        exitHeight: "Exit height",
        growthSpeed: "Growth rate",
        yield: "Yield",
      },
    },
    drawerTitle: "Forage Roster",
    drawerTitleEdit: "Edit Forage",
    drawerSubTitle: "Complete the fields below to create a new forage",
    drawerSubTitleEdit: "Complete the fields below to edit the forage",
    validationMessage: "Fill in all required fields (*) correctly",
    form: {
      groupFarmVisibility: "Who will use the forage?",
      name: "Forage Id",
      namePlaceHolder: "Forage name",
      pastureType: "Scientific name",
      fertilized: "Fertilized",
      notFertilized: "Not fertilized",
      cost: "Cost",
      costPlaceHolder: "Unit: $",
      cp: "CP: Crude Protein",
      cpPlaceHolder: "Unit: g",
      fdn: "NDF: Neutral detergent fiber",
      fdnPlaceHolder: "Unit: %",
      tdn: "TDN: Total Digestible Nutrients",
      tdnPlaceHolder: "Unit: g",
      phosphorus: "Phosphorus",
      phosphorusPlaceHolder: "Unit: g/kg",
      nitrogen: "Nitrogen",
      nitrogenPlaceHolder: "Unit: mg/kg",
      calcium: "Calcium",
      calciumPlaceHolder: "Unit: g/kg",
      zinc: "Zinc",
      zincPlaceHolder: "Unit: mg/kg",
      sodium: "Sodium",
      sodiumPlaceHolder: "Unit: g/kg",
      formation: "Full growth",
      formationPlaceHolder: "Unit: days",
      rest: "Resting",
      restPlaceHolder: "Unit: days",
      entryHeight: "Optimal Grazing Height",
      entryHeightPlaceHolder: "Unit: cm",
      exitHeight: "Optimal Rest Height",
      exitHeightPlaceHolder: "Unit: cm",
      growthRate: "Growth rate",
      growthRatePlaceHolder: "Unit: kg/ha/day",
      leafDensityProduction: "Forage Dry Matter Production",
      leafDensityProductionPlaceHolder: "Unit: t/ha/cycle",
      growthSpeed: "Growth rate",
      growthSpeedPlaceHolder: "Unit: kg MS/㎡/day",
      yield: "Yield",
      yieldPlaceHolder: "Unit: kg DM/㎡",
      status: "Forage status",
      nutritionalData: "NUTRITIONAL DATA",
      capacity: "Capacity",
      maximumConsumption: "Max consumption",
      minimumConsumption: "Min consumption",
      radiogroup: {
        farm: "Only for this farm",
        group: "To all farms in the group",
      },
      fda: "ADF",
      lignin: "Lignin",
      ee: "Ether extract",
      maximumHeight: "Max Height",
      maximumHeightPlaceHolder: "Max Height",
    },
    modal: {
      confirmation: {
        title: "Changing the Forage Type",
        message:
          "AWhen you confirm the change of the forage type, the values for ideal entry height, ideal exit height and weather stations will be changed. Do you want to continue?",
        confirmButton: "Confirm",
        cancelButton: "Cancel",
      },
    },
    errorConsumptionMaximumLessThanMinimum:
      "Maximum consumption must be greater than minimum consumption",
    errorEntryHeightLessThanExitEntryHeight:
      "The optimal grazing height should be greater than the optimal resting height",
    errorNecessaryOneSeason:
      "It is necessary to have at least one weather station informed",
    errorFertilizedSeasonInfoIncomplete:
      "Report or keep pending data on fertilized forage in all climate seasons",
    errorUnfertilizedSeasonInfoIncomplete:
      "Report or keep pending data on non-fertilized forage in all climate seasons",
    errorPastureInfoForAllSeasonsIncomplete:
      "Report or keep pending data on fertilized/non-fertilized forage in all climate seasons",
    saveOptions: {
      only_save: "Save",
      save_and_create_new: "Save and add a new one",
    },
  },
  breed: {
    title: "Breeds",
    buttonNewBreed: "New breed",
    table: {
      title: "List of breeds",
      columns: {
        status: "Status",
        geneticGroup: "Genetic group",
        description: "Descrption",
        name: "Breed",
        asymptoticWeight: "Sigmoid weight(kg)",
        estimatedMaximumAge: "Sigmoid age",
        correctionFactor: "Correction Factor",
        maturationRate: "Maturation Rate",
      },
      menu: {
        parameters: "Parameters",
      },
    },
    drawerTitle: "Breed Register",
    drawerTitleEdit: "Edit Breed",
    drawerSubTitle: "Complete the fields below to create a new breed",
    drawerSubTitleEdit: "Complete the fields below to edit the breed",
    validationMessage: "Fill in all required fields (*) correctly",
    form: {
      name: "Breed Id",
      namePlaceHolder: "Breed name",
      description: "Description",
      descriptionPlaceHolder: "Breed description",
      geneticGroup: "Genetic group",
      geneticGroupPlaceHolder: "Select the genetic group",
      status: "Breed status",
    },
    saveOptions: {
      only_save: "Save",
      save_and_create_new: "Save and create a new one",
      save_and_add_parameters: "Save and configure parameters",
    },
    drawerParametersTitle: "Breed parameters",
    formParameters: {
      asymptoticWeight: "Sigmoid weight",
      asymptoticWeightPlaceHolder: "Set the weight",
      theoricalCurve: "Theoretical curve",
      estimatedMaximumAge: "Sigmoid age",
      estimatedMaximumAgePlaceHolder: "Sigmoid age",
      correctionFactor: "Correction Factor",
      correctionFactorPlaceHolder:
        "Correction Factor for calculating the growth curve for the breed",
      maturationRate: "Maturation Rate",
      maturationRatePlaceHolder:
        "Maturation Rate for calculating the growth curve for the breed",
      status: "Breed Parameter Status",
      extimatedCurveTitle: "ESTIMATED GROWTH CURVE",
      genetic: "GENETICS",
      diet: "DIET",
      gestationDivider: "Gestation period",
      minGestationTime: "Min",
      medGestationTime: "Avg",
      maxGestationTime: "Max",
    },
  },
  handlingOperation: {
    title: "Operations",
    buttonNewPasture: "New operation",
    table: {
      title: "Operations list",
      columns: {
        status: "Status",
        operation: "Operation",
        action: "Action",
      },
    },
    drawerTitle: "Operations Roster",
    drawerTitleEdit: "Edit Operation",
    drawerSubTitle: "Complete the fields below to create a new Operation",
    drawerSubTitleEdit: "Complete the fields below to edit the operation",
    validationMessage: "Fill in all required fields (*) correctly",
    form: {
      name: "Operation",
      namePlaceHolder: "Operation name",
      action: "Action",
      actionPlaceHolder: "Select procedure action",
      recurrence: "Recurrence",
      recurrenceAmount: "Qty.",
      quarantine: "Quarantine",
      quarantineAmount: "Enter the number of days",
      breed: "Breed",
      breedPlaceHolder: "Select breeds",
      gender: "Gender",
      genderPlaceHolder: "Select",
      age: "Age",
      weight: "Weight",
      dontExecuteOperation:
        "Do not perform the operation on animals that are in:",
      inQuarantine: "Quarantine",
      inPregnancy: "Pregnancy",
      operationCost: "Operation cost",
      noCost: "No cost",
      haveCost: "Cost(define the cost in the procedure)",
      status: "Operation status",
    },
    parameters: "PARAMETERS",
    limitations: "LIMITS",
    errors: {
      recurrenceMinAndMaxDayCanBeNull: {
        title: "Error",
        message:
          "The minimum and maximum number of days for the recurrence cannot be blank.",
      },
      recurrenceQtdCanBeNull: {
        title: "Error",
        message: "The number of times the recurrence occurs cannot be blank.",
      },
    },
    saveOptions: {
      only_save: "Save",
      save_and_create_new: "Save and add a new Operation",
    },
  },
  handling: {
    title: "Livestock Husbandry",
    tabs: {
      pending: "In Process",
      finalized: "Finished",
      dietStrategies: "Diet Strategies",
      vacinations: "Veterinary proced",
      handlingReproduction: "Reproduction",
      animalWeightHistory: "Weighings",
      picketSupplementSupply: "Suuplied",
      troughFeedingAssessment: "Food Intake Assessment",
    },
    vacinations: {
      buttonNew: "New Injection",
      form: {
        titleEdit: "Edit Injection",
        titleCreateNew: "New Injection",
        dividerTitle: "Previous Injection",
        animalId: "Animal",
        lastDailyWeight: "Last weighing",
        handlingNumber: "Animal",
        veterinarySupplementId: "Product",
        veterinarySupplementApplicationType: "Administration type",
        veterinarySupplementApplicationValue: "Dosage",
        applicationQuantity: "Amount injected",
        applicationUnit: "Unit",
        applicationDay: "Injection date",
      },
      table: {
        title: "Veterinary",
        columns: {
          animalName: "Animal",
          veterinarySupplementName: "Name",
          type: "Type",
          applicationDate: "Date",
          applicationQuantity: "Quantity",
          applicationUnit: "Unit",
        },
      },
    },
  },
  payments: {
    title: "Payments",
  },
  parameters: {
    title: "Parameters",
    tabs: {
      general: "General",
      breed: "Breeds",
      diet: "Diets",
      supplement: "Suplem./Concent.",
      pasture: "Forage",
      alert: "Alerts",
      benchmark: "Benchmarks",
      marketAnalysis: "Price curve",
      cost: "Costs",
      activity: "Activities",
      boitelPrice: "Boitel - Daily Rates",
      integration: "Integrations",
      financial: "Financial/Costs",
      reproduction: "Reproduction",
      reproductionParameter: "Parameters",
      semen: "Semen",
      inseminators: "Technician",
      supplie: "Inputs",
      animal: "Animals",
      deathReasons: "Cause Mortis",
      unit: "Units",
      veterinary: "Veterinary",
      commercialization: "Comercialization",
      quota: "Quotas",
      multipleBull: "Multiple Bull",
      reproductiveProtocol: "Protocols",
      handlingParameters: "Husbandry",
      farmDayScaleTreatmentParameter: "Feeding schedule",
      farmReadFeederTreatmentParameter: "Food Intake Assessment",
      ageRange: "Age Range",
    },
  },
  boitelPrice: {
    title: "Boitel - Daily Rate",
    buttonNew: "New",
    table: {
      title: "Boitel - Daily Rate",
      columns: {
        name: "Name",
        farmName: "Farm name",
        validity: "Valid thru",
        status: "Status",
      },
    },
  },
  integrationPartners: {
    table: {
      title: "Integrations",
      columns: {
        status: "Status",
        system: "System",
        authType: "Authentication type",
      },
    },
    authType: {
      Token: "Token",
      UserAndPassword: "User and password",
    },
  },
  imports: {
    title: "Imported files",
    tabs: {
      animal: "Animals",
      weight: "Weighings",
      supplement: "Supplements",
      financialTransactions: "Financial entries",
    },
    status: {
      queue: "Waiting",
      processing: "In process",
      processed: "Finished",
      deleting: "Erasing",
      error: "Error",
    },
    animal: {
      buttonImport: "Import",
      table: {
        title: "List of Animal Imports",
        columns: {
          status: "Status",
          fileName: "File",
          date: "Date",
          total: "Total",
          numberImported: "Imported",
          numberAlerted: "Alerts",
          numberError: "Errors",
          numberDuplicated: "Duplicate",
          numberUpdated: "Updated",
          message: "Message",
        },
      },
      details: {
        breadCumbsImportLog: "Importings",
        breadCumbsImportLogDetails: "Data Import Details",
        collapseAnimals: "Animals",
        fileNameTitle: "File",
        statusTitle: "Status",
        dateTitle: "Date",
        table: {
          columns: {
            rowNumber: "Excel row",
            handlingNumber: "Animal Id",
            message: "Reason",
            alert: "Alerts",
          },
        },
      },
    },
    weight: {
      buttonImport: "Import",
      table: {
        title: "List of Weighing Imports",
        columns: {
          status: "Status",
          fileName: "File",
          date: "Date",
          total: "Total",
          numberImported: "Imported",
          numberError: "Errors",
          numberDuplicated: "Duplicate",
          numberUpdated: "Updated",
          message: "Message",
        },
      },
      details: {
        breadCumbsImportLog: "Importings",
        breadCumbsImportLogDetails: "Data Import Details",
        collapseAnimals: "Weighings",
        fileNameTitle: "File",
        statusTitle: "Status",
        dateTitle: "Date",
        table: {
          columns: {
            rowNumber: "Excel row",
            weight: "Weight",
            handlingNumber: "Animal Id",
            message: "Reason",
          },
        },
      },
    },
    supplement: {
      buttonImport: "Import",
      table: {
        title: "List of Supplement Imports",
        columns: {
          status: "Status",
          fileName: "File",
          date: "Date",
          total: "Total",
          numberImported: "Imported",
          numberError: "Errors",
          numberDuplicated: "Duplicate",
          numberUpdated: "Updated",
          message: "Message",
        },
      },
      details: {
        breadCumbsImportLog: "Imports",
        breadCumbsImportLogDetails: "Data Import Details",
        collapseAnimals: "Supplements",
        fileNameTitle: "File",
        statusTitle: "Status",
        dateTitle: "Date",
        table: {
          columns: {
            rowNumber: "Excel row",
            name: "Supplement Id",
            message: "Reason",
          },
        },
      },
    },
    financialTransactions: {
      buttonImport: "Import",
      table: {
        title: "List of Financial Transaction Imports",
        columns: {
          status: "Status",
          fileName: "File",
          date: "Date",
          total: "Total",
          numberImported: "Imported",
          numberError: "Errors",
          numberDuplicated: "Duplicate",
          numberUpdated: "Updated",
          message: "Message",
        },
      },
      details: {
        breadCumbsImportLog: "Imports",
        breadCumbsImportLogDetails: "Import Details",
        collapseAnimals: "Financial posting",
        fileNameTitle: "File",
        statusTitle: "Status",
        dateTitle: "Date",
        table: {
          columns: {
            rowNumber: "Excel row",
            name: "Financial Posting Id",
            message: "Reason",
          },
        },
      },
    },
  },
  benchmark: {
    title: "Benchmarks",
    buttonNewBenchmark: "New benchmark",
    table: {
      title: "Benchmarks List",
      columns: {
        status: "Status",
        description: "Benchmark",
      },
    },
    drawerTitle: "Benchmark Roster",
    drawerTitleEdit: "Edit Benchmark",
    drawerSubTitle: "Complete the fields below to register a new benchmark",
    drawerSubTitleEdit: "Complete the fields below to edit a new benchmark",
    validationMessage: "Fill in all required fields (*) correctly",
    form: {
      name: "Name",
      namePlaceHolder: "Identification",
      status: "Benchmark status",
      groupFarmVisibility: "Who will view the benchmark?",
      radiogroup: {
        farm: "This farm only",
        group: "All farms in the Group",
      },
      harvestDivider: "Harvesting year",
    },
    errorAtLeastOneKPIValue:
      "Enter at least one value for any Indicators(KPIs)",
    saveOptions: {
      only_save: "Save",
      save_and_create_new: "Save benchmark and add a new one",
    },
  },
  marketAnalysis: {
    title: "Price Curve",
    buttonNewMarketAnalysis: "New Price Curve",
    table: {
      title: "Price Curves List",
      columns: {
        status: "Status",
        type: "Type",
        description: "Name",
        baseline: "Default",
      },
    },
    type: {
      bovexo: "BovExo",
      progressive: "Progressive",
      linear: "Linear",
      free: "Free",
    },
  },
  supplement: {
    title: "Supplem./Concent.",
    buttonNewSupplement: "New Supplem./Concent.",
    table: {
      title: "List of Supplem./Concent.",
      columns: {
        status: "Status",
        description: "Supplem./Concent.",
        industrialName: "Brand name",
        supplierName: "Manufacturer",
        createdAt: "Date created",
        userName: "Created by",
        version: "Version",
        cp: "CP: Crude Protein",
        tdn: "TDN: Total Digestible Nutrients",
        npn: "NNP - Nonprotein nitrogen compounds (Max)",
        dryMatterPercentage: "DM: Dry Matter",
        phosphorus: "Phosphorus",
        nitrogen: "Nitrogen",
        calcium: "Calcium",
        zinc: "Zinc",
        sodium: "Sodium",
        additiveEffect: "Additive effect",
        fda: "ADF",
        lignin: "Lignin",
        ee: "Ether Extract ",
      },
    },
    drawerTitle: "Supplem./Concent. Roster",
    drawerTitleEdit: "Edit Supplem./Concent.",
    drawerSubTitle:
      "Complete the fields below to register a new Supplem./Concent.",
    drawerSubTitleEdit:
      "Complete the fields below to edit a new Supplem./Concent.",
    validationMessage: "Fill in all required fields (*) correctly",
    form: {
      supplementType: "Ration or Supplem.?",
      supplementTypeHelp:
        "Ration/Mixture: Ready-made diet, produced on the farm itself. Supplements: Product purchased and supplied in pure form.",
      supplementClassification:
        "What is the classification of the Supplem./Ration?",
      supplementClassificationHelp: {
        title_0: "Mineral Supplem.",
        description_0:
          "when it contains macro and/or micro mineral elements in its composition, and may present, in the final product, a value of less than forty-two percent of protein equivalent.",
        title_1: "Protein-Mineral Supplem.",
        description_1:
          "when it has in its composition, macro and/or micro mineral element, at least 20% crude protein (CP) and provides, at least, 30 grams of crude protein (CP) per 100 kg of BW.",
        title_2: "Energy-Protein-Mineral Supplement",
        description_2:
          "when it has in its composition, macro and/or micro mineral element, at least 20% crude protein, provide at least 30 grams of crude protein and 100 grams of total digestible nutrients (TDN) per 100 kg of BW.",
        source: "*According to Brazilian MAPA - IN12 2004/11/30.",
      },
      name: "Generic name",
      namePlaceHolder: "Identification",
      importCode: "Importing code",
      industrialName: "Brand name",
      industrialNamePlaceHolder: "Brand name",
      supplierName: "Manufacturer",
      supplierNamePlaceHolder: "Manufacturer name",
      status: "Supplem./Concent. Status",
      nutritionalDataDivider: "Nutriotional Data",
      capacityDivider: "Amount per offering type",
      sustenanceCapacity: "Qty per offering type",
      information: 'All information is related to "Animal/day"',
      sustenanceCapacityDrawerTitle:
        "Quantities by Supplem./Concent Offering Type.",
      minimumAmount: "Min Amt.",
      maximumAmount: "Max Amt.",
      minimum: "Min",
      maximum: "Max",
      ratio: "Proportion",
      cost: "Daily cost",
      costPlaceHolder: "Unit: $",
      cp: "CP: Crude Protein",
      cpPlaceHolder: "Unit: g/kg",
      tdn: "TDN: Total Digestible Nutrients",
      tdnPlaceHolder: "Unit: g/kg",
      npn: "NNP - Nonprotein nitrogen compounds (Max)",
      npnPlaceHolder: "Unit: g/kg",
      dryMatterPercentage: "DM: Dry Matter",
      phosphorus: "Phosphorus",
      phosphorusPlaceHolder: "Unit: g/kg",
      nitrogen: "Nitrogen",
      nitrogenPlaceHolder: "Unit: mg/kg",
      calcium: "Calcium",
      calciumPlaceHolder: "Unit: g/kg",
      zinc: "Zinc",
      zincPlaceHolder: "Unit: mg/kg",
      sodium: "Sodium",
      sodiumPlaceHolder: "Unit: g/kg",
      voluntaryConsumption: "Voluntary feed",
      minimumVoluntaryConsumptionPlaceHolder: "Inform",
      maximumVoluntaryConsumptionPlaceHolder: "Inform",
      pure: "Offering of pure",
      mix: "Offering of mixed",
      recommendedTrough: "Trough size recommended",
      recommendedTroughPlaceHolder: "Inform",
      groupFarmVisibility: "Who will use the Supplement/Concent.?",
      radiogroup: {
        farm: "This farm only",
        group: "All farms in the group",
      },
      additiveEffect: "Additive effect",
      additiveEffectPlaceHolder: "Unit: g/dia",
      additiveEffectHelp:
        "Some mineral supplements have additives in their formulation that enhance the animals' weight gain, and the supplier may indicate an incremental gain (g/day), adding to the calculated predicted gain (Pasture + Supplement) or indicate a % increase in the total Crude Protein (CP) to be considered in the calculation.",
      fda: "ADF",
      fdaPlaceHolder: "Unit: g/kg",
      lignin: "Lignin",
      ligninPlaceHolder: "Unit: g/kg",
      ee: "Ethereal Extract",
      eePlaceHolder: "Unit: %/MS",
    },
    errorVoluntaryConsumptionMaximumLessThanMinimum:
      "The maximum consumption recommended by the supplier must be greater than or equal to the minimum consumption",
    errorPureMaximumLessThanMinimum:
      "The Maximum Pure Product Offered must be greater than or equal to the Minimum Pure Product Offered",
    errorPureMaximumTroughLessThanMinimumTrough:
      "The maximum recommended Trough size for Pure Product Offering must be greater than or equal to the minimum size",
    errorMixMaximumLessThanMinimum:
      "The maximum Mix supply must be greater than or equal to the minimum Mix supply.",
    errorMixMaximumTroughLessThanMinimumTrough:
      "The maximum recommended Trough size for Mix Offering must be greater than or equal to the minimum size",
    errorNecessarySustenanceCapacity:
      "It is required to inform the quantity data by supply method.",
    errorWrongNpnInput:
      "Attention! Only products with up to 42.0% NNP - Protein Equivalent are classified as Mineral Supplements. Please check the Classification of this Supplement.",
    saveOptions: {
      only_save: "Save",
      save_and_create_new: "Save and add a new Supplem./Concent.",
    },
    details: {
      tabs: {
        supplementData: "Supplement",
        supplementHistory: "Historic data",
      },
      supplementType: "Type",
      supplementClassification: "Classification",
      name: "Generic name",
      industrialName: "Brand name",
      supplierName: "Manufacturer",
      ratio: "Proportion",
      cost: "Cost",
      nutritionalDataDivider: "Nutritional Data",
      tdn: "TDN: Total Digestible Nutrients",
      cp: "CP: Crude Protein",
      dryMatterPercentage: "MS: Matéria Seca",
      calcium: "Calcium",
      phosphorus: "Phosphorus",
      nitrogen: "Nitrogen",
      zinc: "Zinc",
      sodium: "Sodium",
      voluntaryConsumption: "Voluntary feed",
      pure: "Amt. of Pure Product",
      mix: "Amt. of Mixed",
      recommendedTrough: "Recom. Trough",
      minimum: "Min.",
      maximum: "Max.",
      information: 'All information is related to "Animal/day"',
      capacityDivider: "QUANTITIES BY OFFER MODE",
      sustenanceCapacity: "QUANTITIES BY OFFER MODE",
      sustenanceCapacityDrawerTitle:
        "Quantities by Supplement/Concent Offering Mode.",
      npn: "NNP - Nonprotein nitrogen compounds (Max)",
      additiveEffect: "Additive effect",
      fda: "ADF",
      lignin: "Lignin",
      ee: "Ethereal Extract",
    },
  },
  lot: {
    validationMessage: "Fill in all required fields (*) correctly",
    tabs: {
      production: "Production",
      movementHistory: "Movements Log",
      receipt: "Received",
    },
    production: {
      title: "Production BATCHs",
      buttonNewLot: "New Production BATCH",
      details: {
        breadCumbsProductionLot: "Production BATCHs",
        breadCumbsLotDetails: "BATCH Details ",
        createdAt: "Created:",
        options: "Options",
        labelSystem: "ACTIVITY",
        labelDietStrategy: "Diet Strategy",
        baseline: "Baseline",
        optionalStrategies: "Optional Strategies",
        optional: "Optionals",
        labelAnimals: "ANIMALS",
        labelAsymptoticWeight: "SIGMOID WEIGHT",
        labelMarketValuation: "MARKET VALUE",
        labelProductionCost: "Production Cost",
        makeValuation: "Run Assessment",
        labelWeights: "WEIGHINGS",
        labelAverageWeight: "AVG WEIGHT:",
        labelAverageWeightGain: "ADG:",
        labelPicket: "PADDOCK / PEN:",
        labelProductionArea: "USEFUL AREA:",
        labelTotalArea: "TOTAL AREA:",
        labelSupportCapacity: "Carrying Cap.:",
        titleAnimals: "Animals",
        titleWeigth: "Weight",
        viewBy: "View by:",
        tooltipEstimatedCurve:
          'Theoretical curve obtained from BREED. Click on "Estimate for BATCH"to set the BATCH values',
        rankBy: "Rank by:",
      },
      table: {
        columns: {
          lotNumber: "BATCH number",
          lotName: "BATCH",
          salesScenario: "Sales Scenario",
          dietStrategyName: "Diet Strategy",
          profitCenter: "Acitivty",
          createdAtAndEndAt: "Opening/Closing",
          status: "Status",
          amount: "Amt.",
          gdp: "WDA",
          gmd: "ADG",
          diet: "Diet",
          averageWeight: "Avg. Weight",
          target: "Target",
          picketName: "Paddock/Pen",
        },
      },
      drawer: {
        title: "Create Production BATCH",
        titleEdit: "Edit Production BATCH",
        subtitle: "Complete the fields below to create a BATCH",
        subtitleEdit: "Complete the fields below to edit a BATCH",
        form: {
          status: "Status",
          name: "Lot Name*",
          namePlaceHolder: "Lot Name",
          identification: "Import Code",
          identificationPlaceHolder: "Import Code",
          profitCenter: "Activity*",
          profitCenterPlaceHolder: "Select Activity",
          asymptoticWeight: "Asymptotic Weight",
          carcassHarnessing: "Carcass Yield",
          informPlaceHolder: "Enter",
          picket: "Paddock / Pen",
          picketMessage:
            "Select the paddock / pen if you want to allocate this lot",
          picketPlaceHolder: "Select the paddock / pen",
          dietStrategyBaseLine: "Diet Strategy",
          dietStrategyBaseLineMessage:
            "Select the strategy that will be used for feeding the animals added to this lot",
          dietStrategies: "Diet Strategy - Optional",
          dietStrategiesPlaceHolder: "Select one or more diet strategies",
          dietStrategiesTooltip:
            "The selected optional diet strategies will be used later in the purchase and sale scenario simulations",
          checkboxSaveAndAddNewAnimals: "Save and add animals",
          picketId: "Paddock",
          picketLotEntry: "Lot Entry Date",
          breedId: "Breed",
          referenceAcquisitionDate: "Acquisition Date",
          referenceBirthdayDate: "Animal Birth Date",
          dismembermentDate: "Dismemberment Date",
          referenceAcquisitionWeight: "Average Weight per Animal",
        },
      },
      saveOptions: {
        only_save: "Save",
        save_and_create_new: "Save and add a new BATCH",
      },
      addAnimalsPage: {
        listLotProduction: "List: Production BATCHs",
        title: "Add animal to BATCH",
        descriptionMessage:
          "Select the animals in the gray column and move them to the orange column by clicking the button",
        selectPlaceHolder:
          "Select the breed of animals that should make up the BATCH",
        listAnimals: "List of animals",
        productionLot: "Production BATCH",
        modal: {
          message: "Click confirm to add the animals to the BATCH",
          lot: "BATCH",
          profitCenter: "Activity",
          amount: "Amount",
        },
        error: {
          noAnimalsTitle: "Error",
          noAnimalsMessage: "You must select at least one animal",
        },
      },
      dismemberLotPage: {
        listLotProduction: "List: Production BATCHs",
        title: "Split BATCH",
        descriptionMessage:
          "Select the animals in the table and click the 'Split' button to relocate the animals",
        profitCenter: "Activity",
        productionSubSystem: "Production subsystem",
        animalsTotal: "Total Animals",
        animalsSelected: "Selected animals",
        dismemberButton: "Split",
        destinationLots: "Destination BATCHs",
        drawer: {
          title: "Split BATCH",
          description:
            "Select below whether the subdivision will be into a new BATCH or an existing BATCH.",
          newButton: "New",
          existingButton: "Existing",
          alertMessage:
            "The activity of the informed BATCH differs from the current BATCH",
          searchInputPlaceHolder: "Search by BATCH ID",
        },
      },
    },
    receipt: {
      title: "Receiving BATCHs",
      buttonNewLot: "New",
      collapse: {
        animals: "Animals",
        receiptIn: "Received on",
      },
      table: {
        columns: {
          breed: "Breed",
          gender: "Gender",
          birthday: "Age (months)",
          weight: "Estimated Weight",
          amount: "Qtd. animais",
          confirmedAmount: "No  of animals received",
          purchaseCost: "Acquisition cost",
          numbers: "Numbering",
        },
        boitelColumns: {
          identification: "Identification",
          amount: "No  of animals",
          breed: "Breed",
          entryDate: "Entry Date",
          weightRange: "Weight range",
          confirmedAmount: "No  of animals received",
          identifiedAmount: "No  of animals identified",
          status: "Status",
        },
        status: {
          confirmed: "Confirmed",
          pending: "Pending",
        },
      },
      finalize: {
        title: "Creating receiving BATCHs",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        buttonFinalize: "FINALIZE RECEIPT",
        buttonFinalizeCancel: "CANCEL RECEIPT",
        supplier: "Supplier",
        dateReceiving: "RECEIPT DATE",
        animalsReceived: "No  of animals received",
        grid: {
          title: "Receiving BATCH",
          animalsInserted: "animals added",
          buttonAddAnimals: "Add animals",
          columns: {
            breed: "Breed",
            gender: "Gender",
            birthday: "Age (months)",
            weight: "Estimated Weight",
            amount: "Amt.",
            purchaseCost: "Acquisition cost",
            numbers: "Numbering",
          },
          deleteBox: {
            description: "Upon confirmation the BATCH will be deleted",
            buttonConfirm: "Confirm",
            buttonCancel: "Cancel",
          },
        },
        newLotAnimalsReceipt: {
          title: "New animals",
          description: "Fill in the animal reception data",
          saveOptions: {
            only_save: "Save",
            save_and_create_new: "Save and create new receipt",
            save_and_handle: "Save and start husbandry",
          },
        },
        newLotItem: {
          title: "Animals grouping",
          description:
            "Group the animals being received according to their characteristics by completing the information below.",
          tabGeneralTitle: "General",
          tabNumbersTitle: "Numbering",
          breed: "Breed",
          gender: "Gender",
          birthday: "DOB",
          birthdayPlaceHolder: "Select",
          estimatedWeight: "Estimated Weight",
          estimatedWeightPlaceHolder: "Enter the weight (kg)",
          amount: "Amount",
          amoutPlaceHolder: "Enter the amount",
          purchaseCost: "Acquisition cost",
          purchaseCostPlaceHolder: "Enter the acquision cost",
          rangeDescription:
            "Add the number of animals that have the characteristics",
          range: "Ranges",
          numbersInserted: "Entered numbers",
          animalFarmFunction: "Purpose",
          animalReproductionCategory: "Category",
          saveOptions: {
            only_save: "Save",
            create_new: "Save and create new animals receiving",
          },
        },
        errors: {
          noAnimalsAdd: {
            title: "No animals added",
            description:
              "It is necessary to add the informed quantity of animals",
          },
          amountAnimalsInLotItenBiggerThanAmountAnimalsReceveid: {
            title: "Number of animals large than",
            description:
              "The number of animals entered is greater than that stated in the receiving BATCH",
          },
          amountAnimalsInLotItenLessThanAmountAnimalsReceveid: {
            title: "Number of animals less than",
            description:
              "The number of animals entered is less than that stated in the receiving batch",
          },
          amountAnimalsInLotItenBiggerThanAmountReported: {
            title: "Invalid number of animals",
            description:
              "The number of animals entered is greater than that stated in the BATCH quantity",
          },
        },
      },
      supplier: {
        title: "Animal Entry - Purchase",
        buttonNext: "Next",
        buttonBack: "Back",
        titleBox: "Receipt Registration",
        messageError: "Fill in all required fields (*)",
        supplierTitle: "Supplier",
        supplierComboBoxPlaceHolder: "Select",
        lotName: "BATCH name",
        lotNameInputPlaceHolder: "Receiving BATCH name",
        dateTitle: "Entry Date",
        datePlaceHolder: "Select",
        shouldCreateLotEntry: "Post and create animals?",
        buttonToday: "TODAY",
        qtyAnimalsTitle: "Amount of animals",
        qtyAnimalsInputPlaceHolder: "Amount of animals",
        receiptNameTitle: "Receipt number",
        supplierInformationsTitle: "Supplier data",
        documentTitle: "EIN",
        responsibleTitle: "Responsible",
        emailTitle: "E-mail",
        contactTitle: "Contact",
        addressTitle: "Address",
      },
    },
  },
  picket: {
    status: {
      break: "Resting",
      pasturage: "Grazing",
      renovation: "Renovation",
      maintenance: "Maintenance",
      leased: "Leased",
      deleted: "Deleted",
      active: "Active",
      inactive: "Inactive",
    },
    dashboards: {},
    propertyType: {
      own: "Owned",
      foreign: "Rented",
    },
    tabs: {
      picket: "Paddock/Pen",
      retreat: "Range Unit/Row",
    },
    title: "List of Paddocks/Pens",
    buttonNewPicket: "New Paddock/Pen",
    table: {
      columns: {
        picketName: "Paddock/Pen",
        totalAndProductionArea: "Total/Useful Area",
        propertyType: "Type",
        profitCenterName: "Activity",
        lotName: "BATCH",
        status: "Status",
        inAndOutLot: "BATCH entry",
        supportCapacity: "Carrying cap.",
        averageWeight: "Avg. Weight",
        gmd: "ADG",
        amountAnimals: "Amt.",
      },
    },
    pastureTable: {
      title: "Pasture status",
      titleTooltip:
        "The Pasture Status Chart provides different views so that you can monitor and plan the movement of animals between paddocks, in order to optimize pasture use. - DM Stock: Shows the DM availability (%) of the forage used, according to the weather season and consumption of the animals present in the paddock. The recalculation routine is performed daily to update the estimated availability for the next 30 days. - Supply Rate: Shows the Supply and Consumption ratio of Dry Matter (DM) of the forage available in the paddock, showing the number of days that the Batch of animals can remain in the paddock, according to the available DM stock and expected consumption of the animals.",
      columns: {
        picketName: "Paddock",
        status: "Status",
        days: "Accum. Days",
        lotation: "Stocking rate",
      },
      tableType: {
        dryStockOption: "Available DM",
        OfferRateOption: "DM ratio",
      },
    },
    drawerTitle: "Paddock/Pen Registration",
    drawerTitleEdit: "Edit Paddock",
    drawerTitleEditIntensiveSubSystem: "Edit Pen",
    drawerSubTitle: "Complete the fields below to create a new paddock/pen",
    drawerSubTitleEdit: "Complete the fields below to edit a new paddock/pen",
    drawerAllocateLotTitle: "BATCH allocation in the paddock/pen",
    drawerAllocateLotSubTitle: "BATCH allocation in the paddock/pen",
    validationMessage: "Fill in all required fields (*) correctly",
    searchInputPlaceHolder: "Search for the BATCH ID and press (ENTER).",
    alertLotWithProfitCenterDifferent:
      "The Activity of the selected BATCH will be attributed to the Paddock/Pen!",
    detail: {
      name: "Identification",
      productionSubSystem: "Subsystem",
      supplierId: "Supplier",
      supplierDivider: "Supplier",
      sustenanceCapacityDivider: "Carrying Cap.",
      pastureDivider: "FORAGE",
      infrastructureDivider: "INFRASTRUCTURE",
      pasture: "FORAGE",
      pastureDailyCost: "Daily Cost",
      pastureAnimalCapacity: "Stocking density",
      pastureArea: "Useful Area",
      pastureLeafDensity: "Forage density",
      pastureFertilized: "Fertilized",
      pastureCurrentHeight: "Current grass height",
      pastureCurrentHeightDate: "Current grass height date",
      pastureGrowthSpeed: "Growth rate",
      troughSpacePerAnimal: "Trough space per animal",
      totalTroughSpace: "Total Trough size",
      drinkingFountain: "Total water tank size",
      lotId: "BATCH",
      leaseCost: "Leasing cost",
      profitCenterId: "Activity",
      profitCenterType: "Type",
      pastureId: "Forage type",
      moreInformationButton: "More Information",
    },
    form: {
      name: "Paddock/Pen Id",
      namePlaceHolder: "Paddock/Pen Id",
      importCode: "Importing code",
      propertyType: "Is the paddock/pen internal or outsourced?",
      productionSubSystem: "Production subsystem",
      productionSubSystemPlaceHolder: "Select System",
      supplierId: "Supplier",
      supplierIdPlaceHolder: "Select supplyer",
      sustenanceCapacity: "Carrying Cap.",
      alocateLotTitle: "ALLOCATE PRODUCTION BATCH",
      infrastructureDivider: "INFRASTRUCTURE",
      lotId: "BATCH",
      lotIdPlaceHolder: "Select a BATCH",
      lotStartDate: "Current BATCH entry date",
      oldLotEndDate: "Previous BATCH exit date ",
      leaseMonthlyCost: "Leasing cost",
      profitCenterId: "Activity used",
      profitCenterIdPlaceHolder: "Select Activity",
      status: "Paddock status",
      sustenanceCapacityDrawerTitle: "Paddock carrying capacity",
      sustenanceCapacityDrawerTitleIntensiveSubSystem: "Pen carrying capacity",
      drinkingFountain: "Water tank size",
      troughSpacePerAnimal: "Trough size per head",
      totalTroughSpace: "Trough size",
      pastureDivider: "FORAGE",
      pastureId: "FORAGE",
      pastureIdPlaceHolder: "Selecione o tipo de forrageira",
      pastureAnimalCapacity: "Stocking density",
      pastureArea: "Useful Area",
      pastureLeafDensity: "Forage DM Density",
      pastureFertilized: "Fertilized",
      pastureDailyCost: "Daily cost ",
      pastureDailyCostTooltip:
        "When entering a value in this field, it will be used in the Diet Strategy, regardless of the Climate Season. If no value is entered, the Cost registered in Pasture for each Climate Season will be used.",
      pastureCurrentHeight: "Current Pasture Height",
      pastureCurrentHeightDate: "Current Pasture Height Date",
      pastureGrowthSpeed: "Growth rate",
      observation: "Remarks",
      error: {
        requiredPastureId: {
          title: "Invalid Data",
          message:
            "Select the type of forage when the production subsystem is Extensive or Semi-Intensive",
        },
        requiredSupplierId: {
          title: "Invalid Data",
          message: "Select the supplier when the paddock type is Third Party",
        },
        supplementConsumptionMeasureTypeInvalid: {
          title: "Invalid Data",
          message: "Invalid measurement type for selected Consumption Type",
        },
        productionSubSystemDifferentFromPicketProductionSubSystem: {
          title: "Invalid Data",
          message:
            "Cannot allocate this lot because the production subsystem is different from the paddock/pen",
        },
        sustenanceCapacityNotInformed: {
          title: "Invalid Data",
          message: "Carrying Capacity data must be reported",
        },
      },
    },
    saveOptions: {
      only_save: "Save",
      save_and_create_new: "Create paddock/pen and add a new one",
      save_and_add_lot: "Save and add Batch to the paddock/pen",
    },
    drawerMaintenance: {
      title: "Set Paddock/Pen under maintenance",
      subtitle:
        "Indicate in the field below the time that the paddock/pen will be under maintenance",
      labelAmountDays: "Amount of days",
      finishIn: "Finishing on:",
      infoMessage: "You will be notified when the period ends.",
    },
    drawerRenovation: {
      title: "Set Paddock/Pen under maintenance",
      subtitle:
        "Indicate in the field below the time that the paddock/pen will be under maintenance",
      labelAmountDays: "Amount of days",
      finishIn: "Finishing on:",
      infoMessage: "VYou will be notified when the period ends.",
    },
    drawerBreakTitle: "Are you sure you want to set this paddock/pen on Rest?",
    drawerInactivate: "Are you sure you want to deactivate this paddock/pen?",
    drawerReactivate: "Are you sure you want to reactivate this paddock/pen?",
    drawerDelete: "Are you sure you want to delete this paddock/pen?",
  },
  retreat: {
    title: "List of Range units",
    buttonNew: "New Range",
    table: {
      columns: {
        status: "Status",
        retreatName: "Range",
        area: "Area",
      },
      expandedColumns: {
        picketName: "Paddock/Pen",
        area: "Area",
        activity: "Activity",
        status: "Status",
        lot: "BATCH",
      },
    },
  },
  profitCenter: {
    title: "Activities",
    saveOptions: {
      only_save: "Save",
      save_and_create_new: "Create Activity and add a new one.",
    },
    table: {
      title: "List of Activities",
      columns: {
        name: "Name",
        status: "Status",
        type: "Type",
        productionSubSystem: "Production subsystem",
      },
    },
    drawerTitle: "Activities Roster",
    drawerTitleEdit: "Edit Activity",
    drawerSubTitle: "Complete the fields below to create a new Activity",
    drawerSubTitleEdit: "Complete the fields below to edit a new activity",
    validationMessage: "Fill in all required fields (*) correctly",
    modalCheckbox: "Save and add Batch to Paddock/Pen",
    form: {
      name: "Activity Id",
      namePlaceHolder: "Activity name",
      productionSubSystem: "Production subsystem",
      productionSubSystemPlaceHolder: "What is the production subsystem?",
      type: "Type",
      typePlaceHolder: "What is the type of Activity??",
      status: "Activity status",
    },
  },
  farm: {
    title: "Farms",
    table: {
      title: "Farms list",
      columns: {
        name: "Name",
        status: "Status",
      },
    },
    drawerTitle: "Farms Roster",
    drawerTitleEdit: "Edit Farm",
    drawerSubTitle: "Complete the fields below to create a new farm",
    drawerSubTitleEdit: "Complete the fields below to edit a farm",
    validationMessage: "Fill in all required fields (*) correctly",
    form: {
      logo: "Upload your farm's logo",
      confinement: "Confinement?",
      boitel: "Is it Boitel?",
      boitelVisibleToAllSystem:
        "Check the box if you want to appear in the Boitel - Daily list for all BovExo users.",
      checkVisibleForAllSystem:
        "By checking this box, you agree to add this farm to the list of suppliers and customers of this group and allow this farm to be searchable by all Bovexo users.",
      farmSisbov: "ERAS Farm (SISBOV)?",
      farmSisbovInfoTooltip:
        "By marking that the farm is ERAS (Approved Rural Establishments SISBOV), fields and controls related to traceability will be enabled.",
      name: "Farm trade name",
      namePlaceHolder: "Farm trade name",
      group: "Group",
      groupPlaceHolder: "Select the group",
      companyName: "Corporate name",
      companyNamePlaceHolder: "Farm's corporate name",
      document: "CNPJ",
      documentPlaceHolder: "CNPJ",
      zipCode: "Postal Code",
      zipCodePlaceHolder: "Postal Code",
      address: "Address",
      addressPlaceHolder: "Road, Highway, street...",
      city: "City",
      cityPlaceHolder: "City",
      state: "State",
      statePlaceHolder: "State",
      status: "Farm status",
      country: "Country",
      countryPlaceHolder: "Country",
      totalArea: "Total area",
      rentAreaOutSourced: "Area leased to third parties",
      livestockArea: "Grazing area",
      nonProductiveArea: "Non-productive reserve area",
      cultivationArea: "Cultivation area",
      improvementsArea: "Improvement area",
      complementAddress: "Address complement",
      complementAddressPlaceHolder: "Address complement",
      farmOwner: "Owners",
      weatherStation: "Weather Station",
      weatherStationPlaceHolder: "Weather Station",
    },
    saveOptions: {
      only_save: "Save",
      save_and_create_new: "Save Farm and add a new one",
    },
    messages: {
      successSubmit: "Farm successfully created/updated.",
      errorSubmit: "Farm was not created/updated. Please contact support.",
      errorFarmDocumentAlreadyExists:
        "Farm document already exists in our database.",
      errorFarmOwnerDocumentAlreadyExists:
        "Owner document already exists in our database.",
    },
  },
  farmOwner: {
    title: "Farm Owners",
    buttonNew: "New",
    table: {
      columns: {
        code: "Code",
        name: "Name",
        document: "IRS/EIN",
        participation: "% Partic.",
        mainOwner: "Principal ?",
      },
    },
    form: {
      code: "Code",
      name: "Name",
      document: "IRS/EIN",
      participation: "% Partic.",
      mainOwner: "Principal ?",
    },
  },
  supplier: {
    title: "Suppliers",
    types: {
      supplement: "Supplem./Concent.",
      technology: "Technology",
      cattleFarmer: "Livestock",
      others: "Others",
      veterinarySupplement: "Veterinary Inputs",
    },
    table: {
      title: "Suppliers Roster",
      columns: {
        name: "Name",
        type: "Type",
        status: "Status",
        phoneNumber: "Contact",
        email: "Email",
        city_uf: "City/State",
      },
    },
    drawerTitle: "Supplier Registration",
    drawerTitleEdit: "Edit Supplier",
    drawerSubTitle: "Complete the fields below to create a new Supplier",
    drawerSubTitleEdit: "Complete the fields below to edit the Supplier",
    validationMessage: "Fill in all required fields (*) correctly",
    form: {
      name: "Supplier",
      namePlaceHolder: "Supplier Trading Name",
      type: "Type",
      typePlaceHolder: "Supplyer Type",
      phoneNumber: "Contact",
      phoneNumberPlaceHolder: "Phone number",
      document: "IRS/EIN",
      documentPlaceHolder: "IRS or EIN",
      email: "Email",
      emailPlaceHolder: "E-mail",
      zipCode: "ZIP",
      zipCodePlaceHolder: "ZIP",
      address: "Address",
      addressPlaceHolder: "Street, Road...",
      city: "City/County",
      cityPlaceHolder: "City/County",
      state: "Sate",
      statePlaceHolder: "Sate",
      country: "Country",
      countryPlaceHolder: "Country",
      status: "Supplyer status",
      groupFarmVisibility: "Who will use this Supplier?",
      radiogroup: {
        farm: "This farm",
        group: "All farms in the group",
      },
    },
    saveOptions: {
      only_save: "Save",
      save_and_create_new: "Save Supplyer and add a new one",
    },
  },
  animal: {
    buttonNew: "New Animal",
    buttonGenerateReport: "Generate Animal Listing",
    buttonGenerateReportInfoTooltip:
      "Select one or more animal(s) in the table below to generate the report.",
    buttonGenerateReportERA: "Generate Movement Report",
    listingReportTitle: "Animal Listing",
    dashboard: {
      activeAnimals: "Active Animals",
      activeLots: "Active Batches",
      animalsByGender: {
        title: "Amt. per Gender",
        female: "Female",
        male: "Male",
      },
    },
    columns: {
      title: "Animals Roster",
      buttonCompare: "Compare",
      handlingNumber: "Identification",
      picket: "Paddock/Pen",
      lot: "BATCH",
      sisbov: "NAIS",
      tagId: "Electronic Tag Id",
      breed: "Breed",
      gender: "Gender",
      birthdayMonths: "Age",
      weightRanking: "Ranking",
      currentWeight: "Last Weight",
      gdp: "ADG",
      lastDailyWeightDate: "Last Weighing Date",
      target: "Target",
      status: "Status",
      supplier: "Supplier",
      reproductionCategory: "Reprod. Category",
      reproductionStatus: "Reprod. Situation",
      reproductionState: "Reprod. Status",
    },
    details: {
      animalProductionPageLink: "Productive Animals",
      animalPageDescription: "Animal Details",
      emptyMessage: "Animal data not found",
      bovexoId: "Id Bovexo",
      sisbov: "NAIS",
      age: "DOB",
      source: "Supplier",
      farmOwner: "Proprietário",
      castrated: "Castrated",
      tagId: "Electronic Tag Id",
      sex: "Gender",
      male: "Male",
      female: "Female",
      acquisition: "Acquisition",
      handlingNumber: "Identification",
      weight: "Weight",
      current: "Atual",
      location: "Location",
      picket: "Paddock/Pen",
      lot: "BATCH",
      breed: "Breed",
      weightGain: "Weight gain",
      accumulated: "Accumulated",
      lastWeight: "Last Weight",
      forSlaughter: "for Slaughter/Sell",
      expected: "Expected",
      asymptoticWeight: "Sigmoid weight",
      economicReturn: "Economic return",
      currentValue: "Current value",
      accumulatedCost: "Accumulated cost",
      animalFarmFunction: "Purpose",
      animalReproductionCategory: "Category",
      margin: "Margin",
      appropriateCosts: "Appropriated Costs",
      femaleSituation: "Condition",
      femaleSituationTime: "Condition time",
      coverageDate: "Reprod Service Date",
      coverageType: "Reprod Service Type",
      coverageSemenOrMaleIdentification: "Bull",
      weightCoverage: "Weight at Reprod Serv.",
      tab: {
        production: "Production",
        reproduction: "Breeding",
        genetic: "Genetic",
        geneology: "Genealogy",
        evaluation: "Assessment",
        zoonosis: "Zoonosis",
        weighing: "Weighings",
        dailyWeights: "Daily weights",
        weightGains: "Weight gains (ADG)",
      },
      history: {
        title: "Weights log",
        tooltipHistoryWeight:
          "In this option, the weights recorded for the animal and the respective weight gain will be displayed.\n\n 1. Weights: All weights recorded for the animal are displayed, even if more than one weighing occurs on the same day, such as weights from dynamic weighing scales.\n 2. Daily Weights: These are the weights that will actually be considered for the animal, whether for calculating GDP or ADG. If dynamic weighing scales are used, which can record more than one weight on the same day for the same animal, the rule defined in the Weights configuration, available in [Parameters/General], will be considered.\n 3. Weight gains (ADG): refers to ADG, that is, the daily gain between the weighing on this date and the previous one.",
        grid: {
          emptyMessage: "There is no recorded weight history",
          in: "Start",
          out: "End",
          manual: "Manual",
          date: "Date",
          hour: "Time",
          inOut: "S/E",
          weight: "Weight(kg)",
          eventTitle: "Type",
          originEvent: {
            birthday: "DOB",
            purchase: "Acquisition",
            weaning: "Weaning",
            sale: "Sold",
            transfer: "Transfer",
          },
        },
      },
      totalWeight: {
        title: "Total Weight",
        tooltip:
          "Este gráfico compara o último peso do animal com a media dos animais semelhantes no mesmo grupo genético, raça e do mesmo lote",
        graph: {
          currentWeight: "Current Weight",
          lot: "BATCH",
          genetic: "Genetic",
          breed: "Breed",
          weight: "Weight",
          type: "Type",
          estimated: "Expected ED",
        },
      },
      averageDailyGain: {
        title: "Average Daily Gain",
        tooltip:
          "This graph compares the animal's last daily weight gain with the average of similar animals in the same genetic group, breed and from the same batch.",
        graph: {
          currentGain: "Current ADG",
          lot: "BATCH",
          genetic: "Genetic",
          breed: "Breed",
          weight: "Weight",
          type: "Type",
          estimated: "Expected ED",
        },
      },
      dailyWeightGain: {
        title: "EVOLUTION OF WEIGHT AND ADG",
        graph: {
          weight: "Weight",
          time: "Days",
        },
      },
    },
    drawerTitleEdit: "Edit Animal",
    drawerSubTitleEdit: "Fill in the details below to edit an animal",
    drawerSubTitleEditDeathAnimal: "Dead animal, all fields have been disabled",
    drawerAnimalDeathTitle: "Dead Animal",
    animalDeathPopConfirmTitle:
      "Upon confirmation, the animal is considered to be in a state of permanent death.",
    edit: {
      form: {
        nickname: "Name",
        nicknamePlaceHolder: "Name",
        sisbov: "NAIS",
        sisbovPlaceHolder: "NAIS",
        tagId: "Electronic Tag Id",
        tagIdPlaceHolder: "Electronic Tag Id",
        sex: "Gender",
        male: "Male",
        female: "Female",
        acquisition: "Acquisition",
        handlingNumber: "Identification",
        handlingNumberPlaceHolder: "Animal Id.",
        breed: "Breed",
        breedPlaceHolder: "Select breed",
        cap: "Castrated animal?",
        birthday: "DOB",
        birthdayWeight: "Birth weight",
        firstInseminationWeight: "First insemination",
        weaningWeight: "Weaning weight",
        weaningDate: "Weaning Date",
        asymptoticWeight: "Sigmoid weight",
        asymptoticWeightInfoTooltip:
          "Sigmoid weight is the weight that, based on the information that the user/producer has about the history of the Animal or Batch of Animals (considering genetics, previous management, genotype-environment interaction, frame, among other variables), he believes to be the potential weight limit. In other words, above this weight, this animal will hardly gain weight under normal conditions.",
        acquisitionDate: "Acquisition date",
        purchaseCost: "Acquisition price",
        acquisitionEstimatedWeight: "Acquisition weight",
        carcassHarnessing: "Carcass yield",
        animalFarmFunction: "Purpose",
        animalReproductionCategory: "Category",
        sisbovIdentificationDate: "Identif Date",
        sisbovInsertBNDDate: "Date Inclusion NAIS",
        sisbovSlaughterDate: "Slaughter Release Date",
        farmOwner: "Owner",
      },
      weights: "Weights",
      receipt: "Received",
      sale: "Sale",
    },
    drawerAnimalDiscardTitle: "Animal disposal",
    discard: {
      motives: {
        Age: "Age",
        ReproductiveProblems: "Reproductive Issues",
        IllnessesOrPhysicalProblems: "Illnesses or Physical Problems",
        BodyCondition: "Body condition",
        NegativePregnancy: "Negative Pregnancy",
        MaternalAbility: "Maternal Ability",
      },
    },
    carcassHarnessingInfoTooltip:
      "The animal's carcass yiled must be reported if there is an ultrasound carcass analysis. The utilization of the Batch and the Diet Strategy are expectations. When both are present, Animal, Diet, Batch prevail in this order.",
  },
  dietStrategy: {
    new: {
      title: "Creating diet strategy",
      message:
        "Select the total period of the strategy and define the diets and the execution time of each one",
      name: "Diet Strategy name",
      namePlaceHolder: "Diet Strategy name",
      totalPeriod: "Total period",
      year: "Year",
      oneYear: "1 year",
      twoYears: "2 years",
      threeYears: "3 years",
      dietAndExecutionTime: "Diets and execution time",
      dietPlaceHolder: "Select a diet",
      deleteDietPeriod: "Delete diet",
      totalTime: "Total period",
      weeks: "Week(s)",
      buttonAddDiet: "Add Diet",
      drawerTitle: "Diet Strategy summary",
      start: "Start",
      end: "End",
      notification: {
        success: {
          message: "Diet strategy created!",
          description: "The strategy was included in the system.",
        },
        error: {
          dontHaveName: {
            message: "Error",
            description: "You must enter a name for the diet strategy!",
          },
          dontHaveDiet: {
            message: "Error",
            description: "All periods need a selected diet.",
          },
        },
      },
    },
    title: "Diet Strategies List",
    buttonNewDietStrategy: "New Strategy",
    table: {
      status: "Status",
      name: "Name",
      diet: "Diet",
      totalTime: "Total time",
    },
    modal: {
      simple: {
        title: "Divergent Production Subsystem",
        message:
          "The production subsystem selected for this period does not correspond to the subsystem used in the paddock/pen where the batch is allocated. To obtain good performance, it is recommended that both use the same system.",
        confirmButton: "OK, I get it.",
      },
    },
  },
  generalParameters: {
    weatherSeason: {
      title: "Weather Stations",
      editMessage:
        "Define the most suitable weather stations for the location of the farm using the box on the side:",
      table: {
        columnDayMonth: "Days/Month",
        columnNextSeason: "Next Season",
        columnMonthSeason: "Current Season",
      },
    },
    farmDeathReason: {
      buttonNew: "New",
      table: {
        title: "Cause Mortis",
        columns: {
          name: "Name",
          status: "Condition",
        },
      },
    },
    quotas: {
      buttonNew: "New",
      table: {
        title: "Quotas",
        columns: {
          name: "Name",
          type: "Type",
          status: "Condition",
        },
      },
    },
    successParameterUpdated: "Parameters changed successfully!",
    errorParameterUpdated: "Oops... the parameters were not changed",
  },
  cost: {
    tabs: {
      fixedCost: "Fixed Assets Costs",
      infrastructureCost: "Infrastructure and workforce",
      operatingVariableCost: "Operational variables",
      zootechnicalVariableCost: "Zootechnical costs",
      sanityCost: "Animal health",
    },
    form: {
      error:
        'It is necessary to inform the fields "Cost Value"and "Depreciation"in the costs marked in red',
      growth: "Cow-Calf",
      recreateFattenPasture: "R&F - Pasture",
      recreateFattenSemiConfinement: "R&F - Pasture/Feedlot",
      recreateFattenConfinement: "R&F - Feedlot ",
      area: "Area",
      potentialOccupation: "Max Stocking Density",
      staticCapacity: "Carrying Capac.",
      staticCapacityTooltip:
        "The values in this field are calculated by multiplying the Area by the Max Stocking Density.",
      fence: "Fencing",
      costValue: "Cost",
      depreciationValue: "Depreciation",
      total: "Total",
      amountAnimals: "Amt. Animals",
      totalDailyCostPerAnimal: "Daily cost per animal",
      dietCost: "Diet",
      costProtocol: "Custo de Vet Procedure",
    },
    costType: {
      FixedCost: "Fixed Asset Costs",
      InfrastructureCost: "Infrastructure and workforce costs",
      OperatingVariableCost: "Operational variables",
      ZootechnicalVariableCost: "Zootech/Vet Costs",
      sanityCost: "Animal Health costs",
    },
    code: {
      1: "Offices & Buildings",
      2: "Taxes ",
      3: "Insurance",
      4: "Pasture establishment",
      5: "Fencing",
      6: "Corral",
      7: "Dams",
      8: "Machines and Implements",
      9: "Buildings & infra",
      10: "Operational workforce",
      11: "Technical workforce",
      12: "3rds Tech workforce",
      13: "Energy",
      14: "Phone/Internet",
      15: "ERP",
      16: "Accounting office",
      17: "Maintenance of Buildings/Infra",
      18: "Maintenance of Machines/Implem",
      19: "Fuel and Lubricants",
      20: "Pasture maintenance",
      21: "Fertilization",
      22: "Maintenance of Corral/Fences",
      23: "Pasture Leasing",
      24: "Vaccines",
      25: "Vermifuges",
      26: "Medicines",
      27: "Identification",
      28: "Costs with Service Animals and related",
      29: "Other Admin. Costs",
      30: "Vet Procedure cost",
    },
  },
  saleScenario: {
    error: {
      DietStrategyExpired:
        "It was not possible to process the sales scenario because it contains expired diet strategies.",
      InternalError:
        "There was an error processing the scenario. Please contact support.",
    },
  },
  purchaseScenario: {
    error: {
      DietStrategyExpired:
        "The Purchase Scenario could not be processed because the Diet Strategy provided is Expired.",
    },
  },
  movementHistory: {
    title: "Transaction History(s)",
    table: {
      columns: {
        originalLotsNames: "Origin Batches",
        destinationLotsNames: "Destination Batches",
        requestDate: "Movement Date",
        fromDismemberLot: "Splitting?",
        fileName: "File name",
        animalHandlingNumber: "Animal",
        lotOriginName: "Origin Batch",
        lotDestinationName: "Destination Batch",
        createdAtDate: "Registration Date",
      },
    },
  },
  financial: {
    tabs: {
      costing: "Costing",
      cashFlow: "Cash Flow",
      parameters: "Parameters",
      transactions: "Financial Entries",
      animalSell: "Transf/Production Sales",
      resultCenterAnalysis: "CC/PC Analysis",
    },
    costType: {
      Growth: "Cow-Calf",
      RecreateFattenPasture: "Ext - Pasture",
      RecreateFattenSemiConfinement: "Semi- Pasture/Feedlot",
      RecreateFattenConfinement: "Int - Feedlot",
      Agriculture: "Crops",
      Others: "Others",
    },
    transactions: {
      title: "Entries",
      buttonCreateNewFinancialTransaction: "New Posting",
      buttonExportTransactions: "Export records",
      form: {
        title: "New Posting",
        documentNumber: "Invoice/Sales Tax doc number",
        supplierId: "Supplier",
        billingDate: "Invoice Date",
        expirationDate: "Due date",
        mainValueCurrency: "Select currency",
        mainValue: "Gross Value",
        fees: "Interest/Fines Value",
        discount: "Discounts",
        value: "Net value",
        payment: "Payment",
        actions: "Action",
        actionsOptions: {
          launch: "Post",
          launchAndSettle: "Post and Settling",
          settle: "Settling",
        },
        settleDate: "Settling date",
        inCash: "In cash",
        part: "Installments",
        financialNatureId: "Nature",
        financialClassId: "Class",
        costActivityType: "Activity",
        description: "Description",
        resultCenter: "Profit Center",
        resultCenterApportionmentDefault: "Default",
        resultCenterApportionmentCustom: "Redefine",
        transactionType: "Type",
        clientId: "Customer",
        financialProjectId: "Investment Project",
        bonus: "Bonus",
        errors: {
          installmentsNotGeneratedError:
            'It is necessary to generate the installments. To do this, click on the "Installments"option in the "Payment"field.',
        },
      },
      modalInstallments: {
        title: "Create Installments",
        value: "Trx Value",
        expirationDate: "Due date",
        installmentTotal: "No  of Installments",
        buttonCalc: "Calculate",
      },
      table: {
        columns: {
          nature: "Nature",
          class: "Class",
          values: "Values",
          origin: "Origin",
          billingDate: "Invoice Date",
          expirationDate: "Due Date",
          installment: "Installment",
          documentNumber: "Invoice Number",
          value: "Value",
          isPaidPartially: "Part. Paid",
          isSettled: "Status",
          settleDate: "Settling Date",
          financialClassDepreciation: "Allocation",
          valueDepreciation: "Allocation Cost",
          supplierName: "Supplier/Customer",
          costActivityType: "Activity",
          r: "CR",
          financialProjectName: "Projects",
          transactionType: "Type",
          entityName: "Supplier/Customer",
        },
      },
      modalResultCenterApportionment: {
        title: "Allocation by Profit Center",
      },
    },
    cashFlow: {
      title: "Cash Flow",
      buttonDisbursementReport: "Disbursement Report",
      buttonCashFlowReport: "Cash Flow Report",
      buttonCreateBudget: "New Budget",
      filters: {
        status: "Status",
        visualization: "Visualization",
        exibition: "Display",
        harvest: "Harvesting year",
        costType: "Activity",
        financialProjectId: "Investment Project",
      },
      table: {
        columns: {
          nature: "Nature",
          class: "Class",
          values: "Values",
        },
      },
    },
    costing: {
      title: "Costs Table",
      filters: {
        visualization: "Display",
        harvest: "Harvesting year",
        costType: "Activity",
        financialProjectId: "Investment Project",
      },
      table: {
        columns: {
          nature: "Nature",
          class: "Class",
          values: "Values",
        },
      },
    },
    parameters: {
      tabs: {
        parameters: "Parameters",
        costing: "Costing",
        resultCenter: "Profit Center",
        costCenter: "Cost Center",
        financialProject: "Investiment Projects",
      },
      generalFinancialParameters: {
        title: "Parameters",
        parameter4001: "Use currency conversion?",
        parameter4002: "Diet Costs",
        parameter4002Help:
          "Define whether diet costs will be appropriate for animals based on the Diet Strategy or Supply",
        farmConvertions: {
          title: "Currencies",
          buttonNew: "New Currency",
          table: {},
          form: {
            titleNew: "New Currency",
            currency: "Currency",
            standard: "Set as default?",
          },
        },
      },
      costing: {
        title: "Costing Parameters Table",
        table: {
          columns: {
            nature: "Nature",
            stock: "Inventory Control",
            class: "Class",
            depreciation: "Depreciation (Months)",
            shouldCost: "Costing",
            shouldDisbursement: "Disbursement",
            product: "Product",
            costCenter: "Cost Center",
            type: "Type",
          },
        },
      },
      resultCenter: {
        title: "Table of Profit Centers",
        buttonNew: "New",
        form: {
          title: "Profit Centers",
        },
        financialNatureApportionmentForm: {
          title: "Criteria for Allocation of Expenses/Indirect Costs",
        },
      },
      costCenter: {
        title: "Costs Centers Table",
        buttonNew: "New",
        form: {
          title: "Costs Centers",
        },
        apportionmentForm: {
          title: "Cost Centers x Profit Centers",
        },
        modal: {
          title: "Costs Centers",
          table: {
            columns: {
              financialClass: "Class",
            },
          },
        },
      },
    },
    animalSell: {
      title: "Transf/Production Sales",
      buttonNew: "New Transf./Sale",
      buttonExport: "Sales result",
      form: {
        title: "Transfer/Production Sales",
        animalSelectionDescription:
          "Select the animals in the table to proceed with the sale.",
        sellDate: "Departure Date",
        client: "Client",
        financialNatureId: "Financial Nature",
        financialClassId: "Class",
        priceNegotiated: "Negotiated Price",
        weightPerAnimal: "Average Weight/Animal",
        carcassHarnessing: "Carcass Yield",
        documentNumber: "Tax Document No.",
        supplier: "Supplier",
        billingDate: "Billing Date",
        expirationDate: "Due Date",
        payment: "Payment",
        inCash: "In Cash",
        part: "Installments",
        resultCenter: "Result Center",
        resultCenterApportionmentDefault: "Default",
        resultCenterApportionmentCustom: "Redefine",
        totalValue: "Total Billing",
        AVERAGE_COST: "Current Avg. Cost",
        AVERAGE_MARKET_VALUE: "Avg. Market Value",
        MARGIN_VALUE: "Margin on Cost",
        MARGIN_VALUE_INFO_TOOLTIP:
          'The "Negotiated Price" will be the Current Avg. Cost plus the informed margin.',
        INPUT_PRICE: "Informed Value",
        AVERAGE_LAST_WEIGHT: "Avg. Weight Last Weighing",
        AVERAGE_PROGRESSIVE_WEIGHT: "Current Estimated Avg. Weight",
        INPUT_WEIGHT: "Informed Weight",
        animalTableColumns: {
          handlingNumber: "ID",
          lotName: "Lot",
          picketName: "Picket/Bay",
          breedName: "Breed",
          gender: "Gender",
          age: "Age",
          lastDailyWeight: "Last Weight",
          lastDailyWeightDate: "Last Weighing",
          weightSell: "Sale Weight",
          carcassHarnessing: "% Carcass Yield",
          pricePerKilo: "Price/Kg",
          pricePerArroba: "Price/@",
          priceNegotiated: "Sale Price",
        },
      },
      table: {
        columns: {
          origin: "Origin",
          isTransfer: "Transf.",
          documentNumber: "Invoice Number",
          client: "Customer",
          sellDate: "Inv. Date",
          amountAnimais: "No  Animals",
          gender: "Gender",
          totalValue: "Total Amount",
          averageValue: "Value/animal",
          carcassHarnessing: "% Carc. Yield",
          paymentType: "Payment",
          financialProjectName: "Projects",
        },
      },
      transferPanelTable: {
        columns: {
          status: "Status",
          originGroupName: "Origin Group",
          originFarmName: "Origin Farm",
          amountAnimals: "Qty. Animals",
        },
      },
      messages: {
        selectClientText1:
          "Will this animal transfer involve financial compensation (payment) between the parties?",
        selectClientText2:
          "We have detected that this may be an operation of animal transfer between units of the same economic group. BovExo does not manage the tax/legal aspects of the operation, but for the purpose of analyzing operation results, the minimum value that will be recorded, for general purposes, is the accumulated cost of the transacted animals.",
        infoTooltipText1:
          "This value will be calculated according to the type of Negotiated Value, as follows:",
        infoTooltipText2:
          "Negotiated Value per @: Corresponds to the average weight of the animals, converted into @ (i.e., divided by 15). The number of @ is multiplied by the negotiated @ value with the buyer, and then multiplied by the carcass yield. This results in the average value per animal, which is then multiplied by the number of animals, leading to the total billing amount.",
        infoTooltipText3:
          "Negotiated Value per Kg: Corresponds to the average weight of the animals, multiplied by the number of animals, and then multiplied by the reported Kg value, leading to the total billing amount.",
        successSubmitCreate:
          "Your sale is being processed and will be completed soon.",
        errorSubmitCreate:
          "The sale could not be completed. Please contact support.",
        successSubmitUpdate:
          "The update to your sale is being processed and will be completed soon.",
        errorSubmitUpdate:
          "The sale could not be updated. Please contact support.",
        error5032:
          'Attention! This sale cannot be modified because its financial transaction has been "Processed".',
        modalErrorAnimalsWithPreviousEvents:
          "Animals have events after the informed sale date!",
        modalAlertAnimalsInSaleScenarioText1: "Attention!",
        modalAlertAnimalsInSaleScenarioText2:
          "There are selected animals that are associated with Active Sales Scenarios. By confirming the sale, these Sales Scenarios will be reprocessed, generating a new result for the animals that are still active.",
        modalConfirmPriceNegotiatedText1:
          'The value entered in the "Negotiated Price" field is lower than what should be assigned for managerial purposes.',
        modalConfirmPriceNegotiatedText2: "Do you confirm the value?",
        successOnDelete: "Transfer/Sale successfully deleted.",
        errorMessageOnDeleteTransferAlreadyConfirmed:
          "Attention! This sale/transfer has already been confirmed at the Destination farm.",
        errorMessageOnDelete: "Sale was not deleted. Please contact support.",
        modalConfirmFinancialTransactions:
          "Update the Financial Transaction for this Sale?",
        modalConfirmExpirationDate:
          "Attention! The due date is earlier than the billing date.",
      },
    },
    resultCenterAnalysis: {
      filters: {
        resultCenter: "Profit Center",
        costCenter: "Cost Center",
        period: "Period",
        visualization: {
          label: "Visualization",
          costing: "Costing",
          cashFlow: "Cash Flow",
        },
        buttonConfirm: "Process",
      },
      income: {
        title: "Revenue",
        costCenterName: "Cost Center",
        total: "Total Value",
        totalPercentage: "Total (%)",
      },
      expense: {
        title: "Expenses",
        costCenterName: "Cost Center",
        total: "Total Value",
        totalPercentage: "Total (%)",
      },
      total: "Balance",
    },
  },
  costType: {
    AllTypes: "All",
    Growth: "Cow-Calf",
    RecreateFattenPasture: "R&F - Pasture",
    RecreateFattenSemiConfinement: "R&F - Pasture/Feedlot",
    RecreateFattenConfinement: "R&F - Feedlot",
    Agriculture: "Crops",
    Others: "Others",
  },
  buttons: {
    assign: "Assign",
    back: "Back",
    save: "Save",
    cancel: "Cancel",
    confirm: "Confirm",
    login: "Log in",
    buttonNewProfitCenter: "New Activity",
    buttonNewFarm: "New farm",
    buttonNewSupplier: "New Supplier",
    yes: "Yes",
    no: "No",
    putToDeath: "Set as dead",
    allocateLot: "Allocate Batch",
    download: "Download",
    processWeights: "Process imported weighings",
    processAnimals: "Process imported animals",
    processSupplements: "Process supplem./concent.",
    edit: "Edit",
    editing: "Editing",
    addInfo: "Report data",
    delete: "Delete",
    useAsFavorite: "Set as Favorite",
    generate: "Create",
    putToDiscard: "Discard",
    process: "Process",
    accept: "Accept",
    reject: "Reject",
    updateTable: "Update table",
    viewAll: "View all",
  },
  topbar: {
    title: "Administration  ",
    group: {
      title: "Groups",
      subtitle: "Select a Group",
      submenus: {
        farm: "Farm(s)",
        add_farm: "Add Farm",
      },
    },
    question: {
      title: "Questions",
      submenus: {},
    },
    notification: {
      title: "Remarks",
      submenus: {},
      footer: "Display all",
    },
    add: {
      title: "Add",
      submenus: {
        profit_center: "Activity",
        picket: "Paddock/Pen",
        picketSupplementSupply: "New/Edit Feeding",
        user: "User",
        balance: "Weighing Device",
        sell_and_buy: "Acquisition/Sales Scenario",
        animal: "Animal",
        lotProduction: "Production Batch",
        buy: "Acquisition",
        birth: "DOB",
        importAnimalsWeights: "Import weighings",
        importAnimals: "Import animals",
        importSupplements: "Import Supplem./Concent.",
        importFinancialTransactions: "Import financial transactions",
        downloadBovManager: "Dowload BovManager",
      },
    },
    language: {
      title: "Translation",
      submenus: {
        pt_br: "Português - BR",
        en: "English - US",
        es: "Español - ES",
      },
    },
    user: {
      title: "User",
      submenus: {
        edit_profile: "Edit profile",
        change_user: "Change user",
        change_password: "Change password",
        add_new_user: "Add user",
        signout_application: "Log out",
      },
    },
    search: {
      title: "Search",
      submenus: {
        user: "User",
        profit_center: "Activity",
        picket: "Paddock/Pen",
        balance: "Weighing device",
        animal: "Animal",
        buy: "Acquisition",
        birth: "DOB",
      },
    },
  },
  table: {
    menu: {
      edit: "Edit",
      details: "Details",
      delete: "Delete",
      inactivate: "Inactivate",
      activate: "Activate",
      addAnimalsToLotProduction: "Add animals",
      dismemberLotProduction: "Batch splitting",
      allocateLotToPicket: "Allocate Batch to paddock/stall",
      putPicketInMaintenance: "Perform maintenance",
      putPicketInBreak: "Rest",
      putPicketInRenovation: "Renovate",
      setBaseline: "Set as Default",
      movePickets: "Transf Paddocks/Pens",
    },
  },
  error: {
    formError: "Please fill in the required fields (*) correctly.",
    noGroupSelected: "Select a Group from the top menu",
    sisbovInvalidTitle: "Invalid NAIS",
    sisbovInvalidMessage: "The NAIS number must contain at least 15 characters",
  },
  notifications: {
    types: {
      newObject: "New object",
      purchase: "Acquisition",
      sale: "Sale",
      alert: "Alert",
      warning: "Warning",
      other: "Other",
    },
    drawer: {
      title: "Your notifications and alerts",
      buttonVilualizeAll: "Set all as Read",
      buttonLoadOldNotifications: "Upload older ones",
      notificationsNotRead: "Remarks not read",
      tabNotificationTitle: "Notifications",
      tabAlertTitle: "Alerts",
    },
  },
  sessionExpired: {
    title: "Session expired!",
    message:
      "To keep your account secure, we periodically check your presence, please log in again",
    titleAlmostExpired: "Your session will expire soon!",
    messageAlmostExpired:
      "To keep your account secure, we periodically check your presence, don't forget to save what you are doing before your session expires.",
    buttonLogout: "Log out now",
    buttonConfirm: "Ok, I get it",
    toExpire: "to expire...",
  },
  sidebar: {
    home: "Home",
    farm: "Farms",
    profit_center: "Activities",
    picket: "Range Unit/Paddocks",
    lot: "Batches",
    animal: "Animals",
    decision: "Decisions",
    parameters: "Parameters",
    imports: "Importings",
    administration: "Administration",
    suppliers: "Suppliers",
    operations: "Operations",
    breed: "Breeds",
    handling: "Husbandry",
    diet: "Diets",
    pasture: "Grazing areas",
    supplement: "Supplem./Concent.",
    adminAndUser: "Farm's employees",
    userInvite: "Access invitation",
    client: "Customers",
    payment: "Payments",
    supplierManagement: "Suppliers management",
    financial: "Financial Entries",
    report: "Reports",
  },
  languages: {
    pt_br: "PT",
    en: "EN",
    es: "SP",
  },
  status: {
    active: "Active",
    inactive: "Inactive",
    dismembered: "Splitted",
    pendingData: "Missing data",
    informedData: "Reported Data",
    pending: "Pending",
    processing: "Processing",
    completed: "Completed",
    refused: "Refused",
    error: "Error",
    transfer: "Transferred",
    dead: "Dead",
    sold: "Sold",
    processed: "Processed",
  },
  productionSubSystem: {
    semiIntensive: "Semi- Pasture/Feedlot",
    intensive: "Int - Feedlot",
    extensive: "Ext - Pasture",
    SI: "Semi- Pasture/Feedlot",
    I: "Int - Feedlot",
    E: "Ext - Pasture",
  },
  productionSystem: {
    fatten: "Finishing",
    create: "Cow-Calf",
    recreate: "Bakground rearing",
  },
  kpi: {
    capacity: "Occupancy",
    production: "Production",
    cost: "Cost",
    margin: "Margin",
    gdp: "ADG",
    enjoy: "Turnover (%head)",
    carbonFootprint: "Carbon Footprint",
    disbursement: "Disbursement (/head/month)",
    weanCalf: "Tot Weight Weaned Calves / Tot Weight Cows",
    iep: "CIV",
    reproductiveEfficiency: "Reproductive efficiency",
    weanCalfCost: "Cost per Weaned Calf",
    discardedCows: "Disposed Cows",
    assetValue: "Cattle Asset Value",
    incrementalMargin: "Incremental Margin",
    tooltips: {
      tooltipCapacity:
        "The Occupancy is the ratio (in %) of the current number of heads allocated and the total capacity, as defined in the confinement pen registry.",
      tooltipEnjoy:
        "The Turnover rate measures the herd's ability to generate surplus (@ = 30kg BW) in a given space of time.",
      tooltipProduction: "Amount of @ (30 kg BW) produced per hectare/year.",
      tooltipGDP:
        "Weight Daily Average gain, since the animal enters the Farm.",
      tooltipMargin: "Expected profit margin per @ (=30kg BW) produced.",
      tooltipCost: "Production Cost of @ (=30kg BW).",
      tooltipCarbonFootprint:
        "Maximum emission target in Kg of CO₂E per Kg of weight gain.",
      tooltipDisbursement:
        "Disbursement per Head/Month of animals currently in each production subsystem.",
      tooltipAssetValue: "Cattle Asset Value.",
      tooltipIncrementalMargin: "Incremental Margin.",
      tooltipWeanCalf:
        "Indicator of the proportion of the weight of weaned animals in relation to breeding females.",
      tooltipIEP:
        "Calving interval for breeding females that have calved at least twice (Secondiparous)",
      tooltipReproductiveEfficiency:
        "Final Reproductive Efficiency of serviced cows, considering Pregnancy Rate (PR), Birth Rate (BR) and Weaning Rate (WR).",
      tooltipWeanCalfCost:
        "Cost of weaned calf, including all costs incurred by the cow and calf.",
      tooltipDiscardedCows: "% of cows discarded in the period.",
    },
  },
  zipCodeValid: "ZIP found",
  zipCodeInvalid: "ZIP not found",
  defaultSelectPlaceholder: "Select an option",
  defaultMultiSelectPlaceholder: "Select one or more options",
  defaultDatePickerPlaceholder: "Select a date",
  defaultPlaceholder: "Enter a value",
  filters: {
    applyFilter: "Apply Filter",
    cleanFilter: "Clean Filter",
    filterBy: "Filter by",
    filterByOptions: {
      age: "Age",
      gender: "Gender",
      breed: "Breed",
      supplier: "Suppliers",
      farmOwner: "Owners",
    },
    compareBy: "Compare by",
    value: "Value(s)",
    male: "Male",
    female: "Female",
    min: "Min",
    max: "Max",
    equal: "Equal to",
    greaterThan: "Greater than",
    lessThan: "Less than",
    between: "Between",
  },
  groupAndFarmModal: {
    hello: "Hi!",
    groupSelectedLabel: "Select a Group",
    groupSelectedPlaceHolder: "Select a Group ",
    farmSelectedLabel: "Select a Farm",
    farmSelectedPlaceHolder: "Select a Farm",
    buttonAccessFarm: "Access Farm ",
  },
  importAnimalWeights: {
    title: "Import weighing history ",
    message:
      '<p class="message">Download <strong>the template below, fill the spreadsheet columns</strong> with weight values, <strong>and import the file</strong> in the sequence, clicking to process weighings</p>',
    draggerMessage: "Click or drag the file to this area to upload it. ",
  },
  importAnimals: {
    title: "Import animals into the production batch ",
    message:
      '<p class="message">Select a production batch, download <strong>the template below, fill the spreadsheet columns</strong> with the data from the animals to import, <strong>execute importing</strong> and click to process animals</p>',
    messageForDifferentsLots:
      '<p class="message">Download <strong>the template below, fill the spreadsheet columns</strong> with both animal and batch related data, <strong>execute import</strong> and click to process animals</p>',
    messageForIdentifyAnimals:
      '<p class="message">Download<strong>the template below, fill the spreadsheet columns</strong> with data related to both animals, production batches and receiving batches, <strong>execute importing</strong> and click to process animals</p>',
    draggerMessage: "Click or drag the file to this area to upload it. ",
    helpMessage:
      'Import animals by batch and supplier: Enter the batch and supplier for each animal in the spreadsheet. This way, it is possible to import animals from different suppliers and for more than one production batch. The supplier and production batch must be registered in advance, and the "Batch Id"field must be used in the spreadsheet for the production batch, while for the supplier, enter the "IRS/EIN"from registration. \nImport animals for a single batch and supplier: Select a production batch and a supplier already registered. If the animal to be imported is from the farm itself, select the "Animal(s) supplied by the farm itself"option.',
  },
  error404: {
    title: "ERROR 404",
    subTitle: "Page not found",
    button: "Go to Home",
  },
  months: {
    january: "January",
    jan: "Jan",
    february: "February",
    feb: "Feb",
    march: "March",
    mar: "Mar",
    april: "April",
    apr: "Apr",
    may: "May",
    mmay: "May",
    june: "June",
    jun: "Jun",
    july: "July",
    jul: "Jul",
    august: "August",
    aug: "Aug",
    september: "September",
    sep: "Sep",
    october: "October",
    oct: "Oct",
    november: "November",
    nov: "Nov",
    december: "December",
    dec: "Dec",
  },
  notInformed: "Not informed",
  Profile: {
    drawerTitle: "Personal Data ",
    form: {
      fullName: "Full name",
    },
  },
  scenarePage: {
    title: "Boitel Scenarios",
    table: {
      column: {
        clientID: "Client ID",
        name: "Scenario name",
        createdAt: "Date Created ",
        updatedAt: "Last Update Date",
        status: "Status",
        labelStatus: {
          hired: "Contracted",
          saved: "Saved",
        },
      },
      detailsRow: {
        rangeWeight: "Weight range",
        amountAnimals: "No of Animals ",
        entryDate: "Entry Date ",
        exitDay: "Departure Date ",
        daysOfStay: "No of Daily Rates ",
        dailyPrice: "Daily Price",
        dietStrategy: "Diet",
        carcassHarnessing: "Carcass Yield - %",
        totalIncome: "Total Revenue",
        incomeForCab: "Revenue/head",
        marginForCab: "Margin/head",
      },
    },
  },
  boitelSaleScenario: {
    title: "Sale Scenarios",
    result: "Result(s)",
    btn: {
      newScenare: "New Scenario",
    },
    table: {
      column: {
        scenare: "Scenario",
        client: "Customer",
        favorite: "Favorite",
        status: "Status",
        actions: "Actions",
        labelStatus: {
          analyzing: "Evaluating",
          processed: "Processed",
          canceled: "Canceled",
          partialCanceled: "Part. Canceled",
          sold: "Sold",
          partialSold: "Part. Sold",
        },
      },
      detailsRow: {
        identification: "Identification",
        saleAnimals: "Animals for sale ",
        receive: "To receive",
        margin: "Margin",
        processedAt: "Processed at ",
      },
    },
    new: {
      title: "Creating: Boitel Sale",
      tabs: {
        lots: {
          title: "Batches selection",
        },
        dietStrategy: {
          title: "Diet strategy selection",
        },
        parameters: {
          title: "Parameters definition",
          form: {
            name: "Scenario name*",
            animalsInTruck: "Animals per truck*",
            minimumAge: "Min age for sale*",
            maximumAge: "Max age for sale*",
            minimumWeight: "Min weight for sale*",
            maximumWeight: "Max weight for sale*",
            useDefaultMaximumWeight: "Max weight for sale*",
            allowSeparateSell: "Can Batches be splitted?",
            goalType: "Scenario goal*",
            commitments: "Is there any commitment for the scenario?",
            newCommitment: "Add a new commitment",
            marketAnalysisId: "Market analysis*",
            useCustomCarcassHarnessing: "Enter new % Carcass Yield?",
            useCustomCarcassHarnessingHelp:
              "By default, the system uses the Carcass Yield % informed in the animal acquisition process and batch formation.",
            carcassHarnessing: "Carcass Yield",
          },
        },
      },
    },
    edit: {
      title: "Editing: Boitel Sale",
    },
    details: {
      messageSelectDates:
        "Select a suggested date(s) from the calendar to view animal information.",
      calendarSuggested: "Suggested calendar",
      weekDateNull: "Animals with violation Boitel ",
      animalsToSell: "Animals for sale ",
      planningIncome: "Estimated Revenue",
      dietStrategiesSuggested: "Suggested diet strategy",
      commitments: "Commitments",
      violations: "Violations",
      parameters: "Scenario Parameters",
      marketValues: "Reference values ",
      violationAge: "Age",
      violationWeight: "Weight",
      violationBoitel: "Boitel",
      violationMinAge: "Min age",
      violationMaxAge: "Max age",
      violationMinWeight: "Min weight",
      violationMaxWeight: "Max weight",
      table: {
        columns: {
          animalHandlingNumber: "Identification",
          sellDate: "Suggested date",
          weight: "Estim. weight",
          lastDailyWeight: "Last Weight",
          averageWeightGain: "ADG",
          aggregateArroba: "Net added @",
          saleIncome: "Revenue",
          aggregateIncome: "Added Revenue",
          cost: "Cost",
          aggregateMargin: "Added Margin",
          margin: "Margin",
        },
      },
    },
  },
  importSupplements: {
    title: "Import supplements ",
    message:
      '<p class="message">Download <strong>the template below, fill the spreadsheet columns</strong> with supplements data, <strong> import it</strongand click to process supplements</p>',
    draggerMessage: "Click or drag the file to this area to upload it. ",
  },
  eventLog: {
    fromValueLabel: "Modified from",
    toValueLabel: "to",
    dateLabel: "Date",
    attributeLabel: "Property",
    userLabel: "User",
    nullLabel: "empty",
    Supplement: {
      createdAt: "Creating register: ",
      name: "Name",
      type: "Type",
      classification: "Classification",
      industrialName: "Brand name",
      status: "Condition",
      cost: "Cost",
      cp: "CP",
      tdn: "TDN",
      npn: "NNP",
      dryMatterPercentage: "DM",
      nitrogen: "Nitrogen",
      calcium: "Calcium",
      phosphorus: "Phosphorus",
      zinc: "Zinc",
      sodium: "Sodium",
      minimumVoluntaryConsumption: "Voluntary feed(Min)",
      maximumVoluntaryConsumption: "Voluntary feed(Max)",
      voluntaryConsumptionType: "Unit",
      minimumPure: "Pure product offered (Min)",
      maximumPure: "Pure product offered (Max)",
      minimumPureTrough: "Recom. Trough size (Min)",
      maximumPureTrough: "Recom. Trough size (Max)",
      pureConsumptionType: "Unit",
      minimumMix: "Mixed Product Offered (Min)",
      maximumMix: "Mixed Product Offered (Max)",
      minimumMixTrough: "Recom. Trough size (Min)",
      maximumMixTrough: "Recom. Trough size (Max)",
      mixConsumptionType: "Unit",
      currentVersion: "Version",
      proportion: "Proportion",
      additiveEffect: "Additive Effect",
      additiveEffectUnit: "Unidade Efeito Aditivo",
      fda: "ADF",
      lignin: "Lignin",
      ee: "Ethereal Extract",
    },
  },
  reproductionParameters: {
    title: "Reproduction Parameters ",
    parameters: {
      IDADE_INICIO_FASE_REPRODUTIVA: "Min age for breeding*",
      PERIODO_DESCANSO_POS_PARTO: "Postpartum rest period *",
      TEMPO_APOS_COBERTURA_PARA_DG:
        "Post-service Time for Pregnancy Diagnosis *",
      TOURO_COBERTURAS_DESCONHECIDAS: "Servicing Bull unknown*",
      IDADE_DESMAMA: "Weaning age*",
    },
    reproductionPeriod: {
      title: "Breeding Seasons",
      titleHelper:
        "The Breeding Season is the property's reproductive period, starting on D0 of the first Insemination protocol/Service for the batch and ending with the Final Gestation Diagnosis.",
    },
    score: {
      title: "Reproductive Success Probability Score ",
      titleHelper:
        "Define, based on the score scale of 1 to 9, the probability in % of reproductive success. This score will be applied in the table to generate the Age X Weight X Body Score Curve.",
    },
    breedScore: {
      title: "Age X Weight X Body Score Curve ",
      titleHelper:
        "Define for the Breeding Females, considering Age, Current Weight and Reproduction Success Probability Score, the variables necessary to calculate the cost to, until the next Breeding Season, reach each body score.",
      form: {
        title: "New entry (Breed X Age X Weight X Score)",
        breedId: "Breed*",
        age: "Age*",
        startWeight: "Starting weight*",
        endWeight: "Ending weight*",
        weight: "Weight",
        score: "Score*",
        errors: {
          errorWithFieldWeights:
            ' There are some weights without "Score". Fill them in to save the form.',
        },
      },
    },
  },
  semen: {
    title: "Semen Listing ",
    buttonNewSemen: "New Semen",
    table: {
      columns: {
        name: "Name",
        registration: "Register",
        breedName: "Breed",
      },
    },
    form: {
      titleCreateNewSemen: "New Semen",
      titleEditSemen: "Edit Semen",
      name: "Name",
      registration: "Register",
      breedId: "Breed",
    },
  },
  movimentAnimals: {
    title: "Transfers between Batches",
    description:
      ' Select animals in the table and click the "Transfer"button to relocate the animals ',
    buttonMovementAnimals: "Transfer",
    form: {
      title: "New Transfer - Destination Batch ",
    },
  },
  closedSalesReport: {
    form: {
      title: "Sales Results",
      salesPeriod: "Sales Period",
      clientId: "Customer",
      supplierId: "Supplier",
      saleScenarioId: "Sales Scenario(s)",
      animalGender: "Gender",
    },
  },
  exportFinancialTransactions: {
    modal: {
      title: "Export financial transactions ",
      billingPeriod: "Invoicing ",
      expirationPeriod: "Maturity ",
      suppliers: "Supplier(s)",
      costActivities: "Activity(ies)",
      clients: "Customer(s)",
      transactionType: "Type",
      financialProjectId: "Investment Project",
      status: "Status",
    },
  },
  unit: {
    title: "Unit Listing ",
    buttonNew: "New Unit",
    table: {
      columns: {
        name: "Abbreviation",
        description: "Description",
        basicUnit: "Basic Unit",
        multiplier: "Multiplier",
        status: "Status",
        createdAt: "Date Created",
        createdBy: "Responsible for Creation",
      },
    },
    form: {
      titleCreateNew: "New Unit",
      titleEdit: "Edit Unit",
      groupFarmVisibility: "Who will use the Unit?",
      name: "Abbreviation",
      description: "Description",
      basicUnit: "Basic Unit",
      multiplier: "Multiplier",
      status: "Status",
    },
  },
  veterinary: {
    title: "List of Veterinary Supplies ",
    buttonNew: "New veterinary input",
    table: {
      columns: {
        name: "Brand name",
        type: "Type",
        supplierId: "Supplier",
        supplierName: "Supplier",
        status: "Status",
        createdAt: "Date Created",
        createdBy: "Responsible for Creation",
      },
    },
    form: {
      titleCreateNew: "New veterinary input",
      titleEdit: "Edit veterinary input",
      groupFarmVisibility: "Who will use the veterinary input?",
      name: "Brand name",
      type: "Type",
      supplierId: "Supplier",
      applicationGender: "Gender",
      applicationAge: "Age",
      applicationType: "Administration route",
      applicationValue: "Administration amt.",
      applicationWeightValueParam: "Weight",
      unitId: "Unit used",
      applicationCost: "Administration cost",
      status: "Status",
    },
  },
  handlingReproduction: {
    title: "Husbandry",
    tabs: {
      reproductiveProtocol: "Protocols",
      coverage: "Reproduction service",
      pregnancyDiagnosis: "Pregnancy Diagnosis ",
      animalBirth: "Births and Losses ",
    },
  },
  handlingReproductionCoverage: {
    title: "Reprod Service Listing",
    buttonHandlingReproductionCoverage: "New servicing",
    type: {
      iatf: "AI/FTAI",
      repasse: "FS/Repeat",
    },
    table: {
      columns: {
        animalHandlingNumber: "Female",
        coverageDate: "Date",
        type: "Type",
        maleHandlingNumber: "Bull",
        semenNameAndRegistration: "Semen",
        semenOrMaleIdentification: "Bull/Semen",
        lotName: "BATCH",
        dose: "Dosage",
        reproductionPeriodDescription: "Breeding Season",
        inseminatorName: "AI Technician",
        pregnancyDiagnosisResult: "Result",
      },
    },
    form: {
      type: "Type",
      animalId: "Identification",
      category: "Category",
      semenId: "Semen",
      maleId: "Bull",
      dose: "Dosage",
      inseminatorId: "AI Technician",
      coverageDate: "Service Date",
      reproductionPeriodId: "Breeding Season",
    },
    errorsAndMessages: {
      successMessage: "Service saved/updated successfully!",
      femaleWithoutReproductionAge:
        "Female is not old enough to start the reproductive phase, do you want to continue?",
      femaleDontHaveAgeToCoverage:
        "Attention! Female is not old enough for this service.",
      reproductionPeriodWithoutCurrentDate:
        "Attention! It is necessary to have a Breeding Season registered during the service period.",
      errorFemaleWithFutureCoverages:
        "Attention! Female already has service posted on later dates.",
      errorFemaleWithCoverageInSameDateAndType:
        "Attention! Service already registered previously.",
      errorInternalError:
        "Error registering/updating service. Contact support.",
    },
  },
  inseminator: {
    title: "AI Technicians Listing",
    buttonNewInseminator: "New AI Technician",
    table: {
      columns: {
        name: "Name",
        code: "Code ID",
        status: "Status",
      },
    },
    form: {
      titleCreateNewInseminator: "Novo AI Technician",
      titleEditInseminator: "Editar AI Technician",
      name: "Name",
      code: "Code ID",
    },
  },
  multipleBull: {
    title: "Multiple Bulls",
    buttonNewMultipleBull: "New Multiple Bull",
    table: {
      columns: {
        name: "Name",
        code: "Code ID",
        males: "Bulls",
        formationDate: "Grouping Date",
        status: "Status",
      },
    },
    form: {
      titleCreateNewMultipleBull: "New Multiple Bull",
      titleEditMultipleBull: "Edit Multiple Bull",
      name: "Name",
      code: "Code ID",
      males: "Bulls",
      formationDate: "Grouping Date",
      status: "Status",
    },
  },
  ovarianResult: {
    SemAvaliacao: "No Evaluation",
    CorpoLuteo: "corpus luteum ",
    Foliculo: "Follicular",
    Cisto: "Ovarian cysts ",
    Anestro: "Anestrus ",
    Normal: "Normal",
    Reabsorvendo: "Resorption",
    Gemelar: "Twin condition",
  },
  animalPregnancyDiagnosis: {
    title: "Pregnancy Diagnosis",
    buttonNew: "New Pregnancy Diagnosis",
    table: {
      columns: {
        animalHandlingNumber: "Female",
        lotOriginalName: "BATCH",
        diagnosisDate: "PD Date",
        result: "Result",
        ovarianEvaluationResult: "Ovarian Evaluation",
      },
    },
    form: {
      titleCreateNew: "New Pregnancy Diagnosis",
      titleEdit: "Edit Pregnancy Diagnosis",
      diagnosisDate: "Diagnostic Date",
      animalId: "Identification",
      animalReproductionCategory: "Category",
      femaleSituation: "Condition",
      lastCoverage: "Last service",
      lotName: "Current Batch",
      result: "Result",
      ovarianEvaluationResult: "Ovarian Evaluation",
      lastCoverageReproductionPeriodId: "Breeding Season",
      lotDestination: "Destination Batch",
      timeGestationMC: "Gestation period MC",
      gestationDays: "Gestation period",
      birthForecast: "Birth Forecast",
      shouldDiscardFemale: "Discard Female?",
      discardMotive: "Cause",
    },
  },
  animalWeightHistory: {
    title: "Weighing List ",
    buttonExport: "Weighing Analysis",
    buttonNew: "New Weighing ",
    table: {
      columns: {
        origination: "Origin",
        handlingNumber: "Animal",
        date: "Date",
        weight: "Weight",
        originationLotName: "BATCH",
        originationPicketName: "Paddock",
        event: "Type",
      },
    },
    originEvents: {
      birthday: "DOB",
      sale: "Sale",
      weaning: "Weaning",
      purchase: "Acquisition",
    },
    form: {
      titleCreateNew: "Add Weighing",
      titleEdit: "Edit Weighing ",
      animalId: "Animal Id",
      date: "Weighing Date",
      weight: "Weight",
      gmd: "ADG",
      lotOriginalId: "Current Batch",
      lotDestinationId: "Destination Batch",
      lastDateWeight: "Last Weighing Date",
      lastWeight: "Last Weighing",
      weaningWeight: "Weaned?",
      editIdentifications: "Edit Identifications?",
    },
    modalExportReport: {
      title: "Weighing Analysis",
      periodDate: "Weighing Period",
      gender: "Gender",
      status: "Animals status",
      periodAge: "Idade(meses)",
      lotId: "BATCH",
      supplierId: "Supplier",
      listAnimalsWithoutWeight: "List animals without weighing in the period?",
      showProjectedWeight:
        "Display the weight projected by the Diet Strategy on the current date?",
    },
  },
  dailyLiveCattlesDataAgro: {
    codes: {
      D_PEPR_GO_BR: "@ GO",
      D_PEPR_MG_BR: "@ MG",
      D_PEPR_MT_BR: "@ MT",
      D_PEPR_MS_BR: "@ MS",
      D_PEPR_SP_BR: "@ SP",
      D_PEPR_RO_BR: "@ RO",
      D_PEPR_TO_BR: "@ TO",
      D_PEPR_PA_BR: "@ PA",
      D_PEPR_BA_BR: "@ BA",
    },
  },
  animalBirth: {
    title: "List of Births and Reproductive Losses",
    buttonNew: "New Birth/Loss",
    table: {
      columns: {
        matrizAnimalHandlingNumber: "Breeding cow",
        birthDate: "Date of delivery",
        type: "Type",
        coverageDate: "Serv. Date",
        coverageType: "Serv. Type",
        reproductor: "Bull",
        childs: {
          handlingNumber: "Identification",
          gender: "Gender",
          birthdayWeight: "Birth weight",
          situation: "Condition",
        },
      },
    },
    types: {
      parto: "Birth",
      aborto: "Abortion",
      morteEmbrionaria: "Embryonic Death",
    },
    form: {
      titleCreateNew: "New Birth/Loss",
      titleEdit: "Edit Birth/Loss",
      matrizAnimalId: "Identification",
      type: "Type",
      birthDate: "Date",
      animalReproductionCategory: "Category",
      femaleSituation: "Condition",
      gestationDays: "Gestation period",
      coverageInfoType: "Type",
      coverageInfoDate: "Date",
      coverageInfoGestationTime: "Gestation period",
      coverageInfoBirthForecast: "Birth Forecast",
      coverageInfoBull: "Bull",
      coverageInfoDGDate: "PD Date",
      coverageInfoDGResult: "PD Result",
      shouldDiscardFemale: "Discard Female?",
      discardMotive: "Cause",
      lotMatrizDestinationId: "Production Batch",
      observation: "Remarks",
      child: {
        nickname: "Name",
        nicknamePlaceHolder: "Name",
        sisbov: "NAIS",
        sisbovPlaceHolder: "NAIS",
        tagId: "Electronic Tag Id",
        tagIdPlaceHolder: "Electronic Tag Id",
        sex: "Gender",
        male: "Male",
        female: "Female",
        acquisition: "Acquisition",
        handlingNumber: "Identification",
        handlingNumberPlaceHolder: "Animal Id.",
        breed: "Breed",
        breedPlaceHolder: "Select a breed",
        cap: "Castrated animal?",
        birthday: "DOB",
        birthdayWeight: "Birth weight",
        firstInseminationWeight: "First insemination",
        weaningWeight: "Weaning weight",
        weaningDate: "Weaning Date",
        asymptoticWeight: "Sigmoid weight",
        asymptoticWeightInfoTooltip:
          "Sigmoid weight is the weight that, based on the information that the user/producer has about the history of the Animal or Batch of Animals (considering genetics, previous management, genotype-environment interaction, frame, among other variables), he believes to be the potential weight limit. In other words, above this weight, this animal will hardly gain weight under normal conditions.",
        acquisitionDate: "Acquisition date",
        acquisitionEstimatedWeight: "Acquisition weight",
        carcassHarnessing: "Carcass yield",
        animalFarmFunction: "Purpose",
        animalReproductionCategory: "Category",
        sisbovIdentificationDate: "Identif Date",
        sisbovInsertBNDDate: "Date Inclusion NAIS",
        sisbovSlaughterDate: "Slaughter Release Date",
        lotId: "Production Batch",
        farmOwner: "Owner",
        purchaseCost: "Acq. value",
        stillborn: "Stillborn?",
      },
    },
  },
  animalReproductionState: {
    solteira: "Non-pregnant cow",
    parida: "Calved cow",
  },
  animalReproductionCategory: {
    nulipara: "Nulliparous",
    novilha: "Heifer",
    primipara: "Primiparous",
    secundipara: "Secundiparous",
    multipara: "Multiparous",
    Bull: "Bull",
  },
  femaleSituation: {
    vazia: "Empty",
    prenha: "Pregnant",
    servida: "Serviced",
  },
  animalFarmFunction: {
    reproduction: "Reproduction",
    fatten: "Fattening",
  },
  birthTypes: {
    parto: "Birth",
    aborto: "Abortion",
    morteEmbrionaria: "Embryonic Death",
  },
  animalStatus: {
    A: "Active",
    Active: "Active",
    S: "Sold",
    Sold: "Sold",
    D: "Dead",
    Dead: "Dead",
    I: "Inactive",
    Inactive: "Inactive",
  },
  animalMovementEvents: {
    weight: "Weighing",
    vacination: "Vaccination",
    coverage: "Service",
    pregnancyDiagnosis: "Pregnancy Diagnosis",
    birth: "Birth",
    discard: "Discard",
    lotEntry: "Entry to the Lot",
  },
  financialClass: {
    buttonNew: "New Class",
    form: {
      titleCreateNew: "New Class",
      titleEdit: "Edit Class",
      financialNatureId: "Nature",
      name: "Name",
      shouldCost: "Considered for Costing?",
      shouldDisbursement: "Considered for Desimbursement?",
      depreciation: "Depreciation",
      costCenterName: "Cost Center",
    },
  },
  costCenterDefaultNames: {
    PRODUCAO_ANIMAL: "Animal Production",
    UTILIDADES: "Utilities",
    INFRAEST_PRODUTIVA: "Product Infrast.",
    INFRAEST_OPERACIONAL: "Operat. Infrast.",
    INFRAEST_APOIO: "Service Infrast.",
    TAXA_IMPOSTOS: "Fees & Taxes",
    SERVICOS: "Services",
    INVSUMOS_OPERACIONAIS: "Oper. Inputs",
    PRODUCAO_PASTO_AGRICOLA: "Crop/Pasture Prod.",
  },
  financialProject: {
    title: "Investiment Projects",
    buttonNew: "New Project",
    table: {
      columns: {
        name: "Project name",
        description: "Remarks",
        startProjectDate: "Start Date",
        finishProjectDate: "End Date",
        status: "Status",
      },
    },
    form: {
      titleCreateNew: "New Investment Project",
      titleEdit: "Edit Investment Project",
      name: "Project name",
      description: "Remarks",
      startProjectDate: "Start Date",
      finishProjectDate: "End Date",
      status: "Active?",
    },
  },
  animalGender: {
    Female: "Female",
    Male: "Male",
  },
  lotItemEntry: {
    form: {
      entryDate: "Entry Date",
      amountAnimals: "Amount",
      averageWeight: "Avg. Weight",
      animalFarmFunction: "Purpose",
      animalReproductionCategory: "Category",
      ageInDays: "Age (days/months/years)",
      prefixIdentification: "Prefix for Identification ",
      lotDestinationId: "Destination Batch",
    },
  },
  picketSupplementSupply: {
    title: "Feed delivering",
    buttonNew: "Feed delivering report",
    table: {
      columns: {
        supplyDate: "Date",
        picketsCount: "Paddocks/Pens",
        totalSupplementSupplied: "Tot Delivered(Kg)",
        responsibleName: "Responsible",
        subTable: {
          retreatName: "Range Unit/Row",
          picketName: "Paddocks/Pens",
          lotName: "BATCH",
          totalAnimals: "Amt. Animals",
          supplementName: "Supplem./Concent.",
          weightedAverageDryMatter: "%DM",
          totalSupplementSupplied: "Amt. Delivered",
          productionSubSystem: "Production subsystem",
          subTable: {
            supplyNumber: "Delivery run N°",
            supplementName: "Supplem./Concent.",
            dryMatterPercentage: "%DM",
            quantitySupplied: "Amt. Delivered",
            responsibleName: "Responsible",
            version: "Version",
            prevSupplyHourInMinutes: "Planned Time",
            supplyHourInMinutes: "Actual Time",
            readFeederValue: "Food Intake Assessment",
          },
        },
      },
    },
    form: {
      titleCreateNew: "New/Edit Food Delivering",
      titleEdit: "Edit Delivering",
      supplyDate: "Date",
      supplyNumber: "Delivery run N°",
      responsibleName: "Responsible",
      retreats: "Range Unit/Row",
      pickets: "Paddocks/Pens",
      lots: "Batches",
      subSystems: "Production subsystem",
      quantitySupplied: "Amt. Delivered",
      supplement: "Supplem./Concent.",
      hourSupplied: "Delivery Time",
      saveOptions: {
        only_save: "Save",
        save_and_create_new: "Save and create new delivery",
      },
      tableSupplies: {
        picketName: "Picket/Bay",
        lotName: "Lot",
        animalsCount: "Animal Qty",
        avgWeight: "Estimated Weight",
        avgGdp: "Estimated GDP",
        supplementId: "Supplement/Feed",
        dryMatterPercentage: "DM: Dry Matter",
        quantitySupplied: "Qty Supplied",
        prevSuppliedPerAnimal: "Prev. per animal/day",
        prevSuppliedDryMatterByWeight: "Prev. DM %BW",
        prevSupplyHourInMinutes: "Scheduled Time",
        supplyHourInMinutes: "Actual Time",
        observation: "Observation",
      },
    },
  },
  partialPayment: {
    title: "Create Partial Payment ",
    form: {
      valuePartialPayment: "Partial Payment Amt.",
      settleDate: "Settling date",
      expirationDate: "New Due Date",
      valuePendingPayment: "Due Payment Amt.",
      fees: "Interest/Fines Amount ",
    },
  },
  financialDashboard: {
    resultCenter: "Profit Center",
    costCenter: "Cost Center",
    costType: "Activity",
    period: "Period",
    financialProject: "Inv. Project",
    bankAccount: "Banking acct.",
    buttonConfirm: "Process",
    periods: {
      lastSevenDays: "Last 7 days",
      lastThirdyDays: "Last 30 days",
      currentMonth: "Current Month",
      lastMonth: "Last Month",
      currentYear: "Current Year",
      lastYear: "Last Year",
      currentHarvest: "Current Harvesting Year",
      lastHarvest: "Last Harvesting Year",
      customDateRange: "Specify Date ",
    },
  },
  reproductiveProtocol: {
    title: "Reproductive Protocols ",
    buttonNew: "New Protocol",
    table: {
      columns: {
        status: "Status",
        name: "Name",
        duration: "Duration",
        createdAt: "Date Created",
        createdBy: "Responsible for Creation",
        applicationDays: {
          orderApplication: "Order",
          dayApplication: "Day?",
          product: "Product/Hormone ",
        },
      },
    },
    form: {
      titleCreateNew: "New Protocol",
      titleEdit: "Edit Protocol",
      groupFarmVisibility: "Who will use this Protocol?",
      name: "Generic name",
      duration: "Protocolo duration",
      newApplicationDay: "Row",
      observations: "Remarks",
      applicationDays: {
        orderApplication: "Order",
        dayApplication: "Day?",
        product: "Product/Hormone ",
      },
    },
  },
  animalFarmReproductiveProtocol: {
    title: "Posting Protocols",
    buttonNew: "Protocol Execution",
    table: {
      columns: {
        handlingNumber: "Female",
        dateApplication: "Date",
        reproductiveProtocolName: "Protocol",
        reproductionPeriodDescription: "Breeding Season",
        lotOriginName: "Batch of Origin",
        lotDestinationName: "Destination Batch",
      },
    },
    form: {
      titleCreateNew: "Apply Protocol",
      titleEdit: "Edit Protocol",
      buttonApply: "Apply",
      applyForAnimalsGroupForm: {
        dateApplication: "D0 Date",
        reproductiveProtocolId: "Protocol",
        reproductionPeriodId: "Breeding Season",
        lotDestinationId: "Destination Batch",
      },
      groupTitle: "Executions",
      animalsTable: {
        handlingNumber: "Identification",
        lotName: "BATCH",
        breedName: "Breed",
        age: "Age",
        femaleSituation: "Condition",
        animalFarmFunction: "Purpose",
        animalReproductionState: "Condition",
        animalReproductionCategory: "Category",
        lastBirthDate: "Last Birth Date",
      },
    },
  },
  currency: {
    AED: "Dirham - Emirated",
    AFN: "Afghani - Afghanistan",
    ALL: "Lek - Albany",
    AMD: "Dram - Armenia",
    ANG: "Guilder - Netherlands Antillean ",
    AOA: "Kwanza - Angola",
    ARS: "Peso - Argentina",
    AUD: "Dollar - Australia",
    AZN: "Manat - Azerbaijan ",
    BAM: "Mark - Bosnia and Herzegovina ",
    BBD: "Dolar - Barbados",
    BDT: "Taka - Bangladesh",
    BGN: "Lev - Bulgary",
    BHD: "Dinar - Bahrein",
    BIF: "Franco - Burundi",
    BND: "Dolar - Brunei",
    BOB: "Bolivar - Bolivia",
    BRL: "Real - Brasil",
    BRLT: "Real Tourist - Brasil",
    BSD: "Dolar - Bahamas",
    BTC: "Bitcoin",
    BWP: "Pula - Botswana",
    BYN: "Ruble - Belarus",
    BZD: "Dolar - Belize",
    CAD: "Dolar - Canada",
    CHF: "Franco - Switzerland",
    CHFRTS: "Franco - Switzerland",
    CLP: "Peso - Chile",
    CNH: "Yuan – China offshore",
    CNY: "Yuan - China",
    COP: "Peso - Colombia",
    CRC: "Colón – Costa Rica",
    CUP: "Peso - Cuba",
    CVE: "Escudo – Cape Verde",
    CZK: "Coroa - Czech ",
    DJF: "Franco - Djubouti",
    DKK: "Coroa - Denamark",
    DOGE: "Dogecoin",
    DOP: "Peso – Dominican Rep.",
    DZD: "Dinar - Algeria",
    EGP: "Libra - Egypt",
    ETB: "Birr - Ethiopia",
    ETH: "Ethereum",
    EUR: "Euro",
    FJD: "Dolar - Fiji",
    GBP: "Pound Sterling – Great British",
    GEL: "Lari - Georgia",
    GHS: "Cedi - Ghana",
    GMD: "Dalasi - Gambiam",
    GNF: "Franco - Guinea",
    GTQ: "Quetzal - Guatemalan",
    HKD: "Dolar - Hong Kong",
    HNL: "Lempira - Honduras",
    HRK: "Kuna - Croatia",
    HTG: "Gourde - Haiti",
    HUF: "Florim - Hungary",
    IDR: "Rupia - Indonesia",
    ILS: "Novo Shekel – Israel",
    INR: "Rúpia - India",
    IQD: "Dinar - Iraq",
    IRR: "Rial - Iran",
    ISK: "Coroa - Iceland",
    JMD: "Dolar - Jamaica",
    JOD: "Dinar - Jordan",
    JPY: "Iene - Japan",
    JPYRTS: "Iene - Japan",
    KES: "Shilling - Kenya",
    KGS: "Som - Kyrgyzstan ",
    KHR: "Riel Cambodia",
    KMF: "Franco - Comoros",
    KRW: "Won – South Korea",
    KWD: "Dinar - Kwait",
    KYD: "Dolar - Cayman",
    KZT: "Tengue - Kazakhstan",
    LAK: "Kip - Laos",
    LBP: "Libra - Libya",
    LKR: "Rúpia - Sri Lanka",
    LSL: "Loti - Lesoto",
    LTC: "Litecoin",
    LYD: "Dinar - Libya",
    MAD: "Dirham - Morroccos",
    MDL: "Leu - Moldavia",
    MGA: "Ariary - Madagascar",
    MKD: "Denar - Macedonia",
    MMK: "Kyat - Mianmar",
    MNT: "Mongolian - Tugrik",
    MOP: "Pataca - Macau",
    MRO: "Ouguiya - Mauritania",
    MUR: "Rúpia - Mauritius",
    MVR: "Rufiyaa - Maldivas",
    MWK: "Kwacha - Malawi",
    MXN: "Peso - Mexico",
    MYR: "Ringgit - Malaysia",
    MZN: "Metical - Mozambique",
    NAD: "Dolar - Namib",
    NGN: "Naira - Nigeria",
    NGNI: "Naira - Nigeriaa",
    NGNPARALLEL: "Naira Nigeriana",
    NIO: "Córdoba - Nicaragua",
    NOK: "Krone - Norwich",
    NPR: "Rúpia - Nepal",
    NZD: "Dolar – New Zealand",
    OMR: "Rial - Oman",
    PAB: "Balboa - Panama",
    PEN: "Sol - Peru",
    PGK: "Kina Papua-New Guinea",
    PHP: "Peso - Philippines",
    PKR: "Rúpia - Pakistan",
    PLN: "Zlóti - Poland",
    PYG: "Guarani - Paraguay",
    QAR: "Rial - Qatar",
    RON: "Leu - Romania",
    RSD: "Dinar - Servia",
    RUB: "Rublo - Russia",
    RUBTOD: "Rublo - Russia",
    RUBTOM: "Rublo - Russia",
    RWF: "Franco - Rwanda",
    SAR: "Riyal - Arabia",
    SCR: "Rúpias - Seichelles",
    SDG: "Libra - Sudan",
    SDR: "DSE",
    SEK: "Krown - Sweden",
    SGD: "Dolar - Singapore",
    SOS: "Shilling - Somaly",
    STD: "Dobra - San Tome/Príncipe",
    SVC: "Colon - El Salvador",
    SYP: "Libra - Syria",
    SZL: "Lilangeni - Swaziland",
    THB: "Baht - Thailand",
    TJS: "Somoni - Tajikistan",
    TMT: "TMT",
    TND: "Dinar - Tunisia",
    TRY: "Nova Lira - Turkey",
    TTD: "Dolar - Trinidad",
    TWD: "Dolar - Taiwan",
    TZS: "Shilling - Tanzania",
    UAH: "Hryvinia - Ukraine",
    UGX: "Shilling - Uganda",
    USD: "Dolar – United States",
    USDT: "Dolar – United States",
    UYU: "Peso - Uruguay",
    UZS: "Som - Uzbekistan",
    VEF: "Bolívar - Venezuela",
    VND: "Dong - Vietnam",
    VUV: "Vatu - Vanuatu",
    XAF: "Franco - CFA Central",
    XAGG: "Prata",
    XBR: "Brent Spot",
    XCD: "Dolar - Western Caribe ",
    XOF: "Franco CFA Eastern",
    XPF: "Franco CFP",
    XRP: "XRP",
    YER: "Riyal - Yemen",
    ZAR: "Rand – South Africa",
    ZMK: "Kwacha - Zambia",
    ZWL: "Dolar - Zimbabwe",
    XAU: "Gold",
  },
  importFinancialTransaction: {
    title: "Import Financial Transactions",
    message:
      '<p class="message">Download <strong>template below, fill in the spreadsheet columns</strong> with financial entries, <strong>import it</strong> and click process.</p>',
    draggerMessage: "Click or drag the file to this area to upload it. ",
  },
  confinementResultReport: {
    form: {
      title: "Feedlot Result",
      salesPeriod: "Sales Period",
      clientId: "Customer",
      supplierId: "Supplier",
      saleScenarioId: "Sales Scenario(s)",
      documentNumber: "Doc. N° ",
      lotIds: "Batches",
      showDeathData: "Display Deaths data in the period?",
    },
  },
  farmDayScaleTreatmentParameter: {
    title: "Semen List",
    buttonNewSemen: "New Semen",
    table: {
      columns: {
        name: "Name",
        registration: "Register",
        breedName: "Breed",
      },
    },
    form: {
      titleCreateNewSemen: "New Semen",
      titleEditSemen: "Edit Semen",
      name: "Name",
      registration: "Register",
      breedId: "Breed",
    },
  },
  farmReadFeederTreatmentParameter: {
    title: "Semen List",
    buttonNewSemen: "New Semen",
    table: {
      columns: {
        name: "Name",
        registration: "Register",
        breedName: "Breed",
      },
    },
    form: {
      titleCreateNewSemen: "New Semen",
      titleEditSemen: "Edit Semen",
      name: "Name",
      registration: "Register",
      breedId: "Breed",
    },
  },
  troughFeedingAssessment: {
    title: "Food intake Assessment",
    buttonNew: "Report Food Intake Assessment",
    table: {
      columns: {
        readingDate: "Date",
        supplyDate: "Food Delivery run",
        responsibleName: "Responsible",
        picketName: "Paddocks/Pens",
        lotName: "BATCH",
        dietStrategyName: "Diet Strategy",
        totalAnimals: "Amt. Animals",
        quantitySupplied: "Amt. Delivered(Kg)",
        readValue: "Intake Assessment ",
      },
    },
    form: {
      titleCreateNew: "New/Edit Intake Assessment ",
      informType: "Report by",
      informTypeValues: {
        byDate: "Date",
        byPicket: "Paddock/Pen",
      },
      readingType: "Intake Assessment Format",
      readingTypeValues: {
        unique: "Single",
        perSupply: "Run",
      },
      readingResult: "Intake Asses. value",
      readingDate: "Intake Asses. Date",
      readingHour: "Intake Asses. Time ",
      supplyNumber: "Delivery run N°(s)",
      supplyDates: "Date run",
      responsibleName: "Responsible",
      retreats: "Range Unit/Row",
      pickets: "Paddocks/Pens",
      lots: "Batches",
      tableSupplyNumber: "#Run",
      amountAnimals: "#Heads",
      supplement: "Supplem./Concent.",
      realHour: "Actual Time",
      prevHour: "Planned Time",
      prevQuantitySupplied: "Forecast Amt. (Kg)",
      realQuantitySupplied: "Delivered Amt. (Kg)",
      observation: "Remarks",
      saveOptions: {
        only_save: "Save",
        save_and_create_new: "Save and create new Intake Assement",
      },
    },
  },
  processTransferAnimalTransactionModal: {
    title: "Process Animal Transfer",
    form: {
      origin: "Origin",
      amountAnimals: "Qty. Head",
      sellDate: "Date",
      animalValue: "Average Value",
      totalValue: "Total Value",
      totalInstallments: "Qty. Installments",
      firstInstallmentDate: "Date of 1st Installment",
      firstInstallmentValue: "Value of 1st Installment",
      resultCenter: "Result Center",
      lotDestinationId: "Destination Lot",
      financialNatureId: "Nature",
      financialClassId: "Class",
      farmOwnerId: "Owner",
      dividerTitle: "ADDITIONAL DATA",
      useFarmOwnersFromOrigin: "Keep owners from Origin Farm",
    },
    messages: {
      rejectModalTitle: "Alert",
      rejectModalContent:
        "Do you really want to reject this transfer? This action is irreversible!",
      successSubmit:
        "The transfer has been successfully confirmed and is now being processed. The transaction will be completed soon.",
      errorSubmit:
        "An error occurred while trying to confirm the transfer. Please contact support.",
      successReject:
        "The transfer is being rejected. The transaction will be successfully rejected soon.",
      errorReject:
        "An error occurred while trying to reject the transfer. Please contact support.",
      errorNotSelectFarmOwnerOrCheckUseOriginalFarmOwner:
        "You must select an Owner or check the option to keep the owners from the Origin Farm.",
      errorFarmOwnerNotCreated:
        "Attention! There are owners (listed below) of animals, not registered on this farm. To adjust, go to [Administration/Farms]",
    },
  },
  weightRanking: {
    top: "Top",
    middle: "Middle",
    bottom: "Bottom",
  },
  menuNavigation: {
    menu_diet_strategy: "DIET STRATEGY",
    menu_picket_supplement_supply: "SUPPLY",
    menu_trough_feeding_assessment: "TROUGH FEEDING ASSESSMENT",
    menu_weights: "WEIGHINGS",
    menu_veterinary: "VETERINARY",
    menu_reproductive: "REPRODUCTIVE",
    menu_dashboard: "DASHBOARD",
    menu_purchase_scenario: "PURCHASE SCENARIO",
    menu_sale_scenario: "SALE SCENARIO",
    menu_purchase_scenario_boitel: "PURCHASE SCENARIO",
    menu_sale_scenario_boitel: "SALE SCENARIO",
    menu_nutritional: "NUTRITIONAL",
    menu_entry: "ENTRY",
    menu_technician_veterinary: "VETERINARY",
    menu_exit: "EXIT",
    menu_manager: "MANAGEMENT",
    menu_handling: "HANDLINGS",
    menu_purchase: "PURCHASE",
    menu_sale: "SALE",
    menu_purchase_boitel: "PURCHASE",
    menu_sale_boitel: "SALE",
    menu_recreate_fatten_extensive: "R/E PASTURE",
    menu_growth: "GROWTH",
    menu_confinement: "R/E CONFINEMENT",
    menu_boitel: "BOITEL",
  },
  weather: {
    title: "Weather Data",
  },
  arrobaPrice: {
    title: "Financial Data",
    dataAgro: {
      selectDate: "Select a date: ",
    },
    marketAnalysisDash: {
      title: "Price Curve",
      selectCurve: "Select the curve you want to view:",
      emptyMessage: "Select a curve to display the chart",
    },
  },
  alertPanel: {
    title: "alerts & pending items",
    emptyMessage: "No alerts found on this date",
  },
  viewTransferAnimalTransactionModal: {
    title: "Animal Transfer Details",
    form: {
      origin: "Origin",
      amountAnimals: "Qty. Head",
      sellDate: "Date",
      animalValue: "Average Value",
      totalValue: "Total Value",
      totalInstallments: "Qty. Installments",
      firstInstallmentDate: "Date of 1st Installment",
      firstInstallmentValue: "Value of 1st Installment",
      resultCenter: "Result Center",
      lotDestinationId: "Destination Lot",
      financialNatureId: "Nature",
      financialClassId: "Class",
      farmOwnerId: "Owner",
      dividerTitle: "ADDITIONAL DATA",
    },
    table: {
      columns: {
        handlingNumber: "Animal",
        dateTransferCreation: "Registration Date",
        dateTransferAccept: "Confirmation Date",
        weightTransfer: "Weight",
        priceTransfer: "Price",
        lotOriginName: "Origin Lot",
        lotDestinationName: "Destination Lot",
        responsibleCreateTransfer: "Origin Responsible",
        responsibleAcceptTransfer: "Destination Responsible",
      },
    },
  },
  movementAnimalReportParameter: {
    title: "Herd Dynamics by Age",
    classification: "Classification",
    messages: {
      errorLenghtEqualZero:
        "At least one age classification by gender is required.",
      errorClassificationWithoutMinAge:
        "Classifications must have a minimum age.",
      errorClassificationWithManyMaxAgeNull:
        "Only one classification without a maximum age per gender is allowed.",
      errorClassificationWithMaxAgeLessThanOrEqualToMinAge:
        "The maximum age must be greater than the minimum age.",
      errorClassificationWithWrongOrder:
        "Classifications must follow an age order, where the minimum age of a classification must be greater than the maximum of the previous one.",
    },
  },
  movementAnimalReportModalRequest: {
    title: "Animal Movements",
    form: {
      onlyFinalStock: "Current Animal Stock",
      allFarmGroups: "All Farms",
      datePeriod: "Period",
      breedId: "Breed",
      ownerId: "Owner",
      supplierId: "Supplier",
    },
  },
  successGenerateReport: "Report generated successfully",
  errorGenerateReport:
    "There was an error generating the report. Please contact support",
  reports: {
    tabs: {
      favorite: "Favorites",
      animal: "Animals",
      produciton: "Production",
      financial: "Financial",
      management: "Management",
      operational: "Operations",
    },
    titles: {
      SimpleAnimalReport: "Simple Animal Report",
      ListAnimalReportReproduction: "Animal Listing Report (Reproduction)",
      ListAnimalReportFatten: "Animal Listing Report (Fattening)",
      AnimalWeightImportReport: "Weight Import Report",
      ReportMovementAnimals: "Movement Report",
      ReportNutritionalConsumption: "Feed/Supplement Consumption Report",
      ReportPicketFeedingAssessment: "Supply Spreadsheet",
      IntensiveResultReport: "Confinement Results Report",
      FarmSalesReport: "Sales Results Report",
      FinancialTransactionsReport: "Financial Transactions Export",
      FinancialCashFlowReport: "Cash Flow Report",
      DisbursementReport: "Disbursement Report",
      ReportAnimalWeightHistory: "Weight Report",
    },
    messages: {
      errorUpdateFavorite:
        "An error occurred while updating/removing the report from favorites",
    },
  },
  financialCashFlowReportModalRequest: {
    title: "Cash Flow Report",
    form: {
      transactionType: "Type",
      periodStartDate: "Start",
      periodEndDate: "End",
      periodType: "Period",
      intervalType: "Interval",
      farmIds: "Farm(s)",
      financialNatureId: "Nature",
      financialClassId: "Class",
      costActivityType: "Activity",
      showZeroValues: "Show Classes with no movements in the period?",
      showAnalyticalData: "Generate analytical report?",
    },
    periods: {
      lastSevenDays: "Last 7 days",
      lastThirdyDays: "Last 30 days",
      currentMonth: "Current Month",
      lastMonth: "Previous Month",
      currentYear: "Current Year",
      lastYear: "Previous Year",
      currentHarvest: "Current Harvest",
      lastHarvest: "Previous Harvest",
      customDateRange: "Specify Date (Max. 12 months)",
    },
    intervals: {
      daily: "Daily",
      weakly: "Weekly",
      monthly: "Monthly",
      yearly: "Yearly",
    },
    messages: {
      errorPeriodMoreThanTwelveMonths: "Period cannot exceed 12 months.",
      errorNoDataFound: "No results were found with the applied filter",
    },
  },
  disbursementReportModalRequest: {
    title: "Disbursement Report",
    form: {
      transactionType: "Type",
      periodStartDate: "Start",
      periodEndDate: "End",
      periodType: "Period",
      intervalType: "Interval",
      farmIds: "Farm(s)",
      financialNatureId: "Nature",
      financialClassId: "Class",
      costActivityType: "Activity",
      showZeroValues: "Display Classes without transactions in the period?",
      showAnalyticalData: "Generate analytical report?",
    },
    periods: {
      lastSevenDays: "Last 7 days",
      lastThirdyDays: "Last 30 days",
      currentMonth: "Current Month",
      lastMonth: "Previous Month",
      currentYear: "Current Year",
      lastYear: "Previous Year",
      currentHarvest: "Current Harvest",
      lastHarvest: "Previous Harvest",
      customDateRange: "Specify Date (Max. 12 months)",
    },
    intervals: {
      daily: "Daily",
      weakly: "Weekly",
      monthly: "Monthly",
      yearly: "Yearly",
    },
    messages: {
      errorPeriodMoreThanTwelveMonths: "Period cannot exceed 12 months.",
      errorNoDataFound:
        "There are no financial transactions and/or animals in the period reported.",
    },
  },
};
