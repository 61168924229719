import { Button, Col, DatePicker, Icon, Input, Row, Spin, Table } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

// Contexts
import { Creators as ClientActions } from "../../../../../../store/ducks/client";

// Components
import ButtonStandard from "../../../../../../components/utils/button";
import InfoTooltip from "../../../../../../components/utils/infoTooltip";
import { Title } from "../styles";

// Services
import {
  findAllAnimalsForSell,
  findFarmSell,
} from "../../../../../../services/farmSellService";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../../../services/helpersMethodsService";

// Modais

// Ícones
import { findAllFinancialClassesByGroupIdAndFarmId } from "../../../../../../services/financialClassService";
import { findAll } from "../../../../../../services/financialProjectService";

const { Column } = Table;

const initialFormValue = {
  clientId: null,
  sellDate: null,
  priceNegotiated: null,
  priceNegotiatedUnit: "VALUE_PER_ARROBA",
  weightPerAnimal: null,
  carcassHarnessing: null,
  documentNumber: null,
  supplierId: null,
  billingDate: null,
  expirationDate: null,
  payment: "inCash",
  installmentTotal: null,
  installments: [],
  resultCenterApportionmentDefault: true,
  customApportionmentValues: [],
  totalValue: 0,
  valueCurrency: "BRL",
  bonus: null,
  financialCompensation: false,
  financialNatureId: null,
  financialClassId: null,
  typeWeightPerAnimal: "INPUT_WEIGHT",
  typePriceNegotiatedPerAnimal: "INPUT_PRICE",
  sellBetweenSameGroup: false,
  sellBetweenBovExoFarmClients: false,
};

const formatDate = "DD/MM/YYYY";

const FormProductionSell = ({ onCancel }) => {
  const dispatch = useDispatch();

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { listDropdown: clientData } = useSelector((state) => state.client);

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialFormValue);

  const [animalsCustomData, setAnimalsCustomData] = useState([]);

  const [amountAnimals, setAmountAnimals] = useState(null);

  const [filteredListFinancialNature, setFilteredListFinancialNature] =
    useState([]);
  const [listFinancialClass, setListFinancialClass] = useState([]);
  const [listFinancialProjects, setListFinancialProjects] = useState([]);

  const {
    params: { id: farmSellId },
  } = useRouteMatch();

  const avgCarcassHarnessing = useMemo(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumCarcassHarnessing = animalsCustomData.reduce(
        (sumValue, currentObj) =>
          sumValue + (currentObj?.carcassHarnessing || 0),
        0
      );
      return +(sumCarcassHarnessing / animalsCustomData.length).toFixed(2);
    }

    return 0;
  }, [animalsCustomData]);
  const avgSellWeight = useMemo(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumWeightSell = animalsCustomData.reduce(
        (sumValue, currentObj) => sumValue + (currentObj?.weightSell || 0),
        0
      );
      return +(sumWeightSell / animalsCustomData.length).toFixed(2);
    }

    return 0;
  }, [animalsCustomData]);
  const avgSellWeightInArroba = useMemo(() => {
    if (amountAnimals && form.carcassHarnessing && form.weightPerAnimal) {
      const weight = avgSellWeight ? avgSellWeight : form.weightPerAnimal;
      const carcassHarnessing = avgCarcassHarnessing
        ? avgCarcassHarnessing
        : form.carcassHarnessing;
      return (weight * amountAnimals * (carcassHarnessing / 100 || 1)) / 15;
    }
    return 0;
  }, [
    amountAnimals,
    avgCarcassHarnessing,
    avgSellWeight,
    form.carcassHarnessing,
    form.weightPerAnimal,
  ]);
  const bonusPerArroba = useMemo(() => {
    return form.bonus && form.bonus > 0
      ? form.bonus / avgSellWeightInArroba
      : 0;
  }, [avgSellWeightInArroba, form.bonus]);

  const totalValue = useMemo(() => {
    const {
      priceNegotiated,
      weightPerAnimal,
      carcassHarnessing,
      priceNegotiatedUnit,
    } = form;
    let totalPrice = 0;
    if (priceNegotiatedUnit === "VALUE_PER_ANIMAL") {
      totalPrice = getTwoDecimalDigits((priceNegotiated || 0) * amountAnimals);
    } else if (priceNegotiatedUnit === "VALUE_PER_KG") {
      totalPrice = getTwoDecimalDigits(
        (priceNegotiated || 0) * (weightPerAnimal || 0) * amountAnimals
      );
    } else {
      totalPrice = getTwoDecimalDigits(
        (priceNegotiated || 0) *
          ((weightPerAnimal || 0) / 15) *
          ((carcassHarnessing || 0) / 100) *
          amountAnimals
      );
    }
    return totalPrice + (form.bonus ? form.bonus : 0);
  }, [form, amountAnimals]);

  function getColumnSearchAnimalsProps(dataIndex) {
    return true
      ? {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8 }}>
              <Input
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearchAnimals(selectedKeys, confirm, dataIndex)
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
              <Button
                type="primary"
                onClick={() =>
                  handleSearchAnimals(selectedKeys, confirm, dataIndex)
                }
                icon="search"
                size="small"
                style={{
                  width: 90,
                  marginRight: 8,
                  background: "#684e94",
                  borderColor: "none",
                  border: "none",
                }}
              />
              <Button
                onClick={() => handleAnimalsReset(clearFilters)}
                icon="delete"
                size="small"
                style={{ width: 90 }}
              />
            </div>
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#684e94" : undefined }}
            />
          ),
          onFilter: (value, record) => {
            return record[dataIndex].toString().includes(value);
          },
        }
      : {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8 }}>
              {dataIndex === "sellDate" ? (
                <DatePicker
                  value={selectedKeys[0]}
                  format={formatDate}
                  onChange={(date, dateString) =>
                    setSelectedKeys(date ? [date] : [])
                  }
                  onPressEnter={() =>
                    handleSearchAnimals(selectedKeys, confirm)
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
              ) : (
                <Input
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() =>
                    handleSearchAnimals(selectedKeys, confirm)
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
              )}
              <Button
                type="primary"
                onClick={() => handleSearchAnimals(selectedKeys, confirm)}
                icon="search"
                size="small"
                style={{
                  width: 90,
                  marginRight: 8,
                  background: "#684e94",
                  borderColor: "none",
                  border: "none",
                }}
              />
              <Button
                onClick={() => handleAnimalsReset(clearFilters)}
                icon="delete"
                size="small"
                style={{ width: 90 }}
              />
            </div>
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#684e94" : undefined }}
            />
          ),
        };
  }

  function handleSearchAnimals(selectedKeys, confirm) {
    confirm();
  }

  function handleAnimalsReset(clearFilters) {
    clearFilters();
  }

  // Fetch animals
  useEffect(() => {
    async function fetchAnimals(animals) {
      const ids = animals.map((a) => a.animalId);

      try {
        const {
          data: { results },
        } = await findAllAnimalsForSell({
          groupId,
          farmId,
          withoutPagination: true,
          ids,
        });

        setAmountAnimals(animals.length);

        const animalsCustomData = results.map((a) => {
          let customAnimal = animals.find((animal) => animal.animalId === a.id);
          a.carcassHarnessing = customAnimal?.carcassHarnessing;
          a.weightSell = customAnimal?.weightSell;
          a.priceNegotiated = customAnimal?.priceNegotiated;
          a.pricePerKilo = customAnimal?.pricePerKilo;
          a.pricePerArroba = customAnimal?.pricePerArroba;
          return a;
        });

        setAnimalsCustomData(animalsCustomData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    async function fetchFarmSell() {
      setIsLoading(true);

      try {
        const {
          data: { results },
        } = await findFarmSell({
          groupId,
          farmId,
          farmSellId,
        });

        setForm({
          ...results,
          sellDate: moment(results.sellDate),
          billingDate: moment(results.billingDate),
          expirationDate: moment(results.expirationDate),
          installments: results.installments.map((i) => {
            i.date = moment(i.date);
            return i;
          }),
        });
        fetchAnimals(results.animals);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }

    if (farmSellId) {
      fetchFarmSell();
    }
  }, [groupId, farmId, farmSellId]);

  // Fetch clients, financial nature/class and future prices
  useEffect(() => {
    async function getFinancialProjects() {
      try {
        const {
          data: { results: projects },
        } = await findAll({
          groupId,
          farmId,
        });
        const activeProjects = projects.filter(
          (project) => project.status === "Active"
        );
        setListFinancialProjects(activeProjects);
      } catch (error) {
      } finally {
      }
    }
    async function getClients() {
      try {
        dispatch(ClientActions.getDropdownClients(groupId, farmId, true));
      } catch (error) {
        console.error(error);
      }
    }

    async function getFinancialClassAndNature() {
      try {
        const {
          data: { results: classes },
        } = await findAllFinancialClassesByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        const natures = [
          ...new Map(
            classes
              .map((c) => c.financialNature)
              .map((item) => [item["id"], item])
          ).values(),
        ];
        setFilteredListFinancialNature(
          natures.filter((n) => n.type === "income")
        );
        setListFinancialClass(classes);
      } catch (error) {
      } finally {
      }
    }
    if (groupId && farmId) {
      Promise.all([
        getFinancialProjects(),
        getClients(),
        getFinancialClassAndNature(),
      ]);
    }
  }, [dispatch, groupId, farmId]);

  return (
    <>
      {/* Page Header */}
      <div className="pageHeader">
        <Row type="flex" justify="start">
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Title>{translation.financial.animalSell.form.title}</Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            align="right"
            className="buttonsCol"
          >
            <ButtonStandard
              type="button"
              buttonType="type4"
              size="s"
              onClick={onCancel}
            >
              {translation.buttons.back}
            </ButtonStandard>
          </Col>
        </Row>
      </div>

      {/* Form section */}
      <Spin spinning={isLoading}>
        <Row type="flex" align="stretch" justify="start">
          {/* Fields */}
          {form.financialCompensation === true ? (
            <Col span={21}>
              {/* Sale fields */}
              <Row type="flex" align="middle" justify="start" gutter={8}>
                {/* Client */}
                <Col xs={6} sm={6} md={6} lg={8} xl={8}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.client}
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {clientData
                        ? clientData.find(
                            (client) => client.id === form.clientId
                          )?.name
                        : "-"}
                    </strong>
                  </Row>
                </Col>
                {/* Sell date */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.sellDate}
                    </label>
                  </Row>
                  <Row>
                    <strong>{moment(form?.sellDate).format(formatDate)}</strong>
                  </Row>
                </Col>
                {/* Weight Per Animal */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.weightPerAnimal}
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {numberMask(form?.weightPerAnimal, false)} kg
                    </strong>
                  </Row>
                </Col>
                {/* Carcass harnessing */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.carcassHarnessing}
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {numberMask(form?.carcassHarnessing, false)} %
                    </strong>
                  </Row>
                </Col>
                {/* Price negotiated */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.priceNegotiated}
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {numberMask(form?.priceNegotiated, false)}{" "}
                      {form?.priceNegotiatedUnit === "VALUE_PER_ARROBA"
                        ? "R$/@"
                        : form?.priceNegotiatedUnit === "VALUE_PER_KG"
                        ? "R$/Kg"
                        : "R$/cab"}
                    </strong>
                  </Row>
                </Col>
              </Row>
              {/* Transaction fields */}
              <Row
                type="flex"
                align="middle"
                justify="start"
                gutter={8}
                className="row-form"
              >
                {/* Document number */}
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.documentNumber}
                    </label>
                  </Row>
                  <Row>
                    <strong>{form?.documentNumber}</strong>
                  </Row>
                </Col>
                {/* Financial Nature */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.financialNatureId}
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {filteredListFinancialNature
                        ? filteredListFinancialNature.find(
                            (nature) => nature.id === form.financialNatureId
                          )?.name
                        : "-"}
                    </strong>
                  </Row>
                </Col>
                {/* Financial Class */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.transactions.form.financialClassId}
                      *
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {listFinancialClass.find(
                        (fClass) => fClass.id === form.financialClassId
                      )?.name ?? "-"}
                    </strong>
                  </Row>
                </Col>
                {/* Billing date */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.billingDate}
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {moment(form?.billingDate).format(formatDate)}
                    </strong>
                  </Row>
                </Col>
                {/* Expiration date */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.expirationDate}
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {moment(form?.expirationDate).format(formatDate)}
                    </strong>
                  </Row>
                </Col>
              </Row>
              <Row
                type="flex"
                align="middle"
                justify="start"
                gutter={8}
                className="row-form"
              >
                {/* Payment */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.payment}
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {form.payment === "inCash"
                        ? translation.financial.animalSell.form.inCash
                        : translation.financial.animalSell.form.part}
                    </strong>
                  </Row>
                </Col>
                {/* Result Center */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.resultCenter}
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {form?.resultCenterApportionmentDefault
                        ? translation.financial.animalSell.form
                            .resultCenterApportionmentDefault
                        : translation.financial.animalSell.form
                            .resultCenterApportionmentCustom}
                    </strong>
                  </Row>
                </Col>
                {/* Financial Projects */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {
                        translation.financial.transactions.form
                          .financialProjectId
                      }
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {listFinancialProjects.find(
                        (project) => project.id === form.financialProjectId
                      )?.name ?? "-"}
                    </strong>
                  </Row>
                </Col>
                {/* Bonus */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.transactions.form.bonus}
                    </label>
                  </Row>
                  <Row>
                    <strong>{numberMask(form?.bonus, true)}</strong>
                  </Row>
                  {form.bonus && (
                    <Row>
                      <label>{`${numberMask(bonusPerArroba, true)}/@`}</label>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          ) : (
            <Col span={21}>
              {/* Sale fields */}
              <Row type="flex" align="middle" justify="start" gutter={8}>
                {/* Client */}
                <Col xs={6} sm={6} md={6} lg={8} xl={8}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.client}
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {clientData
                        ? clientData.find(
                            (client) => client.id === form.clientId
                          )?.name
                        : "-"}
                    </strong>
                  </Row>
                </Col>
                {/* Sell date */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.sellDate}
                    </label>
                  </Row>
                  <Row>
                    <strong>{moment(form?.sellDate).format(formatDate)}</strong>
                  </Row>
                </Col>
                {/* Weight Per Animal */}
                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                  <Row>
                    <label>
                      {translation.financial.animalSell.form.weightPerAnimal}
                    </label>
                  </Row>
                  <Row>
                    <strong>
                      {numberMask(form?.weightPerAnimal, false)} kg
                    </strong>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
          {/* Total value */}
          <Col span={3} className="colWithBorderLeft">
            <Row
              type="flex"
              align="bottom"
              justify="center"
              className="row-total-value"
            >
              <span className="label-total-value">
                {translation.financial.animalSell.form.totalValue}
                <InfoTooltip
                  placement="top"
                  trigger="click"
                  title={
                    <>
                      <p>
                        {
                          translation.financial.animalSell.messages
                            .infoTooltipText1
                        }
                      </p>
                      <p>
                        {
                          translation.financial.animalSell.messages
                            .infoTooltipText2
                        }
                      </p>
                      <p>
                        {
                          translation.financial.animalSell.messages
                            .infoTooltipText3
                        }
                      </p>
                    </>
                  }
                />
              </span>
            </Row>
            <Row type="flex" align="top" justify="center">
              <span className="total-value">
                {`${numberMask(totalValue || Number(0), true)}`}
              </span>
            </Row>
          </Col>
        </Row>
      </Spin>
      {/* Selected Animals Table */}
      <Row className="row-form">
        <Table
          rowKey="id"
          loading={isLoading}
          dataSource={Array.isArray(animalsCustomData) ? animalsCustomData : []}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
          }}
          scroll={{ x: true }}
          size="small"
        >
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .handlingNumber
            }
            dataIndex="handlingNumber"
            key="handlingNumber"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("handlingNumber")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns.lotName
            }
            dataIndex="lotName"
            key="lotName"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("lotName")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .picketName
            }
            dataIndex="picketName"
            key="picketName"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("picketName")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns.breedName
            }
            dataIndex="breedName"
            key="breedName"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("breedName")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns.gender
            }
            dataIndex="gender"
            key="gender"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("gender")}
            render={(value) => {
              if (value === "M") {
                return <span>Macho</span>;
              } else {
                return <span>Fêmea</span>;
              }
            }}
          />
          <Column
            title={translation.financial.animalSell.form.animalTableColumns.age}
            dataIndex="age"
            key="age"
            align="left"
            sorter
            render={(text, record) =>
              record.age !== null ? <span>{`${record.age} m`}</span> : ""
            }
            {...getColumnSearchAnimalsProps("age")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .lastDailyWeight
            }
            dataIndex="lastDailyWeight"
            key="lastDailyWeight"
            align="left"
            render={(text, record) => (
              <span>
                {record.lastDailyWeight != null
                  ? `${numberMask(record.lastDailyWeight || 0)} Kg`
                  : `0 Kg`}
              </span>
            )}
            {...getColumnSearchAnimalsProps("lastDailyWeight")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .lastDailyWeightDate
            }
            dataIndex="lastDailyWeightDate"
            key="lastDailyWeightDate"
            align="left"
            render={(text, record) => (
              <span>
                {record.lastDailyWeightDate != null
                  ? moment(record.lastDailyWeightDate).format(formatDate)
                  : null}
              </span>
            )}
            {...getColumnSearchAnimalsProps("lastDailyWeightDate")}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .weightSell
            }
            dataIndex="weightSell"
            key="weightSell"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("weightSell")}
            render={(text, record) => (
              <span>{`${numberMask(record.weightSell || 0)} Kg`}</span>
            )}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .carcassHarnessing
            }
            dataIndex="carcassHarnessing"
            key="carcassHarnessing"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("carcassHarnessing")}
            render={(text, record) => (
              <span>{`${numberMask(record.carcassHarnessing || 0)}`}</span>
            )}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .pricePerKilo
            }
            dataIndex="pricePerKilo"
            key="pricePerKilo"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("pricePerKilo")}
            render={(text, record) => (
              <span>{`${numberMask(record.pricePerKilo || 0, true)}`}</span>
            )}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .pricePerArroba
            }
            dataIndex="pricePerArroba"
            key="pricePerArroba"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("pricePerArroba")}
            render={(text, record) => (
              <span>{`${numberMask(record.pricePerArroba || 0, true)}`}</span>
            )}
          />
          <Column
            title={
              translation.financial.animalSell.form.animalTableColumns
                .priceNegotiated
            }
            dataIndex="priceNegotiated"
            key="priceNegotiated"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("priceNegotiated")}
            render={(text, record) => (
              <span>{`${numberMask(record.priceNegotiated || 0, true)}`}</span>
            )}
          />
        </Table>
      </Row>
    </>
  );
};

export default FormProductionSell;
